import React from 'react';
import styles from './columnContent.module.scss';
import logo from '../../assets/images/logo/logo-ccv-blue.svg';

function ColumnContent({ children, leftColumn = null, rightColumn = null }) {
  return (
    <div className={`container ${styles.columnContent}`}>
      <div className="row overflow-hidden">
        <div className={`col-auto ${styles.leftColumn}`}>{leftColumn}</div>
        <div className={`col bg-white pt-6 pb-7 ${styles.centerColumn}`}>
          {children}
        </div>
        <div className={`col-auto bg-white ${styles.rightColumn}`}>
          {rightColumn}
        </div>
      </div>
      <div className={`row ${styles.footer}`}>
        <div className="col p-4">
          <a
            href="https://hetccv.nl/onderwerpen/naleving-toezicht-en-handhaving/"
            target="_blank"
            rel="noopener noreferrer"
            id="ccv"
          >
            <img src={logo} id="logo-ccv" width="196" height="39" alt="CCV" />
          </a>
        </div>
        <div className="col-auto p-0">
          <div className="row no-gutters align-items-center h-100">
            <div className="col px-3">
              <p>
                Churchilllaan 11
                <br />
                3527GV Utrecht
              </p>
            </div>
            <div
              className={`col px-3 ${styles.footerContactInfo}`}
              style={{ minWidth: '140px' }}
            >
              <p>
                <a href="tel:030-7516700">030 - 7516700</a>
                <br />
                <a href="mailto:info@hetccv.nl">info@hetccv.nl</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColumnContent;
