import React from 'react';
import { useTranslation } from 'react-i18next';

import { generatePath, useParams } from 'react-router-dom';
import {
  ROUTE_PROJECT_ANALYSIS,
  ROUTE_PROJECT_DISTRIBUTION_INTRO,
} from '../../../constants/routes';
import StepIntro from '../../stepIntro/stepIntro';

function AnalysisIntro() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  return (
    <StepIntro
      title={t('content.questionnaire.analysis.title')}
      description={t('content.questionnaire.intro.analysis.text')}
      continueLink={generatePath(ROUTE_PROJECT_ANALYSIS, { projectId })}
      skipLink={generatePath(ROUTE_PROJECT_DISTRIBUTION_INTRO, { projectId })}
      activeStep="analysis"
    />
  );
}

export default AnalysisIntro;
