import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ROUTE_PUBLIC } from './constants/routes';
import PublicApp from './PublicApp';
import DefaultApp from './DefaultApp';
import EnglishWebsiteAlert from './components/englishWebsiteAlert/englishWebsiteAlert';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path={ROUTE_PUBLIC}>
            <PublicApp />
          </Route>
          <Route path="/">
            <DefaultApp />
          </Route>
        </Switch>
      </Router>
      <EnglishWebsiteAlert />
    </>
  );
}

export default App;
