import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Redirect, useParams } from 'react-router-dom';
import ConfirmNavigationModal from '../../../components/confirmNavigationModal/confirmNavigationModal';
import useDistribution from '../../../hooks/useDistribution';
import FixedLoader from '../../../components/loader/fixedLoader';
import Errors from '../../../components/errors/errors';
import { Button } from 'react-bootstrap';
import { useMutation, useQueryCache } from 'react-query';
import saveDistribution from '../../../queries/saveDistribution';
import { useAuth } from '../../../context/auth';
import { ROUTE_PROJECT_DISTRIBUTION_RESULT } from '../../../constants/routes';
import NextButton from '../../../components/nextButton/nextButton';
import EnablePublicProjectHeader, {
  VIEW_ANSWERS,
  VIEW_QUESTIONNAIRE,
} from '../../../components/enablePublicProjectHeader/enablePublicProjectHeader';
import useProjectData from '../../../hooks/useProjectData';
import DistributionQuestions from './components/distributionQuestions/distributionQuestions';
import DistributionAnswers from './components/distributionAnswers/distributionAnswers';
import getAllDistributionValues from '../../../utilities/distribution/getAllDistributionValues';

function Distribution() {
  const queryCache = useQueryCache();
  const { projectId } = useParams();
  const { t } = useTranslation();
  const { authToken } = useAuth();
  const [activeView, setActiveView] = useState(VIEW_QUESTIONNAIRE);
  const { project } = useProjectData(projectId);
  const [redirectTo, setRedirectTo] = useState(null);
  const [valuesHaveChanged, setValuesHaveChanged] = useState(false);
  const [newDistribution, setNewDistribution] = useState(null);
  const {
    distribution,
    isLoading: isLoadingDistribution,
    error,
    refetch,
    isSuccess: hasRetrievedDistribution,
  } = useDistribution(projectId);
  const [mutate, { isLoading }] = useMutation(saveDistribution, {
    throwOnError: true,
    onSuccess: () => {
      queryCache.invalidateQueries('projectDistribution');
    },
  });

  async function handleSaveDistribution(
    nextLocation = generatePath(ROUTE_PROJECT_DISTRIBUTION_RESULT, {
      projectId,
    })
  ) {
    try {
      if (newDistribution) {
        await mutate({
          id: projectId,
          authToken,
          data: {
            distributionId: distribution?.id || null,
            unintentional_complier: newDistribution.unintentionalComplier,
            unintentional_offender: newDistribution.unintentionalOffender,
            spontaneous: newDistribution.spontaneous,
            deterred: newDistribution.deterred,
            principled: newDistribution.principled,
            opportunity: newDistribution.opportunity,
            calculating:
              newDistribution.nonPrincipled - newDistribution.opportunity,
          },
        });
      }
      setRedirectTo(nextLocation);
    } catch (e) {
      console.log('e', e); // eslint-disable-line
    }
  }

  async function handleSaveAndNavigate(nextLocation) {
    await handleSaveDistribution(nextLocation);
  }

  function handleDistributionChange(newDistributionValues) {
    if (!valuesHaveChanged) setValuesHaveChanged(true);

    setNewDistribution({
      ...getAllDistributionValues(newDistributionValues),
    });
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  const publicFormEnabled = !!project?.public_distribution_id;

  return (
    <>
      <EnablePublicProjectHeader
        type="distribution"
        publicFormEnabled={publicFormEnabled}
        activeView={activeView}
        onChangeActiveView={setActiveView}
        answersCount={project?.public_distributions_count}
      />
      {(isLoading || isLoadingDistribution) && <FixedLoader />}
      <div className="container">
        {error && (
          <div className="row">
            <div className="col-12 mx-4 mt-2">
              <Errors errors={[error]} />
              <Button onClick={refetch}>{t('content.label.retry')}</Button>
            </div>
          </div>
        )}
      </div>

      {activeView === VIEW_QUESTIONNAIRE && hasRetrievedDistribution && (
        <DistributionQuestions
          defaultDistribution={newDistribution || distribution}
          disabled={!hasRetrievedDistribution || isLoading}
          onChange={handleDistributionChange}
        />
      )}
      {activeView === VIEW_ANSWERS && (
        <DistributionAnswers
          currentUserDistribution={newDistribution || distribution}
          disabled={!hasRetrievedDistribution || isLoading}
          onChange={handleDistributionChange}
        />
      )}

      <div className="container mb-5">
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <NextButton
              onClick={() => handleSaveDistribution()}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>

      <ConfirmNavigationModal
        shouldTriggerConfirmation={valuesHaveChanged}
        title={t('content.questionnaire.distribution.confirm.title')}
        text={t('content.questionnaire.distribution.confirm.text')}
        confirmLabel={t('content.label.distribution.confirm.ok')}
        cancelLabel={t('content.label.distribution.confirm.cancel')}
        extraActions={[
          {
            label: t('content.label.distribution.confirm.saveAndLeave'),
            onClick: handleSaveAndNavigate,
          },
        ]}
      />
    </>
  );
}

export default Distribution;
