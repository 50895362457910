import React, { useEffect, useState } from 'react';
import Modal from '../modal/modal';
import nl2br from '../../utilities/string/nl2br';

import styles from './intakeInfoModal.module.scss';

function IntakeInfoModal({ intakeQuestions }) {
  const [intakeQuestionId, setIntakeQuestionId] = useState(null);
  const [intakeQuestion, setIntakeQuestion] = useState(null);

  useEffect(() => {
    if (
      intakeQuestionId &&
      intakeQuestions &&
      (!intakeQuestion || intakeQuestionId !== intakeQuestion.id)
    ) {
      const newIntakeQuestion = intakeQuestions.find(
        (iq) => iq.id === parseInt(intakeQuestionId)
      );
      if (newIntakeQuestion) {
        setIntakeQuestion(newIntakeQuestion);
      }
    } else if (intakeQuestion && !intakeQuestionId) {
      setIntakeQuestion(null);
    }
  }, [intakeQuestionId, intakeQuestions, intakeQuestion]);

  function openIntakeQuestionModal(e) {
    setIntakeQuestionId(e.target.attributes.getNamedItem('intake-info').value);
  }

  function closeModal() {
    setIntakeQuestionId(null);
  }

  useEffect(() => {
    if (intakeQuestions) {
      const intakeInfos = document.querySelectorAll('[intake-info]');
      intakeInfos.forEach((intakeInfoButton) => {
        const intakeIds = intakeInfoButton.attributes
          .getNamedItem('intake-info')
          .value.split('|')
          .map((id) => parseInt(id, 10));
        const intakeQuestion = intakeQuestions.find((intakeQuestion) =>
          intakeIds.includes(intakeQuestion.id)
        );
        if (intakeQuestion) {
          intakeInfoButton.setAttribute('intake-info', intakeQuestion.id);
          intakeInfoButton.addEventListener('click', openIntakeQuestionModal);
        } else {
          intakeInfoButton.removeAttribute('intake-info');
        }
      });

      return () => {
        intakeInfos.forEach((intakeInfoButton) => {
          intakeInfoButton.removeEventListener(
            'click',
            openIntakeQuestionModal
          );
        });
      };
    }
  }, [intakeQuestions]);

  return (
    <Modal
      active={!!intakeQuestion}
      onClose={closeModal}
      title={intakeQuestion && intakeQuestion.question}
      className={styles.modal}
      backdropClassName={styles.backdrop}
    >
      {intakeQuestion?.answer && (
        <p dangerouslySetInnerHTML={{ __html: nl2br(intakeQuestion.answer) }} />
      )}
    </Modal>
  );
}

export default IntakeInfoModal;
