import { useTranslation } from 'react-i18next';

import SunburstEN from '../content/download/EN/sunburst.json';
import SunburstNL from '../content/download/NL/sunburst.json';

export default function useSunburstData() {
  const { i18n } = useTranslation();

  let sunburst = SunburstNL;
  if (i18n.language === 'en') {
    sunburst = SunburstEN;
  }

  return sunburst;
}
