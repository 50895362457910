import React, { useCallback, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import FixedLoader from '../../../../components/loader/fixedLoader';
import useResponseExceptionHandler from '../../../../hooks/useResponseExceptionHandler';
import Project from '../../../../components/project/project';
import EmptyProject from '../../../../components/project/emptyProject';

import getProjects from '../../../../queries/getProjects';

function ProjectList() {
  const { t } = useTranslation();
  const [globalError, setGlobalError] = useState(null);
  const handleResponseException = useResponseExceptionHandler();
  const {
    isLoading,
    isError,
    data: projects,
    error,
  } = useQuery('projects', getProjects);

  const handleError = useCallback(
    (e, imageMap) => {
      const error = handleResponseException(e, imageMap);
      setGlobalError(error);
    },
    [handleResponseException]
  );

  useEffect(() => {
    if (isError && error) {
      handleError(error);
    }
  }, [isError, error, handleError]);

  return (
    <>
      <h1>{t('content.project.yourProjects')}</h1>
      {isLoading && <FixedLoader />}
      {globalError && (
        <Alert
          variant="danger"
          dismissible
          onClick={() => setGlobalError(null)}
        >
          {globalError}
        </Alert>
      )}
      {projects && projects.length > 0 ? (
        projects.map((project) => (
          <Project
            key={project.id}
            id={project.id}
            title={project.title}
            metadata={project.metadata}
            analysisQuestionsCount={project.analysis_questions_count}
            distributionCount={project.distribution_count}
            zoomed={project.zoomed}
            finished={project.finished}
            startdate={project.startdate}
            type={project.type}
            allowDeleteProject
          />
        ))
      ) : (
        <EmptyProject />
      )}
    </>
  );
}

export default ProjectList;
