import { useTranslation } from 'react-i18next';

import AnalysisQuestionsEN from '../content/download/EN/analysis_questions.json';
import AnalysisQuestionsNL from '../content/download/NL/analysis_questions.json';

export type ChildQuestion = {
  id: number;
  question: string;
  mechanismId: number;
  answers: string[];
  score: null;
  children?: never[];
  subquestion: string;
  subquestion_mandatory: boolean;
  info?: string;
  core: string;
  projectType: ProjectType;
};

export type Question = {
  name: string;
  category: string;
  id: number;
  colors: string[];
  children: ChildQuestion[];
};

function useAnalysisQuestions(type?: ProjectType) {
  const { i18n } = useTranslation();

  if (!type) {
    return {
      questions: [],
      questionCount: 0,
    };
  }

  let questions: Question[] = AnalysisQuestionsNL;
  if (i18n.language === 'en') {
    questions = AnalysisQuestionsEN;
  }

  const questionsByType: Question[] = [];

  questions.forEach((question) => {
    questionsByType.push({
      ...question,
      children: question.children.filter(
        (child) => child.projectType === '' || child.projectType === type
      ),
    });
  });

  return {
    questions: questionsByType,
    questionCount:
      questionsByType?.reduce(
        (accumulator, category) => accumulator + category.children.length,
        0
      ) || 0,
  };
}

export default useAnalysisQuestions;
