import SaveLoader from '../loader/saveLoader';
import cn from 'classnames';
import { Alert } from 'react-bootstrap';
import styles from '../../pages/public/publicAnalysis/publicAnalysis.module.scss';
import IKButton from '../button/IKButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Content from '../content/content';
import { useForm } from 'react-hook-form';
import useResponseExceptionHandler from '../../hooks/useResponseExceptionHandler';
import SaveSuccess from '../../pages/public/saveSuccess/saveSuccess';

function PublicForm({
  isLoading,
  mutateData,
  children,
  disabled,
  submitWarning,
}) {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [error, setError] = useState(null);
  const handleResponseException = useResponseExceptionHandler();

  async function handleSaveData(data) {
    if (!disabled) {
      if (error) setError(null);
      try {
        await mutateData(data);
        setSaveSuccess(true);
      } catch (e) {
        const error = handleResponseException(e);
        setError(error);
      }
    }
  }

  if (saveSuccess) {
    return <SaveSuccess />;
  }

  return (
    <Content>
      <>
        {isLoading && <SaveLoader />}
        <form onSubmit={handleSubmit(handleSaveData)}>
          <div className="container my-4">
            <div className="row">
              <div className="col-8 offset-2">
                <div className="form-group">
                  <label>{t('content.publicAnalysis.name')}</label>
                  <input
                    type="text"
                    name="name"
                    ref={register({
                      maxLength: {
                        value: 60,
                        message: t('validation.maxLength', { maxLength: 60 }),
                      },
                    })}
                    className={cn('form-control', {
                      'is-invalid': errors.name,
                    })}
                  />
                  {errors.name && (
                    <div className="invalid-feedback">
                      {errors.name.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {children}

          <div className="container">
            {error && (
              <div className="row">
                <div className="col-12">
                  <Alert type="danger">{error}</Alert>
                </div>
              </div>
            )}
            <div className="row pb-5">
              <div className="col-8 offset-2 d-flex justify-content-center align-items-center">
                {submitWarning && (
                  <p className={styles.submitWarning}>{submitWarning}</p>
                )}
              </div>
              <div className="col-2 d-flex justify-content-end">
                <div className={styles.submitButton}>
                  <IKButton type="submit" disabled={disabled}>
                    <div className={styles.label}>
                      {t('content.publicAnalysis.submit')}
                    </div>
                  </IKButton>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    </Content>
  );
}

export default PublicForm;
