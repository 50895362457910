import React, { useEffect, useMemo, useState } from 'react';
import ReactSlider from 'react-slider';
import cn from 'classnames';

import styles from './distributionSlider.module.scss';

function DistributionSlider({
  disabled,
  value,
  max,
  leftColor = '#FF0000',
  leftLabel,
  rightColor = '#ffff00',
  rightLabel,
  topColor = 'transparent',
  topLabel,
  onChange,
  size = 'large',
}) {
  const [sliderValue, setSliderValue] = useState(value);

  useEffect(() => {
    if (value !== sliderValue) {
      setSliderValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (max < sliderValue) {
      setSliderValue(max);
      onChange(max);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [max, onChange]);

  const leftWidth = useMemo(
    () => (max > 0 ? (100 / max) * sliderValue : 0),
    [sliderValue, max]
  );

  return (
    <div
      className={cn(styles.distributionSliderContainer, {
        [styles.disabled]: disabled,
        [styles.small]: size === 'small',
      })}
    >
      <ReactSlider
        disabled={disabled}
        max={max}
        className={styles.distributionSlider}
        trackClassName={styles.track}
        renderThumb={(props, state) => (
          <div
            {...props}
            style={{ ...props.style, backgroundColor: leftColor }}
            className={styles.thumb}
          >
            {state.valueNow}
          </div>
        )}
        onChange={setSliderValue}
        onAfterChange={onChange}
        value={sliderValue}
      />
      <div className={styles.sliderBackgroundContainer}>
        <div
          className={styles.left}
          style={{ width: `${leftWidth}%`, backgroundColor: leftColor }}
        />
      </div>
      <div
        className={styles.leftBackgroundOffset}
        style={{ backgroundColor: leftColor }}
      />
      <div
        className={styles.rightBackground}
        style={{ backgroundColor: rightColor }}
      />
      {topLabel && (
        <div
          className={styles.topBackground}
          style={{ backgroundColor: topColor }}
        >
          <p>
            {topLabel}: {max}%
          </p>
        </div>
      )}

      <div
        className={styles.leftLabelContainer}
        style={{ width: `${leftWidth}%` }}
      >
        <p>
          {sliderValue}% {leftLabel}
        </p>
      </div>
      <div
        className={styles.rightLabelContainer}
        style={{ width: `${100 - leftWidth}%` }}
      >
        <p>{`${max - sliderValue}% ${rightLabel}`}</p>
      </div>
    </div>
  );
}

export default DistributionSlider;
