import React from 'react';
import ModalComponent from '../modal/modal';

function ConfirmNavigationModal({
  active,
  title,
  text,
  confirmLabel,
  cancelLabel,
  onClose,
  onConfirm,
}) {
  return (
    <ModalComponent
      title={title}
      active={active}
      onClose={onClose}
      actions={[
        {
          label: cancelLabel,
          onClick: onClose,
          variant: 'outline-primary',
        },
        {
          label: confirmLabel,
          onClick: onConfirm,
        },
      ]}
    >
      {text}
    </ModalComponent>
  );
}

export default ConfirmNavigationModal;
