import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './styles/main.scss';
import App from './App';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <ReactQueryCacheProvider queryCache={queryCache}>
    <App />
  </ReactQueryCacheProvider>,
  document.getElementById('root')
);
