import axios from 'axios';

const getProject = (key, { id }) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/project/${id}`, {
      validateStatus: (status) => status === 200,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
      },
    })
    .then((result) => result.data.result);
};

export default getProject;
