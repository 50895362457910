import React from 'react';

function Errors({ errors }) {
  return (
    errors &&
    errors.map((error, i) => {
      if (Array.isArray(error)) {
        return <Errors errors={error} />;
      }
      return (
        <p key={i} className="text-danger">
          {error}
        </p>
      );
    })
  );
}

export default Errors;
