import axios from 'axios';

const downloadProjectReport = ({ authToken, id, formData }) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/project/${id}/download_report`,
    formData,
    {
      validateStatus: (status) => status === 200,
      responseType: 'blob', // Important
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};

export default downloadProjectReport;
