import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FaqsEN from '../content/download/EN/faqs.json';
import FaqsNL from '../content/download/NL/faqs.json';
import { useAuth } from '../context/auth';

export default function useFaqs() {
  const { authToken } = useAuth();
  const { i18n } = useTranslation();
  const [faqs, setFaqs] = useState(null);

  useEffect(() => {
    let faqs = FaqsNL;
    if (i18n.language === 'en') {
      faqs = FaqsEN;
    }
    setFaqs(
      faqs.filter((faq) => {
        if (authToken) {
          return faq.showAfterLogin;
        }
        return faq.showBeforeLogin;
      })
    );
  }, [authToken, i18n.language]);

  return {
    faqs,
  };
}
