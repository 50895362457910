import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface State {
  authToken?: string;
  type?: ProjectType;
  setAuthToken: (authToken: string) => void;
  setType: (type: ProjectType) => void;
}

const useStore = create<State>()(
  devtools(
    (set) => ({
      authToken: undefined,
      type: undefined,
      setAuthToken: (authToken: string) => set({ authToken }),
      setType: (type: ProjectType) => set({ type }),
    }),
    { enabled: process.env.NODE_ENV === 'development' }
  )
);

export const selectAuthToken = ({ authToken, setAuthToken }: State) => ({
  authToken,
  setAuthToken,
});
export const selectType = ({ type, setType }: State) => ({
  type,
  setType,
});

export default useStore;
