import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ContentPage from '../../components/contentPage/contentPage';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import ColumnContent from '../../components/columnContent/columnContent';
import BackButton from '../../components/backButton/backButton';
import Footer from '../../components/footer/footer';
import React from 'react';

export default function Privacy() {
  const { t } = useTranslation();
  const history = useHistory();

  function handleGoBackClick() {
    history.goBack();
  }

  return (
    <ContentPage>
      <ContentImageBackground />
      <ColumnContent>
        <h1>{t('privacy_title')}</h1>
        <Trans i18nKey="privacy_text0">
          <h4>Algemeen</h4>
          <p>
            Het CCV verwerkt ten behoeve van het gebruik van het
            Interventiekompas persoonsgegevens. Het CCV maakt gebruik van de
            privacy- en cookieverklaring CCV, zoals vermeld op de website van
            het CCV, www.hetccv.nl, om te voldoen aan de
            informatieverplichtingen op grond van artikel 13 en 14 van de
            Algemene Verordening Gegevensbescherming.
          </p>
          <h4>Specifiek bij gebruik van het InterventieKompas</h4>
          <p>
            Door het InterventieKompas te gebruiken laat je persoonsgegevens bij
            het CCV achter die nodig zijn om je toegang tot jouw account op de
            website te geven. Het CCV bewaart en gebruikt deze gegevens alleen
            om op geaggregeerd niveau inzicht te krijgen in het gebruik van het
            InterventieKompas. Deze gegevens worden vertrouwelijk behandeld en
            niet met derden gedeeld. Het betreft de volgende gegevens:
          </p>
          <ul>
            <li>e-mailadres;</li>
            <li>voornaam;</li>
            <li>achternaam;</li>
            <li>organisatie;</li>
            <li>functie;</li>
            <li>gebruikersnaam;</li>
            <li>wachtwoord.</li>
          </ul>
          <p>
            Als je je gegevens (geanonimiseerd) ter beschikking stelt voor het
            verbeteren van het InterventieKompas, heeft het CCV tevens inzicht
            in de aangemaakte projecten. Deze gegevens worden zorgvuldig door
            het CCV bewaard. Het CCV gebruikt deze gegevens alleen om het
            InterventieKompas te verbeteren. Deze gegevens worden vertrouwelijk
            behandeld en niet met derden gedeeld. Als je je gegevens niet ter
            beschikking stelt, dan heeft het CCV geen toegang tot jouw
            projecten.
          </p>
          <p>
            Het CCV neemt beveiligingsmaatregelen om misbruik van en
            ongeautoriseerde toegang tot persoonsgegevens te beperken.
          </p>
          <h4>Bewaartermijn</h4>
          <p>
            Wanneer je ons toestemming hebt gegeven voor de verwerking van
            gegevens of je je ergens voor hebt aangemeld, dan verwerken wij de
            gegevens totdat je jouw toestemming intrekt of de gegevens zelf
            verwijdert. Accounts die langer dan twee jaar inactief zijn, worden
            verwijderd.
          </p>
          <h4>Inzage, wijzigen of verwijderen van je gegevens</h4>
          <p>
            Indien je via onze website aan ons persoonsgegevens hebt verstrekt,
            kun je het CCV een verzoek toesturen om deze gegevens in te zien, te
            wijzigen of te verwijderen. Je kunt hiervoor per e-mail contact met
            ons opnemen via <a href="mailto:info@hetccv.nl">info@hetccv.nl</a>.
            Om misbruik te voorkomen, kunnen wij je daarbij vragen om je
            adequaat te identificeren.
          </p>
          <h4>Cookies</h4>
          <a href="mailto:info@hetccv.nl">info@hetccv.nl</a>{' '}
          {/* FOR TRANSLATION PURPOSES */}
        </Trans>
        <script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/460fbecf-23f6-4874-85e5-1496b4f83ce3/cd.js"
          type="text/javascript"
          async
        ></script>
        <Trans i18nKey="privacy_text1">
          <h4>Contactgegevens</h4>
          <p>
            Als je vragen hebt over jouw privacy of onze privacy- en
            cookieverklaring, dan kun je deze stellen aan onze functionaris voor
            de gegevensbescherming door te mailen naar privacy(at)hetccv.nl, of
            door telefonisch contact met de functionaris voor
            gegevensbescherming op te nemen via 030 - 751 6703.
          </p>
          <p>
            Stichting Centrum voor Criminaliteitspreventie en Veiligheid
            <br />
            Churchilllaan 11, 18e etage
            <br />
            3527 GV Utrecht
          </p>
          <p>KvK-nummer: 27268679</p>
          <p>
            Deze privacy- en cookieverklaring is voor het laatst aangepast in
            augustus 2021.
          </p>
        </Trans>
        <div className="mt-6 float-right">
          <BackButton onClick={handleGoBackClick} />
        </div>
      </ColumnContent>
      <Footer />
    </ContentPage>
  );
}
