import React from 'react';
import ProjectList from './components/projectList/projectList';
import MainMenu from '../../components/menu/mainMenu';

function Projects() {
  return (
    <div className="container">
      <div className="row my-3">
        <div className="col-6">
          <ProjectList />
        </div>
        <div className="col-6">
          <MainMenu />
        </div>
      </div>
    </div>
  );
}

export default Projects;
