import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  COLOR_TARGET_GROUP_CALCULATING,
  COLOR_TARGET_GROUP_COMPLYING,
  COLOR_TARGET_GROUP_DETERRED,
  COLOR_TARGET_GROUP_OFFENDER,
  COLOR_TARGET_GROUP_OPPORTUNITY,
  COLOR_TARGET_GROUP_PRINCIPLED,
  COLOR_TARGET_GROUP_SPONTANEOUS,
  COLOR_TARGET_GROUP_UNINTENTIONAL_COMPLIER,
  COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER,
} from '../../../constants/colors';
import { generatePath, useParams } from 'react-router-dom';
import useDistribution from '../../../hooks/useDistribution';
import FixedLoader from '../../../components/loader/fixedLoader';
import Errors from '../../../components/errors/errors';
import { Button } from 'react-bootstrap';

import styles from './distributionResult.module.scss';
import {
  ROUTE_PROJECT_DISTRIBUTION,
  ROUTE_PROJECT_RESULT,
} from '../../../constants/routes';
import DistributionResultRow from './components/distributionResultRow/distributionResultRow';
import NextButton from '../../../components/nextButton/nextButton';
import BackButton from '../../../components/backButton/backButton';

function DistributionResult() {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const {
    distribution,
    isLoading: isLoadingDistribution,
    error,
    refetch,
  } = useDistribution(projectId);

  return (
    <>
      {isLoadingDistribution && <FixedLoader />}
      <div className="container">
        {error && (
          <div className="row">
            <div className="col-12 mx-4 mt-2">
              <Errors errors={[error]} />
              <Button onClick={refetch}>{t('content.label.retry')}</Button>
            </div>
          </div>
        )}
      </div>

      <div className="row no-gutters mt-2">
        <div
          className={`col-12 ${styles.header}`}
          style={{ backgroundColor: COLOR_TARGET_GROUP_COMPLYING }}
        >
          <h1>{t('content.distribution.complying')}</h1>
        </div>
      </div>
      <div className="container">
        <DistributionResultRow
          label={t('content.distribution.unintentionalComplier')}
          color={COLOR_TARGET_GROUP_UNINTENTIONAL_COMPLIER}
          value={distribution.unintentionalComplier}
        />
        <DistributionResultRow
          label={t('content.distribution.spontaneous')}
          color={COLOR_TARGET_GROUP_SPONTANEOUS}
          value={distribution.spontaneous}
        />
        <DistributionResultRow
          label={t('content.distribution.deterred')}
          color={COLOR_TARGET_GROUP_DETERRED}
          value={distribution.deterred}
        />
      </div>

      <div className="row no-gutters">
        <div
          className={`col-12 ${styles.header}`}
          style={{ backgroundColor: COLOR_TARGET_GROUP_OFFENDER }}
        >
          <h1>{t('content.distribution.offender')}</h1>
        </div>
      </div>
      <div className="container mb-5">
        <DistributionResultRow
          label={t('content.distribution.unintentionalOffender')}
          color={COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER}
          value={distribution.unintentionalOffender}
        />
        <DistributionResultRow
          label={t('content.distribution.opportunity')}
          color={COLOR_TARGET_GROUP_OPPORTUNITY}
          value={distribution.opportunity}
        />
        <DistributionResultRow
          label={t('content.distribution.principled')}
          color={COLOR_TARGET_GROUP_PRINCIPLED}
          value={distribution.principled}
        />
        <DistributionResultRow
          label={t('content.distribution.calculating')}
          color={COLOR_TARGET_GROUP_CALCULATING}
          value={distribution.calculating}
        />
        <div className="row mt-3">
          <div className="col-6">
            <BackButton
              to={generatePath(ROUTE_PROJECT_DISTRIBUTION, { projectId })}
            />
          </div>
          <div className="col-6 d-flex justify-content-end">
            <NextButton
              to={generatePath(ROUTE_PROJECT_RESULT, { projectId })}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DistributionResult;
