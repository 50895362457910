import axios from 'axios';

export default function deleteProject({ authToken, id }) {
  return axios.delete(`${process.env.REACT_APP_API_URL}/project/${id}`, {
    validateStatus: (status) => status === 200,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
}
