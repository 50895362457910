import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './mainMenu.module.scss';
import IKButton from '../button/IKButton';
import axios from 'axios';
import { useAuth } from '../../context/auth';
import { Redirect, generatePath } from 'react-router-dom';
import { ROUTE_PROJECT_INTAKE } from '../../constants/routes';
import useResponseExceptionHandler from '../../hooks/useResponseExceptionHandler';

function MainMenu() {
  const [isLoading, setIsLoading] = useState(false);
  const [createdProjectId, setCreatedProjectId] = useState(null);
  const { authToken } = useAuth();
  const { t } = useTranslation();
  const handleResponseException = useResponseExceptionHandler();

  function handleNewProjectClick() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/project`,
        {},
        {
          validateStatus: (status) => status === 201,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setCreatedProjectId(response.data.result);
      })
      .catch((e) => {
        handleResponseException(e);
      });
  }

  if (createdProjectId) {
    return (
      <Redirect
        to={generatePath(ROUTE_PROJECT_INTAKE, { projectId: createdProjectId })}
      />
    );
  }

  return (
    <div className={styles.mainMenu}>
      <div className={styles.main}>
        <IKButton onClick={handleNewProjectClick} disabled={isLoading} priority>
          <div className={styles.label}>{t('content.menu.newProject')}</div>
        </IKButton>
      </div>
    </div>
  );
}

export default MainMenu;
