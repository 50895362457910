import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import pingUser from '../queries/pingUser';
import { loadReCaptcha } from 'react-recaptcha-v3';

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthContextProvider({ children }) {
  const { t } = useTranslation();
  const existingToken = sessionStorage.getItem('authToken');
  const [authToken, setAuthToken] = useState(existingToken);
  const [authError, setAuthError] = useState(null);
  const refreshTokenInterval = useRef(null);
  const [mutate] = useMutation(pingUser, { throwOnError: true });

  const setToken = (token, reason = null) => {
    if (token) {
      sessionStorage.setItem('authToken', token);
    } else {
      sessionStorage.removeItem('authToken');
    }

    if (token === null && reason === 'INACTIVE_TOO_LONG') {
      setAuthError(t('error.login.loggedOutInactive'));
    }
    setAuthToken(token);
  };

  useEffect(() => {
    refreshAuthToken();
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function refreshAuthToken() {
    try {
      const token = await mutate({
        authToken: sessionStorage.getItem('authToken'),
      });
      setToken(token);
    } catch (e) {
      if (e.response?.status === 401) {
        if (e.response.data.result === 'INACTIVE_TOO_LONG') {
          setToken(null, 'INACTIVE_TOO_LONG');
        } else {
          setToken(null);
        }
      }
    }
  }

  useEffect(() => {
    if (authToken) {
      if (authError) setAuthError(null);
      refreshTokenInterval.current = setInterval(refreshAuthToken, 60000 * 3); // run every 3 minutes, token expires after 5
    } else if (refreshTokenInterval.current) {
      clearInterval(refreshTokenInterval.current);
    }

    return () => {
      if (refreshTokenInterval.current) {
        clearInterval(refreshTokenInterval.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  return (
    <AuthContext.Provider
      value={{ authToken, authError, setAuthToken: setToken }}
    >
      {children}
    </AuthContext.Provider>
  );
}
