import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import styles from './faqs.module.scss';
import Card from 'react-bootstrap/Card';
import cn from 'classnames';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import useFaqs from '../../hooks/useFaqs';
import nl2br from '../../utilities/string/nl2br';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import ColumnContent from '../../components/columnContent/columnContent';
import { useHistory } from 'react-router-dom';
import BackButton from '../../components/backButton/backButton';
import ContentPage from '../../components/contentPage/contentPage';
import Footer from '../../components/footer/footer';

function ContextAwareToggle({ title, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div className={styles.head} onClick={decoratedOnClick}>
      <h3 className={styles.title}>{title}</h3>
      <i
        className={cn('fas', {
          'fa-caret-up': isCurrentEventKey,
          'fa-caret-down': !isCurrentEventKey,
        })}
      />
    </div>
  );
}

function Faqs() {
  const { t } = useTranslation();
  const history = useHistory();
  const { faqs } = useFaqs();

  function handleGoBackClick() {
    history.goBack();
  }

  return (
    <ContentPage>
      <ContentImageBackground />
      <ColumnContent>
        <h1>{t('content.faq.title')}</h1>

        <Accordion className={styles.faqs}>
          {faqs?.map((faq, i) => {
            const x = i + 1;
            return (
              <Card key={i} className={`mb-3 ${styles.faq}`}>
                <ContextAwareToggle
                  eventKey={i.toString()}
                  number={x}
                  title={faq.question}
                />
                <Accordion.Collapse eventKey={i.toString()}>
                  <p
                    dangerouslySetInnerHTML={{ __html: nl2br(faq.answer) }}
                    className={styles.content}
                  />
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>

        <div className="mt-6 float-right">
          <BackButton onClick={handleGoBackClick} />
        </div>
      </ColumnContent>
      <Footer />
    </ContentPage>
  );
}

export default Faqs;
