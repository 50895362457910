import React from 'react';
import cn from 'classnames';

import styles from './checkboxWithLabel.module.scss';

function CheckboxWithLabel({
  label,
  checked,
  checkboxColor,
  labelColor,
  onClick,
}) {
  return (
    <div
      className={cn(`row no-gutters ${styles.checkboxWithLabel}`, {
        [styles.clickable]: !!onClick,
      })}
      onClick={onClick}
    >
      <div className={`col-auto ${styles.checkboxContainer}`}>
        <button
          className={cn(styles.checkbox, {
            [styles.checked]: checked,
          })}
          style={{
            borderColor: checkboxColor,
          }}
        >
          <div
            className={styles.background}
            style={{ backgroundColor: checkboxColor }}
          />
        </button>
      </div>
      <div className="col">
        <label style={{ color: labelColor }}>{label}</label>
      </div>
    </div>
  );
}

export default CheckboxWithLabel;
