import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import getAnalysis from '../queries/getAnalysis';
import useResponseExceptionHandler from './useResponseExceptionHandler';
import useAnalysisQuestions from './useAnalysisQuestions';
import useProjectData from './useProjectData';
import { useTranslation } from 'react-i18next';

function combine(questionCategories, data) {
  if (!data) return questionCategories;

  return questionCategories.map((category) => ({
    ...category,
    children: category.children.map((question) => {
      const backendQuestionData = data.find(
        (q) => q.question_id === question.id
      );
      if (backendQuestionData) {
        return {
          ...question,
          score: backendQuestionData.score,
          subQuestionAnswer: backendQuestionData.subquestion_answer,
        };
      }
      return question;
    }),
  }));
}

function useAnalysisQuestionsWithData(projectId) {
  const { i18n } = useTranslation();
  const { project } = useProjectData(projectId);
  const { questions, questionCount } = useAnalysisQuestions(project?.type);
  const [error, setError] = useState(null);
  const [analysisQuestions, setAnalysisQuestions] = useState(null);
  const [analysisQuestionsDataSet, setAnalysisQuestionsDataSet] =
    useState(false);
  const handleResponseException = useResponseExceptionHandler();
  const {
    isLoading,
    isError,
    data,
    error: queryError,
  } = useQuery(['projectAnalysis', { id: projectId }], getAnalysis);

  const handleError = useCallback(
    (e) => {
      const error = handleResponseException(e);
      setError(error);
    },
    [setError, handleResponseException]
  );

  useEffect(() => {
    if (isError && queryError) {
      handleError(queryError);
    } else if (error) {
      setError(null);
    }
  }, [error, isError, queryError, handleError]);

  function combineAnalysisQuestionsAndData() {
    setAnalysisQuestions(combine([...questions], data));
    if (data && !analysisQuestionsDataSet) {
      setAnalysisQuestionsDataSet(true);
    }
  }

  useEffect(() => {
    if (project) {
      combineAnalysisQuestionsAndData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, project, i18n.language]);

  return {
    isLoading,
    analysisQuestionsDataSet,
    error,
    analysisQuestionCount: questionCount,
    analysisQuestions,
    analysisQuestionsAnsweredCount: analysisQuestions
      ? analysisQuestions.reduce(
          (acc, analysisQuestionCategory) =>
            analysisQuestionCategory.children.filter((q) => q.score !== null)
              .length + acc,
          0
        )
      : 0,
  };
}

export default useAnalysisQuestionsWithData;
