import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ROUTE_PUBLIC_PROJECT_ANALYSIS,
  ROUTE_PUBLIC_PROJECT_DISTRIBUTION,
} from './constants/routes';
import PublicAnalysis from './pages/public/publicAnalysis/publicAnalysis';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import Header from './components/header/header';
import { AuthContext } from './context/auth';
import PublicDistribution from './pages/public/publicDistribution/publicDistribution';

function PublicApp() {
  return (
    // in PublicApp we always provide null authToken, because other components require it, but we don't want to be logged in as a regular user
    <AuthContext.Provider value={{ authToken: null }}>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route path={ROUTE_PUBLIC_PROJECT_ANALYSIS}>
          <PublicAnalysis />
        </Route>
        <Route path={ROUTE_PUBLIC_PROJECT_DISTRIBUTION}>
          <PublicDistribution />
        </Route>
      </Switch>
    </AuthContext.Provider>
  );
}

export default PublicApp;
