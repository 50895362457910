import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import {
  ROUTE_FORGOT_PASSWORD,
  ROUTE_PROJECTS,
  ROUTE_REGISTER,
} from '../../../../constants/routes';

import { useAuth } from '../../../../context/auth';
import FixedLoader from '../../../../components/loader/fixedLoader';
import Errors from '../../../../components/errors/errors';
import useResponseExceptionHandler from '../../../../hooks/useResponseExceptionHandler';

function Login() {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const location = useLocation();
  const referer = location.state?.referer;
  const [isLoading, setIsLoading] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const { authToken, setAuthToken } = useAuth();
  const handleResponseException = useResponseExceptionHandler();

  function handleFailedLogin(exception) {
    setIsLoading(false);
    const error = handleResponseException(exception, {
      NOT_ACTIVE: t('error.login.accountInactive'),
      IS_BANNED: t('error.login.accountBanned'),
      TOKEN_NOT_EXPIRED: t('error.login.anotherPersonLoggedIn'),
      default: t('error.login.invalidEmailOrPassword'),
    });
    setGlobalError(error);
  }

  function handleLoginSubmit(data) {
    setIsLoading(true);
    setGlobalError(null);

    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, data, {
        validateStatus: (status) => status === 200,
      })
      .then((result) => setAuthToken(result.data.result.auth_token))
      .catch((e) => handleFailedLogin(e));
  }

  if (authToken) {
    return <Redirect to={referer || ROUTE_PROJECTS} />;
  }

  return (
    <form onSubmit={handleSubmit(handleLoginSubmit)}>
      {globalError && <Errors errors={[globalError]} />}
      {isLoading && <FixedLoader />}
      <div className="form-group">
        <label htmlFor="emailInput" className="form-label">
          {t('content.login.email')}
        </label>
        <input
          id="emailInput"
          type="email"
          name="email"
          ref={register({ required: t('validation.required') })}
          className={cn('form-control', { 'is-invalid': errors.email })}
        />
        {errors.email && (
          <div className="invalid-feedback">{errors.email.message}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="passwordInput">{t('content.login.password')}</label>
        <input
          id="passwordInput"
          type="password"
          name="password"
          ref={register({ required: t('validation.required') })}
          className={cn('form-control', { 'is-invalid': errors.password })}
        />
        {errors.password && (
          <div className="invalid-feedback">{errors.password.message}</div>
        )}
      </div>
      <div className="form-group mb-0">
        <button
          type="submit"
          className="btn btn-block btn-primary"
          disabled={isLoading}
        >
          {t('content.label.login')}
        </button>
        <Link
          to={ROUTE_FORGOT_PASSWORD}
          className="btn btn-block btn-link pt-0 mb-3"
        >
          {t('forgotPassword_title')}
        </Link>
        <Link
          to={ROUTE_REGISTER}
          className="btn btn-block btn-outline-primary"
          role="button"
        >
          {t('content.register.createAccount')}
        </Link>
        <p
          dangerouslySetInnerHTML={{ __html: t('content.login.problems') }}
          className="text-center mt-1 mb-0"
        />
      </div>
    </form>
  );
}

export default Login;
