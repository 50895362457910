export default function getAllDistributionValues(distribution) {
  const unintentionalComplier = distribution
    ? distribution.unintentional_complier
    : 0;
  const unintentionalOffender = distribution
    ? distribution.unintentional_offender
    : 0;
  const unknown = distribution
    ? unintentionalComplier + unintentionalOffender
    : 0;
  const spontaneous = distribution ? distribution.spontaneous : 0;
  const deterred = distribution ? distribution.deterred : 0;
  const principled = distribution ? distribution.principled : 0;
  const opportunity = distribution ? distribution.opportunity : 0;
  const calculating = distribution ? distribution.calculating : 0;
  const known = 100 - unknown;
  const nonSpontaneous = known - spontaneous < 0 ? 0 : known - spontaneous;
  const nonDeterred =
    nonSpontaneous - deterred < 0 ? 0 : nonSpontaneous - deterred;
  const nonPrincipled =
    nonDeterred - principled < 0 ? 0 : nonDeterred - principled;

  return {
    unknown,
    unintentionalComplier,
    unintentionalOffender,
    spontaneous,
    deterred,
    principled,
    opportunity,
    calculating,
    known,
    nonSpontaneous,
    nonDeterred,
    nonPrincipled,
  };
}
