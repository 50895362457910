import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ContentPage from '../../components/contentPage/contentPage';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import ColumnContent from '../../components/columnContent/columnContent';
import BackButton from '../../components/backButton/backButton';
import Footer from '../../components/footer/footer';

export default function Accessibility() {
  const { t } = useTranslation();
  const history = useHistory();

  function handleGoBackClick() {
    history.goBack();
  }

  return (
    <ContentPage>
      <ContentImageBackground />
      <ColumnContent>
        <h1>{t('accessibility_title')}</h1>
        <Trans i18nKey="accessibility_text">
          <p>
            Het CCV wil dat elke overheidsprofessional het InterventieKompas kan
            gebruiken. Kom je toch een pagina tegen die niet toegankelijk is?
            Dan kun je dit aan ons melden.
          </p>
          <p>
            Wij streven naar een optimale gebruikerservaring en goede
            toegankelijkheid van het Interventiekompas door diverse maatregelen
            binnen onze processen:
          </p>
          <ul>
            <li>
              Toegankelijkheid ‘by design’: toegankelijkheid is onderdeel van
              alle stappen in het ontwerp-, bouw en redactionele proces van het
              Interventiekompas.
            </li>
            <li>
              Externe evaluatie: externe deskundigen toetsen regelmatig
              (onderdelen van) het Interventiekompas op toegankelijkheid. Zowel
              voor de functioneel-technische onderdelen als voor de redactionele
              aspecten. Gevonden knelpunten lossen wij binnen de budgettaire
              mogelijkheden op.
            </li>
            <li>
              Kennis medewerkers: de medewerkers van de software houden hun
              kennis over toegankelijkheid op peil en passen deze toe waar
              nodig.
            </li>
          </ul>
        </Trans>

        <h4>{t('accessibility_problems_title')}</h4>
        <p>
          <Trans i18nKey="accessibility_problems_text">
            Heb je vragen of opmerkingen? Of wil je een pagina gebruiken die
            niet toegankelijk blijkt? Neem dan contact op met
            <a href="mailto:info@hetccv.nl">info@hetccv.nl</a>.
          </Trans>
        </p>
        <div className="mt-6 float-right">
          <BackButton onClick={handleGoBackClick} />
        </div>
      </ColumnContent>
      <Footer />
    </ContentPage>
  );
}
