import TeamMembers from '../../components/teamMembers/teamMembers';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ContentPage from '../../components/contentPage/contentPage';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import ColumnContent from '../../components/columnContent/columnContent';
import { ROUTE_CONTACT } from '../../constants/routes';
import BackButton from '../../components/backButton/backButton';
import Footer from '../../components/footer/footer';

export default function AdviceAndTraining() {
  const { t } = useTranslation();
  const history = useHistory();

  function handleGoBackClick() {
    history.goBack();
  }

  return (
    <ContentPage>
      <ContentImageBackground />
      <ColumnContent>
        <h1>{t('adviceAndTraining_title')}</h1>
        <h3>{t('adviceAndTraining_processGuidance_title')}</h3>
        <p>
          <Trans i18nKey="adviceAndTraining_text">
            Werken in het InterventieKompas vergt tijdsinvestering; een team is
            al gauw een dagdeel bezig met het invullen en analyseren van de
            uitkomsten. De CCV-procesbegeleiders adviseren en ondersteunen je
            graag bij dit proces en zijn bovendien experts in het gebruik van
            het InterventieKompas, wel zo efficiënt. Neem{' '}
            <Link to={ROUTE_CONTACT}>contact</Link> op en vraag naar de
            mogelijkheden van het inzetten van een CCV-procesbegeleider.
          </Trans>
        </p>
        <h3>{t('adviceAndTraining_training_title')}</h3>
        <p>
          <Trans i18nKey="adviceAndTraining_training_text">
            Je kunt ook de
            <a
              href="https://hetccv.nl/advies-en-training/ccv-academie/interventiekompas-gebruiken/"
              target="_blank"
              rel="noopener noreferrer"
            >
              training InterventieKompas
            </a>
            volgen. Tijdens de training maak je kennis met het
            InterventieKompas. Je leert hoe het instrument werkt en hoe je het
            kunt gebruiken om jouw beleid of toezicht effectiever te maken. De
            training gaat in op het bepalen van de doelgroep, het doelgedrag en
            op de verschillende dimensies uit het kompas die van invloed zijn op
            het gedrag. Ten slotte bepaal je aan de hand van het kompas een
            koers.
          </Trans>
        </p>
        <h4 className="mb-1">{t('adviceAndTraining_targetGroup_title')}</h4>
        <p>{t('adviceAndTraining_targetGroup_text')}</p>

        <h4>{t('adviceAndTraining_trainers_title')}</h4>
        <TeamMembers />
        <h4>{t('adviceAndTraining_signUp_title')}</h4>
        <p>{t('adviceAndTraining_signUp_text')}</p>

        <p className="rounded border border-dark p-3 mt-5 bg-light">
          <Trans i18nKey="adviceAndTraining_externalTraining_text">
            Meer weten over hoe je met gedragskennis gewenst gedrag bevordert?
            Leer het bij de incompany training
            <a
              href="https://hetccv.nl/advies-en-training/ccv-academie/gedragsverandering-voor-toezicht-en-handhaving/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gedragsverandering voor toezicht en handhaving
            </a>
            .
          </Trans>
        </p>
        <div className="mt-6 float-right">
          <BackButton onClick={handleGoBackClick} />
        </div>
      </ColumnContent>
      <Footer />
    </ContentPage>
  );
}
