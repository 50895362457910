import usePublicAuthSession from '../../hooks/usePublicAuthSession';
import usePublicProjectInfo from '../../hooks/usePublicProjectInfo';
import { useParams } from 'react-router-dom';
import Loader from '../loader/loader';
import Errors from '../errors/errors';
import PublicLoginForm from '../publicLoginForm/publicLoginForm';
import { useTranslation } from 'react-i18next';
import useStore, { selectAuthToken } from 'src/hooks/useStore';

function PublicLogin({ type }) {
  const { t } = useTranslation();
  const { publicId } = useParams();
  const { publicProjectInfo, isLoading, error } = usePublicProjectInfo(
    publicId,
    type
  );

  if (error) {
    return (
      <div className="container">
        <div className="row no-gutters">
          <div className="col-4 offset-4 mt-4">
            <Errors errors={[error]} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container h-100">
      <div className="row no-gutters h-100">
        <div className="col-4 offset-4 h-100 d-flex align-items-center">
          {isLoading || !publicProjectInfo ? (
            <Loader />
          ) : (
            <div className="w-100">
              <h3>
                {t('content.publicLogin.title', {
                  projectTitle: publicProjectInfo.title,
                })}
              </h3>
              <p>{t('content.publicLogin.description')}</p>
              <PublicLoginForm hasPassword={publicProjectInfo.hasPassword} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function AuthenticationWall({ children, type }) {
  const { authToken } = useStore(selectAuthToken);

  if (!authToken) {
    return <PublicLogin type={type} />;
  }

  return children;
}

function PublicAuthenticator({ children, type }) {
  usePublicAuthSession(type);

  return <AuthenticationWall type={type}>{children}</AuthenticationWall>;
}

export default PublicAuthenticator;
