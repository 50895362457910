import PublicAuthenticator from '../../../components/publicAuthenticator/publicAuthenticator';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import DistributionQuestions from '../../steps/distribution/components/distributionQuestions/distributionQuestions';
import savePublicDistribution from '../../../queries/savePublicDistribution';
import PublicForm from '../../../components/publicForm/publicForm';
import getAllDistributionValues from '../../../utilities/distribution/getAllDistributionValues';
import { useTranslation } from 'react-i18next';
import useStore, { selectAuthToken } from 'src/hooks/useStore';

function PublicDistributionForm() {
  const { t } = useTranslation();
  const [valuesHaveChanged, setValuesHaveChanged] = useState(false);
  const [distribution, setDistribution] = useState(null);
  const { authToken } = useStore(selectAuthToken);
  const [mutate, { isLoading }] = useMutation(savePublicDistribution, {
    throwOnError: true,
  });

  const disabled = !valuesHaveChanged;

  async function handleSavePublicDistribution(data) {
    return await mutate({
      authToken,
      data: {
        name: data.name,
        distribution: {
          unintentional_complier: distribution.unintentionalComplier,
          unintentional_offender:
            distribution.unknown - distribution.unintentionalComplier,
          spontaneous: distribution.spontaneous,
          deterred: distribution.deterred,
          principled: distribution.principled,
          opportunity: distribution.opportunity,
          calculating: distribution.nonPrincipled - distribution.opportunity,
        },
      },
    });
  }

  function handleDistributionChange(newDistributionValues) {
    if (!valuesHaveChanged) setValuesHaveChanged(true);

    setDistribution({
      ...getAllDistributionValues(newDistributionValues),
    });
  }

  return (
    <PublicForm
      disabled={disabled}
      mutateData={handleSavePublicDistribution}
      isLoading={isLoading}
      submitWarning={
        !valuesHaveChanged
          ? t('content.publicDistribution.answerAllQuestions')
          : null
      }
    >
      <DistributionQuestions onChange={handleDistributionChange} />
    </PublicForm>
  );
}

function PublicDistribution() {
  return (
    <PublicAuthenticator type="distribution">
      <PublicDistributionForm />
    </PublicAuthenticator>
  );
}

export default PublicDistribution;
