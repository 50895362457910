import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import useResponseExceptionHandler from './useResponseExceptionHandler';
import useIntakeQuestions from './useIntakeQuestions';
import getIntake from '../queries/getIntake';
import { useTranslation } from 'react-i18next';

function combine(questions, data) {
  if (!data) return questions;

  return questions.map((intakeQuestion) => {
    const backendQuestion = data.find(
      (backendQuestion) => backendQuestion.question_id === intakeQuestion.id
    );
    return {
      ...intakeQuestion,
      answer: backendQuestion ? backendQuestion.answer : '',
    };
  });
}

function useIntakeQuestionsWithData(projectId, type = null) {
  const { i18n } = useTranslation();
  const { questions, questionCount } = useIntakeQuestions(type);
  const [error, setError] = useState(null);
  const [intakeQuestions, setIntakeQuestions] = useState(null);
  const [intakeQuestionsDataSet, setIntakeQuestionsDataSet] = useState(false);
  const handleResponseException = useResponseExceptionHandler();
  const {
    isLoading,
    isError,
    data,
    error: queryError,
  } = useQuery(['projectIntake', { id: projectId }], getIntake);

  const handleError = useCallback(
    (e) => {
      const error = handleResponseException(e);
      setError(error);
    },
    [setError, handleResponseException]
  );

  useEffect(() => {
    if (isError && queryError) {
      handleError(queryError);
    } else if (error) {
      setError(null);
    }
  }, [error, isError, queryError, handleError]);

  function combineIntakeQuestionsAndData() {
    setIntakeQuestions(combine([...questions], data));
    if (data && !intakeQuestionsDataSet) {
      setIntakeQuestionsDataSet(true);
    }
  }

  useEffect(() => {
    combineIntakeQuestionsAndData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, i18n.language, type]);

  return {
    isLoading,
    intakeQuestionsDataSet,
    error,
    intakeQuestionCount: questionCount,
    intakeQuestions: intakeQuestions,
    intakeQuestionsPre: intakeQuestions?.filter(
      (question) => question.type === 'pre'
    ),
    intakeQuestionsPost: type
      ? intakeQuestions?.filter(
          (question) => question.type === 'post' || question.type === type
        )
      : [],
  };
}

export default useIntakeQuestionsWithData;
