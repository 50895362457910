export const COLOR_TARGET_GROUP_UNKNOWN = '#bab5d8';
export const COLOR_TARGET_GROUP_KNOWN = '#898bb2';

export const COLOR_TARGET_GROUP_COMPLYING = '#9c27b0';
export const COLOR_TARGET_GROUP_UNINTENTIONAL_COMPLIER = '#ce5ee7';
export const COLOR_TARGET_GROUP_SPONTANEOUS = '#e1bee7';
export const COLOR_TARGET_GROUP_DETERRED = '#ce93d8';

export const COLOR_TARGET_GROUP_OFFENDER = '#e91e63';
export const COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER = '#f8bbd0';
export const COLOR_TARGET_GROUP_OPPORTUNITY = '#f06292';
export const COLOR_TARGET_GROUP_PRINCIPLED = '#ec407a';
export const COLOR_TARGET_GROUP_CALCULATING = '#c2185b';
export const COLOR_TARGET_GROUP_UNDEFINED = 'lightgrey';
