export const ROUTE_HOME = '/';
export const ROUTE_REGISTER = '/register';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_RESET_PASSWORD = '/reset-password/:token';
export const ROUTE_FAQ = '/faq';
export const ROUTE_ADVICE_AND_TRAINING = '/advice-and-training';
export const ROUTE_CONTACT = '/contact';
export const ROUTE_CONDITIONS = '/terms-and-conditions';
export const ROUTE_DISCLAIMER = '/disclaimer';
export const ROUTE_COPYRIGHT = '/copyright';
export const ROUTE_PRIVACY = '/privacy-and-cookies';
export const ROUTE_ACCESSIBILITY = '/accessibility';
export const ROUTE_PROJECTS = '/projects';
export const ROUTE_PROJECT_BASE = '/projects/:projectId';
export const ROUTE_PROJECT_INTAKE = ROUTE_PROJECT_BASE;
export const ROUTE_PROJECT_ANALYSIS = `${ROUTE_PROJECT_BASE}/target-group-analysis`;
export const ROUTE_PROJECT_ANALYSIS_INTRO = `${ROUTE_PROJECT_BASE}/target-group-analysis/intro`;
export const ROUTE_PROJECT_ANALYSIS_RESULT = `${ROUTE_PROJECT_BASE}/target-group-analysis/result`;
export const ROUTE_PROJECT_DISTRIBUTION = `${ROUTE_PROJECT_BASE}/target-group-distribution`;
export const ROUTE_PROJECT_DISTRIBUTION_INTRO = `${ROUTE_PROJECT_BASE}/target-group-distribution/intro`;
export const ROUTE_PROJECT_DISTRIBUTION_RESULT = `${ROUTE_PROJECT_BASE}/target-group-distribution/result`;
export const ROUTE_PROJECT_RESULT = `${ROUTE_PROJECT_BASE}/result`;
export const ROUTE_PROJECT_DETAIL = `${ROUTE_PROJECT_BASE}/detail`;
export const ROUTE_PUBLIC = `/public`;
export const ROUTE_PUBLIC_PROJECT_ANALYSIS = `${ROUTE_PUBLIC}/:publicId/analysis`;
export const ROUTE_PUBLIC_PROJECT_DISTRIBUTION = `${ROUTE_PUBLIC}/:publicId/distribution`;
