import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './sunburstHelpTexts.module.scss';

function SunburstHelpTexts({ zoomLevel }) {
  const { t } = useTranslation();

  if (zoomLevel === 0) {
    return (
      <div className={`${styles.helpText} ${styles.helpText0}`}>
        {t('content.result.tip.0')}
      </div>
    );
  }
  if (zoomLevel === 1) {
    return (
      <>
        <div className={`${styles.helpText} ${styles.helpText1}`}>
          {t('content.result.tip.1')}
        </div>
        <div className={`${styles.helpText} ${styles.helpTextReturn}`}>
          {t('content.label.return')}
        </div>
      </>
    );
  }
  if (zoomLevel === 2) {
    return (
      <div className={`${styles.helpText} ${styles.helpText2}`}>
        {t('content.result.tip.2')}
      </div>
    );
  }

  return null;
}

export default SunburstHelpTexts;
