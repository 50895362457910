import CategoryHeader from '../categoryHeader/categoryHeader';
import AnalysisQuestion from '../analysisQuestion/analysisQuestion';
import React, { useState } from 'react';
import useAnalysisQuestionsWithData from '../../../../../hooks/useAnalysisQuestionsWithData';
import { useParams } from 'react-router-dom';
import IntakeInfoModal from '../../../../../components/intakeInfoModal/intakeInfoModal';
import ModalComponent from '../../../../../components/modal/modal';
import nl2br from '../../../../../utilities/string/nl2br';
import useIntakeQuestionsWithData from '../../../../../hooks/useIntakeQuestionsWithData';
import useProjectData from '../../../../../hooks/useProjectData';

function AnalysisQuestions({ onSaveAnalysisQuestionAnswer }) {
  const { projectId } = useParams();
  const { project } = useProjectData(projectId);
  const { intakeQuestions } = useIntakeQuestionsWithData(
    projectId,
    project?.type
  );
  const { analysisQuestions } = useAnalysisQuestionsWithData(projectId);
  const [infoModalContent, setInfoModalContent] = useState(null);

  function showInfoModal(info) {
    setInfoModalContent(info);
  }

  function hideInfoModal() {
    setInfoModalContent(null);
  }

  return (
    <>
      {analysisQuestions && (
        <>
          {analysisQuestions.map((analysisQuestionsCategory, index) => (
            <div
              key={analysisQuestionsCategory.id}
              id={analysisQuestionsCategory.category}
            >
              <div className="row no-gutters">
                <div className="col-12">
                  <CategoryHeader
                    index={index}
                    category={analysisQuestionsCategory}
                  />
                </div>
              </div>
              <div className="container px-0 pt-3">
                {analysisQuestionsCategory.children.map((question) => (
                  <AnalysisQuestion
                    key={question.id}
                    question={question}
                    categoryId={analysisQuestionsCategory.id}
                    colors={analysisQuestionsCategory.colors}
                    onInfoModalClick={showInfoModal}
                    onSave={onSaveAnalysisQuestionAnswer}
                  />
                ))}
              </div>
            </div>
          ))}
          <IntakeInfoModal intakeQuestions={intakeQuestions} />
        </>
      )}
      <ModalComponent active={!!infoModalContent} onClose={hideInfoModal}>
        {infoModalContent && (
          <p
            dangerouslySetInnerHTML={{ __html: nl2br(infoModalContent) }}
            className="mb-0"
          />
        )}
      </ModalComponent>
    </>
  );
}

export default AnalysisQuestions;
