import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './finishButton.module.scss';
import IKButton from '../button/IKButton';
import IKLink from '../button/IKLink';

function FinishButton({ onClick, to, disabled }) {
  const { t } = useTranslation();

  return (
    <div className={styles.finishButton}>
      {onClick && (
        <IKButton onClick={onClick} disabled={disabled}>
          <div className={styles.label}>{t('content.label.finish')}</div>
        </IKButton>
      )}
      {to && (
        <IKLink link={to} disabled={disabled}>
          <div className={styles.label}>{t('content.label.finish')}</div>
        </IKLink>
      )}
    </div>
  );
}

export default FinishButton;
