import React, { useState } from 'react';
import cn from 'classnames';
import styles from './enablePublicProjectHeader.module.scss';
import ButtonUnderlined from '../buttonUnderlined/buttonUnderlined';
import { Button } from 'react-bootstrap';
import EnablePublicProjectModal from '../enablePublicProjectModal/enablePublicProjectModal';
import { useTranslation } from 'react-i18next';

export const VIEW_QUESTIONNAIRE = 'VIEW_QUESTIONNAIRE';
export const VIEW_ANSWERS = 'VIEW_ANSWERS';

function EnablePublicProjectHeader({
  type,
  publicFormEnabled,
  activeView,
  onChangeActiveView,
  answersCount = 0,
}) {
  const { t } = useTranslation();
  const [showPublicProjectModal, setShowPublicProjectModal] = useState(false);

  function displayPublicProjectModal() {
    setShowPublicProjectModal(true);
  }
  function hidePublicProjectModal() {
    setShowPublicProjectModal(false);
  }

  const classNames = cn(styles.header, {
    [styles.analysis]: type === 'analysis',
    [styles.distribution]: type === 'distribution',
  });

  return (
    <>
      <div className={classNames}>
        <div className="container">
          <div className="row no-gutters">
            {publicFormEnabled && (
              <div className="col-8 d-flex align-items-center py-2">
                <div className="mr-2">
                  <ButtonUnderlined
                    active={activeView === VIEW_QUESTIONNAIRE}
                    onClick={() => onChangeActiveView(VIEW_QUESTIONNAIRE)}
                  >
                    {t('content.enablePublicForm.viewQuestionnaire')}
                  </ButtonUnderlined>
                </div>
                <div className="mx-2">
                  <ButtonUnderlined
                    active={activeView === VIEW_ANSWERS}
                    onClick={() => onChangeActiveView(VIEW_ANSWERS)}
                  >
                    {t('content.enablePublicForm.viewPublicResults', {
                      count: answersCount,
                    })}
                  </ButtonUnderlined>
                </div>
              </div>
            )}
            <div
              className={`${
                publicFormEnabled ? 'col-4' : 'col-12'
              } d-flex justify-content-end align-items-center py-2`}
            >
              <Button
                className={styles.publicProjectButton}
                onClick={displayPublicProjectModal}
              >
                {t('content.enablePublicForm.share')}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <EnablePublicProjectModal
        active={showPublicProjectModal}
        onClose={hidePublicProjectModal}
        type={type}
      />
    </>
  );
}

export default EnablePublicProjectHeader;
