import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import styles from './englishWebsiteAlert.module.scss';

export default function EnglishWebsiteAlert() {
  const [showAlert, setShowAlert] = useState(false);
  const alertHasBeenDismissed = useRef(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === 'en' && !alertHasBeenDismissed.current) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [i18n.language]);

  function handleDismissAlert() {
    alertHasBeenDismissed.current = true;
    setShowAlert(false);
  }

  if (!showAlert) return null;

  return (
    <Alert
      variant="warning"
      dismissible
      onClose={handleDismissAlert}
      className={styles.alert}
    >
      The English website is not fully translated yet, some texts might be
      displayed in Dutch.
    </Alert>
  );
}
