import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import useStore, { selectAuthToken, selectType } from 'src/hooks/useStore';
import pingPublicUser from '../queries/pingPublicUser';

function usePublicAuthSession(type) {
  const { t } = useTranslation();
  const authTokenKey = `authToken_${type}`;
  const { authToken, setAuthToken } = useStore(selectAuthToken);
  const { setType } = useStore(selectType);
  const [authError, setAuthError] = useState(null);
  const refreshTokenInterval = useRef(null);
  const [mutate] = useMutation(pingPublicUser, { throwOnError: true });

  const setToken = (token, reason = null) => {
    if (token) {
      sessionStorage.setItem(authTokenKey, token);
    } else {
      sessionStorage.removeItem(authTokenKey);
    }

    if (token === null && reason === 'INACTIVE_TOO_LONG') {
      setAuthError(t('error.login.loggedOutInactive'));
    }
    setAuthToken(token);
  };

  async function refreshAuthToken() {
    try {
      if (authToken) {
        const token = await mutate({ authToken });
        setToken(token);
      }
    } catch (e) {
      if (e.response?.status === 401) {
        if (e.response.data.result === 'INACTIVE_TOO_LONG') {
          setToken(null, 'INACTIVE_TOO_LONG');
        } else {
          setToken(null);
        }
      }
    }
  }

  useEffect(() => {
    refreshAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAuthToken(sessionStorage.getItem(authTokenKey) || undefined);
  }, [authTokenKey, setAuthToken]);

  useEffect(() => {
    setType(type);
  }, [setType, type]);

  useEffect(() => {
    if (authToken) {
      if (authError) setAuthError(null);
      refreshTokenInterval.current = setInterval(refreshAuthToken, 60000 * 3); // run every 3 minutes, token expires after 5
    } else if (refreshTokenInterval.current) {
      clearInterval(refreshTokenInterval.current);
    }

    return () => {
      if (refreshTokenInterval.current) {
        clearInterval(refreshTokenInterval.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  return null;
}

export default usePublicAuthSession;
