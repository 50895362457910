import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './sunburstTitleTexts.module.scss';

function SunburstTitleTexts({ zoomLevel, name }) {
  const { t } = useTranslation();
  if (zoomLevel === 1) {
    return (
      <div className={styles.sunburstTitleTexts}>
        <h3>
          {t('content.label.category')}: {name}
        </h3>
        <p>{t('content.sunburst.helpText.1')}</p>
      </div>
    );
  }
  if (zoomLevel === 2) {
    return (
      <div className={styles.sunburstTitleTexts}>
        <h3>
          {t('content.label.mechanism')}: {name}
        </h3>
        <p>{t('content.sunburst.helpText.2')}</p>
      </div>
    );
  }

  return null;
}

export default SunburstTitleTexts;
