import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import cn from 'classnames';

import styles from './project.module.scss';

import CloseBtn from '../../components/closeBtn/closeBtn';
import nl2br from '../../utilities/string/nl2br';
import ProjectProgress from '../projectProgress/projectProgress';
import deleteProject from '../../queries/deleteProject';
import { useAuth } from '../../context/auth';
import useResponseExceptionHandler from '../../hooks/useResponseExceptionHandler';
import Alert from 'react-bootstrap/Alert';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTE_PROJECT_DETAIL } from '../../constants/routes';
import useAnalysisQuestions from '../../hooks/useAnalysisQuestions';

function Project({
  id,
  title,
  metadata,
  notes,
  startdate,
  analysisQuestionsCount,
  distributionCount,
  zoomed,
  finished,
  showProgress = true,
  allowDeleteProject,
  type,
}) {
  const history = useHistory();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const { questionCount: totalAnalysisQuestionCount } =
    useAnalysisQuestions(type);
  const { authToken } = useAuth();
  const handleResponseException = useResponseExceptionHandler();
  const [mutate, { error: mutationError, reset }] = useMutation(deleteProject, {
    throwOnError: true,
    onSuccess: () => {
      queryCache.invalidateQueries('projects');
    },
  });
  const [error, setError] = useState(null);

  const handleError = useCallback(
    (e) => {
      setError(
        handleResponseException(e, {
          default: t('error.project.deleteFailed'),
        })
      );
    },
    [t, handleResponseException, setError]
  );

  useEffect(() => {
    if (error && !mutationError) {
      setError(null);
    }
  }, [error, mutationError]);

  async function handleDeleteProject(e) {
    e.stopPropagation();
    if (window.confirm(t('content.project.confirmDelete'))) {
      try {
        await mutate({ id, authToken });
      } catch (e) {
        handleError(e);
      }
    }
  }

  const projectClassNames = cn(styles.project, {
    [styles.isClickable]: id,
  });

  function navigateToProject() {
    if (id) history.push(generatePath(ROUTE_PROJECT_DETAIL, { projectId: id }));
  }

  return (
    <div className={projectClassNames} onClick={navigateToProject}>
      {allowDeleteProject && (
        <div className={styles.deleteBtnContainer}>
          <CloseBtn onClick={handleDeleteProject} />
        </div>
      )}
      <div className={styles.content}>
        {error && (
          <Alert variant="danger" dismissible onClick={() => reset()}>
            {error}
          </Alert>
        )}
        <p className="title">{t('content.label.project')}:</p>
        <h4>{title}</h4>
        {metadata && (
          <>
            <p className="title">{t('content.project.meta')}:</p>
            <h4 dangerouslySetInnerHTML={{ __html: nl2br(metadata) }} />
          </>
        )}
        {notes && (
          <>
            <p className="title">{t('content.project.notes')}:</p>
            <h4 dangerouslySetInnerHTML={{ __html: nl2br(notes) }} />
          </>
        )}
        <p className="title">{t('content.project.startdate')}:</p>
        <h4>{dayjs(startdate).format('MMMM YYYY')}</h4>
      </div>
      {showProgress && (
        <div className={styles.projectProgressContainer}>
          <span onClick={(e) => e.stopPropagation()}>
            <ProjectProgress
              projectId={id}
              newActive
              analysisActive={
                totalAnalysisQuestionCount > 0 &&
                analysisQuestionsCount >= totalAnalysisQuestionCount
              }
              distributionActive={distributionCount > 0}
              resultActive={zoomed}
              detailActive={finished}
            />
          </span>
        </div>
      )}
    </div>
  );
}

export default Project;
