import React from 'react';

import styles from './distributionFilterItem.module.scss';
import CheckboxWithLabel from '../../../../../components/checkboxWithLabel/checkboxWithLabel';

function DistributionFilterItem({
  label,
  distributionValue,
  value,
  color,
  checked,
  onClickSelect,
  onClickDeselect,
}) {
  function handleCheckboxClick() {
    if (!checked) {
      onClickSelect(value);
    } else {
      onClickDeselect(value);
    }
  }

  return (
    <div className={styles.distributionFilterItem}>
      <CheckboxWithLabel
        label={label}
        onClick={onClickSelect && handleCheckboxClick}
        checked={checked}
        checkboxColor={color}
      />
      <div className="row no-gutters">
        <div className={`col-auto ${styles.valueBlock}`}>
          {distributionValue}
        </div>
        <div className="col">
          <div
            className={styles.valueBar}
            style={{ backgroundColor: color, width: `${distributionValue}%` }}
          />
        </div>
      </div>
    </div>
  );
}

export default DistributionFilterItem;
