import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import styles from './leftColumn.module.scss';
import CCVLogo from '../../../../assets/images/logo/logo-ccv-white.svg';
import { generatePath, Link } from 'react-router-dom';
import {
  ROUTE_ADVICE_AND_TRAINING,
  ROUTE_CONTACT,
} from '../../../../constants/routes';
import helpDownload from '../../../../assets/downloads/Handleiding InterventieKompas.pdf';

export default function LeftColumn() {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className={`${styles.ccvLogoContainer} col-12 p-4`}>
        <a
          href="https://hetccv.nl/onderwerpen/naleving-toezicht-en-handhaving/"
          target="_blank"
          rel="noopener noreferrer"
          id="ccv"
        >
          <img
            src={CCVLogo}
            id="logo-ccv"
            width="196"
            height="39"
            alt="CCV logo"
          />
        </a>
      </div>
      <div className="col-12 p-4">
        <h3>{t('home_leftColumn_title')}</h3>

        <p style={{ whiteSpace: 'pre-wrap' }}>
          <Trans i18nKey="home_leftColumn_text">
            Wil je aan de slag met het InterventieKompas, en kun je wel wat hulp
            gebruiken?
            <Link to={generatePath(ROUTE_ADVICE_AND_TRAINING)}>
              Volg de CCV-training InterventieKompas
            </Link>
            waarin je leert hoe je het instrument kunt toepassen en wat het voor
            jouw organisatie kan opleveren. Of schakel de procesbegeleiders van
            het CCV in om een sessie op maat te begeleiden. Neem
            <Link to={generatePath(ROUTE_CONTACT)}>contact</Link> met het CCV op
            en vraag naar onze mogelijkheden. Wil je liever eerst wat lezen over
            de werkwijze en de mechanismen en achtergronden van het
            Interventiekompas, lees dan de
            <a
              href={helpDownload}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              handleiding
            </a>
            .
          </Trans>
        </p>
      </div>
    </div>
  );
}
