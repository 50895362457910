import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ContentPage from '../../components/contentPage/contentPage';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import ColumnContent from '../../components/columnContent/columnContent';
import BackButton from '../../components/backButton/backButton';
import Footer from '../../components/footer/footer';
import React from 'react';
import TeamMembers from '../../components/teamMembers/teamMembers';

export default function Contact() {
  const { t } = useTranslation();
  const history = useHistory();

  function handleGoBackClick() {
    history.goBack();
  }

  return (
    <ContentPage>
      <ContentImageBackground />
      <ColumnContent>
        <h1>{t('contact_title')}</h1>
        <h3>{t('contact_ccvHelps')}</h3>
        <p>
          <Trans i18nKey="contact_text_1">
            Het InterventieKompas is ontwikkeld door het expertiseteam{' '}
            <a
              href="https://hetccv.nl/onderwerpen/gedrag-en-samenleving/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Gedrag & Samenleving
            </a>{' '}
            van het Centrum voor Criminaliteitspreventie en Veiligheid (CCV).
            Het CCV zet zich in voor een veilig en leefbaar Nederland. Naast het
            ontwikkelen van tools en het organiseren van opleidingen en
            trainingen op het gebied van effectief beleid en
            gedragsbeïnvloeding, levert het expertiseteam advies op maat aan
            departementen, markttoezichthouders, inspecties,
            uitvoeringsorganisaties met handhavingstaken en decentrale
            overheden.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="contact_text_2">
            Wil je aan de slag met het InterventieKompas, maar kun je daar wel
            wat hulp bij gebruiken? Schakel de adviseurs van het CCV in om een
            sessie op maat te begeleiden. Neem contact op met Sara of Mirjam en
            vraag naar de mogelijkheden. Of volg de
            <a
              href="https://hetccv.nl/advies-en-training/ccv-academie/interventiekompas-gebruiken/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CCV-training InterventieKompas
            </a>
            waar je leert hoe je het instrument kunt toepassen en wat het voor
            jouw organisatie kan opleveren.
          </Trans>
        </p>

        <h3 className="mb-1">{t('contact_questions_title')}</h3>
        <p>{t('contact_questions_text')}</p>
        <TeamMembers />

        <div className="mt-6 float-right">
          <BackButton onClick={handleGoBackClick} />
        </div>
      </ColumnContent>
      <Footer />
    </ContentPage>
  );
}
