import IntakeHelpQuestionsModalButton from '../intakeHelpQuestionsModal/intakeHelpQuestionsModal';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

function IntakeQuestionField({
  name,
  questionIdName,
  register,
  intakeQuestion,
  error,
  onBlur,
}) {
  const { t } = useTranslation();
  return (
    <div className="form-group">
      <div className="d-flex align-items-center mb-2">
        <label className="flex-grow-1 mb-0">{intakeQuestion.question}</label>
        {intakeQuestion.info && (
          <div className="ml-1">
            <IntakeHelpQuestionsModalButton info={intakeQuestion.info} />
          </div>
        )}
      </div>
      <textarea
        className={cn('form-control', { 'is-invalid': error })}
        name={name}
        rows={intakeQuestion.rows}
        ref={register({
          required: intakeQuestion.mandatory ? t('validation.required') : false,
        })}
        defaultValue={intakeQuestion.answer}
        onBlur={onBlur}
        placeholder={intakeQuestion.placeholder}
      />
      {error && <div className="invalid-feedback">{error.message}</div>}
      <input
        type="hidden"
        name={questionIdName}
        ref={register()}
        defaultValue={intakeQuestion.id}
      />
    </div>
  );
}

export default IntakeQuestionField;
