import React from 'react';
import { useTranslation } from 'react-i18next';
import Login from './components/login/login';
import { useLocation } from 'react-router-dom';
import IntroSteps from './components/introSteps/introSteps';
import { useAuth } from '../../context/auth';
import logo from '../../assets/images/logo/logo.svg';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import ColumnContent from '../../components/columnContent/columnContent';
import ContentPage from '../../components/contentPage/contentPage';
import Footer from '../../components/footer/footer';
import RightColumn from './components/rightColumn/rightColumn';
import LeftColumn from './components/leftColumn/leftColumn';

function Home() {
  const { authError } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const isNewlyRegistered = location.state?.isNewlyRegistered;

  return (
    <ContentPage>
      <ContentImageBackground>
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-4 offset-xl-1">
              <div className="card border-0">
                <div className="card-body">
                  <h2>{t('content.login.title')}</h2>
                  {isNewlyRegistered && (
                    <div className="alert alert-success">
                      <h4 className="alert-heading">
                        {t('content.register.success.title')}
                      </h4>
                      <p
                        className="mb-0"
                        dangerouslySetInnerHTML={{
                          __html: t('content.register.success.body'),
                        }}
                      />
                    </div>
                  )}
                  {authError && (
                    <div className="alert alert-danger">
                      <p
                        className="mb-0"
                        dangerouslySetInnerHTML={{ __html: authError }}
                      />
                    </div>
                  )}
                  <Login />
                </div>
              </div>
            </div>
            <div className="col-7 col-xl-5 offset-1">
              <p className="lead text-white">{t('home_teaser_description')}</p>
              <div className="d-flex align-items-center">
                <img
                  src={logo}
                  width={100}
                  height={100}
                  className="mr-3"
                  alt="InterventieKompas logo"
                />
                <h2 className="text-white font-weight-normal">
                  {t('home_teaser_title')}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </ContentImageBackground>
      <ColumnContent leftColumn={<LeftColumn />} rightColumn={<RightColumn />}>
        <h1>{t('home_main_title')}</h1>
        <p className="lead font-weight-normal">{t('home_main_text')}</p>
        <h3>{t('home_howItWorks_title')}</h3>
        <p>{t('home_howItWorks_text')}</p>

        <h3>{t('home_introSteps_title')}</h3>
        <IntroSteps />
      </ColumnContent>
      <Footer />
    </ContentPage>
  );
}

export default Home;
