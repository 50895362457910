import cn from 'classnames';

import styles from './analysisQuestionAnswers.module.scss';

function PublicAnalysisQuestionAnswers({ answers, selectedAnswer, colors }) {
  const classNames = cn('row no-gutters h-100', styles.public, {
    [styles.selectionMade]: selectedAnswer !== null,
    [styles.noSelectionMade]: selectedAnswer === null,
  });
  return (
    <div className={classNames}>
      <div className="col-1 h-100">
        <div
          className={cn(styles.answer, styles.unknownAnswer, {
            [styles.selected]: -1 === selectedAnswer,
          })}
          style={{ backgroundColor: colors[0] }}
        />
      </div>
      <div className="col-1 h-100">
        <div
          className={cn(styles.answer, {
            [styles.selected]: 0 === selectedAnswer,
          })}
          style={{ backgroundColor: colors[0] }}
        />
      </div>
      {answers.map((answer, i) => {
        const answerScore = i + 1;
        return (
          <div className="col-2 h-100" key={i}>
            <div
              className={cn(styles.answer, {
                [styles.selected]: answerScore === selectedAnswer,
              })}
              style={{ backgroundColor: colors[answerScore] }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default PublicAnalysisQuestionAnswers;
