import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import useResponseExceptionHandler from '../../hooks/useResponseExceptionHandler';
import Errors from '../errors/errors';
import FixedLoader from '../loader/fixedLoader';
import useStore, { selectAuthToken, selectType } from 'src/hooks/useStore';

function PublicLoginForm({ hasPassword }) {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const { setAuthToken } = useStore(selectAuthToken);
  const { type } = useStore(selectType);
  const { publicId } = useParams();
  const handleResponseException = useResponseExceptionHandler();

  function handleFailedLogin(exception) {
    setIsLoading(false);
    const error = handleResponseException(exception, {
      default: t('error.publicLoginForm.invalidPasswordOrLink'),
    });
    setGlobalError(error);
  }

  function handleLoginSubmit(data) {
    setIsLoading(true);
    setGlobalError(null);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/public/login`,
        { type, publicId, ...data },
        { validateStatus: (status) => status === 200 }
      )
      .then((result) => {
        setAuthToken(result.data.result.auth_token);
      })
      .catch((e) => handleFailedLogin(e));
  }

  return (
    <form onSubmit={handleSubmit(handleLoginSubmit)}>
      {globalError && <Errors errors={[globalError]} />}
      {isLoading && <FixedLoader />}
      {hasPassword && (
        <div className="form-group">
          <label>{t('content.login.password')}</label>
          <input
            type="password"
            name="password"
            ref={register({ required: t('validation.required') })}
            className={cn('form-control', { 'is-invalid': errors.password })}
          />
          {errors.password && (
            <div className="invalid-feedback">{errors.password.message}</div>
          )}
        </div>
      )}
      <div className="form-group">
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
          {t('content.publicLogin.start')}
        </button>
      </div>
    </form>
  );
}

export default PublicLoginForm;
