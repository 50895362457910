import MirjamImage from './assets/CCV_Mirjam_Prinsen.jpg';
import SaraImage from './assets/CCV_Sara_Wester.jpg';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TeamMembers() {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-6">
        <img
          src={MirjamImage}
          alt="Mirjam Prinsen"
          className="img-fluid mb-3"
        />
        <h3 className="mb-1">Mirjam Prinsen</h3>
        <p>{t('teamMembers_mirjam_role')}</p>
        <p>
          <a href="tel:06 52 638 751">06 52 638 751</a>
          <br />
          <a href="mailto:mirjam.prinsen@hetccv.nl">mirjam.prinsen@hetccv.nl</a>
        </p>
      </div>
      <div className="col-6">
        <img src={SaraImage} alt="Sara Wester" className="img-fluid mb-3" />
        <h3 className="mb-1">Sara Wester</h3>
        <p>{t('teamMembers_sara_role')}</p>
        <p>
          <a href="tel:06 21 952 943">06 21 952 943</a>
          <br />
          <a href="mailto:sara.wester@hetccv.nl">sara.wester@hetccv.nl</a>
        </p>
      </div>
    </div>
  );
}
