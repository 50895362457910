import ContentPage from '../../components/contentPage/contentPage';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import ColumnContent from '../../components/columnContent/columnContent';
import BackButton from '../../components/backButton/backButton';
import Footer from '../../components/footer/footer';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function Conditions() {
  const { t } = useTranslation();
  const history = useHistory();

  function handleGoBackClick() {
    history.goBack();
  }

  return (
    <ContentPage>
      <ContentImageBackground />
      <ColumnContent>
        <h1>{t('conditions_title')}</h1>
        <p className="lead font-weight-normal mb-5">{t('conditions_lead')}</p>
        <p>
          Centrum voor Criminaliteitspreventie en Veiligheid
          <br />
          Churchilllaan 11
          <br />
          3527 GV Utrecht
        </p>
        <Trans i18nKey="conditions_text">
          <h3>Disclaimer</h3>
          <p>
            Het CCV streeft naar een zorgvuldige controle van de informatie die
            we via het InterventieKompas aanbieden, zodat deze actueel,
            nauwkeurig en betrouwbaar is. Desondanks kan het CCV niet garanderen
            dat de informatie in het InterventieKompas te allen tijde actueel en
            correct is. Het InterventieKompas pretendeert niet volledig te zijn.
          </p>
          <h3>Eigendomsvoorbehoud</h3>
          <p>
            Het CCV behoudt zich het recht voor alle informatie in het
            InterventieKompas, op ieder moment zonder verdere aankondiging, te
            wijzigen of te verwijderen. Op alle content die via het
            InterventieKompas te raadplegen is, waaronder begrepen maar niet
            beperkt tot de teksten, look and feel, geluids- en beeldmateriaal,
            afbeeldingen, vormgeving, merken, logo’s alsmede enige andere
            informatie, rusten intellectuele eigendomsrechten die aan het CCV
            dan wel zijn licentiegevers toebehoren.
          </p>
          <p>
            Deze content mag uitsluitend gebruikt worden om van het
            InterventieKompas en de daarop aangeboden diensten gebruik te maken.
            Zonder uitdrukkelijke voorafgaande schriftelijke toestemming van het
            CCV mag de content niet voor andere doeleinden worden aangewend,
            waaronder begrepen, maar niet beperkt tot het verveelvoudigen en/of
            openbaar maken van de content dan wel het verstrekken daarvan aan
            derden, al dan niet voor commerciële doeleinden, tenzij zulks
            dwingend rechtelijk is toegestaan.
          </p>
          <h3>Aansprakelijkheid</h3>
          <p>
            Het CCV aanvaardt geen enkele aansprakelijkheid voor schade ontstaan
            door het gebruik van het InterventieKompas, danwel uit verleende
            diensten, aangeboden of verwezen content in het InterventieKompas.
            Aan de verstrekte informatie kunnen geen rechten worden ontleend.
            Bij vragen, opmerkingen en/of klachten over het InterventieKompas
            kun je contact opnemen met het CCV via{' '}
            <a href="mailto:info@hetccv.nl">mail</a>.
          </p>
          <h3>Contactgegevens</h3>
          <p>
            Als je vragen hebt over deze algemene voorwaarden, dan kun je die
            stellen via <a href="mailto:info@hetccv.nl">mail</a>.
          </p>
          <a href="mailto:info@hetccv.nl">mail</a>{' '}
          {/* FOR TRANSLATION PURPOSES */}
        </Trans>
        <div className="mt-5">
          <p>{t('conditions_lastUpdated')}</p>
        </div>
        <div className="mt-6 float-right">
          <BackButton onClick={handleGoBackClick} />
        </div>
      </ColumnContent>
      <Footer />
    </ContentPage>
  );
}

export default Conditions;
