import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import useExamples from '../../hooks/useExamples';
import { Button } from 'react-bootstrap';

import styles from './exampleStack.module.scss';
import NextExampleButton from '../nextExampleBtn/nextExampleButton';
import CloseBtn from '../closeBtn/closeBtn';
import ExampleModal from '../exampleModal/exampleModal';

function ExampleStackCard({ example, onClick, color }) {
  const { t } = useTranslation();

  function handleClick() {
    onClick(example.id);
  }

  return (
    <div
      key={example.id}
      className={styles.example}
      style={{
        backgroundColor: color,
      }}
    >
      <h3 className={styles.title}>{example.name}</h3>
      <div
        className={styles.imgContainer}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/examples/images/${example.image})`,
        }}
        onClick={handleClick}
      />
      <div className={styles.footer}>
        <Button variant="primary" onClick={handleClick}>
          {t('content.cardstack.open')}
        </Button>
      </div>
    </div>
  );
}

function ExampleStack({
  exampleIds,
  categoryColors,
  onClose,
  savedExampleIds,
}) {
  const { examples } = useExamples();
  const [exampleStack, setExampleStack] = useState([]);
  const [activeExampleNumber, setActiveExampleNumber] = useState(1);
  const [exampleModal, setExampleModal] = useState(null);

  useEffect(() => {
    if (exampleIds && exampleIds.length) {
      setExampleStack(
        examples.filter((example) => example && exampleIds.includes(example.id))
      );
    } else {
      setExampleStack([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exampleIds]);

  function handleClick(exampleId) {
    setExampleModal(exampleStack.find((example) => example.id === exampleId));
  }

  function handleGoToNextExample() {
    if (exampleStack && exampleStack.length > 1) {
      const examples = [...exampleStack];
      const example = examples.splice(0, 1);
      setExampleStack([...examples, ...example]);
      let newActiveExampleNumber = activeExampleNumber + 1;
      if (newActiveExampleNumber > exampleStack.length) {
        newActiveExampleNumber = 1;
      }
      setActiveExampleNumber(newActiveExampleNumber);
    }
  }

  return (
    <div className={styles.exampleStack}>
      <div className={cn(styles.examples, styles.hasCloseBtn)}>
        {exampleStack.map((example, i) => (
          <ExampleStackCard
            key={example.id}
            example={example}
            onClick={handleClick}
            color={categoryColors[i * 2 > 4 ? 4 : i * 2]}
          />
        ))}
      </div>
      {onClose && (
        <div className={styles.closeBtnContainer}>
          <CloseBtn onClick={onClose} />
        </div>
      )}
      {exampleStack.length > 1 && (
        <div className={styles.exampleCountIndicator}>
          <p>
            {activeExampleNumber}/{exampleStack.length}
          </p>
          <div className={styles.nextBtnContainer}>
            <NextExampleButton onClick={handleGoToNextExample} />
          </div>
        </div>
      )}

      <ExampleModal
        savedExampleIds={savedExampleIds}
        active={!!exampleModal}
        onClose={() => setExampleModal(null)}
        example={exampleModal}
      />
    </div>
  );
}

export default ExampleStack;
