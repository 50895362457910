import { useEffect, useState } from 'react';

const useStateWithLocalStorage = (localStorageKey, defaultValue = '') => {
  const [value, setValue] = useState(
    localStorage.getItem(localStorageKey) !== null
      ? localStorage.getItem(localStorageKey) === 'true'
      : defaultValue
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return [value, setValue];
};

export default useStateWithLocalStorage;
