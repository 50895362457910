import axios from 'axios';

const getPublicAnalysis = (key, { id, authToken }) => {
  if (!id || !authToken) {
    return Promise.resolve([]);
  }
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/project/${id}/public_distributions`,
      {
        validateStatus: (status) => status === 200,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((result) => result.data.result);
};

export default getPublicAnalysis;
