import axios from 'axios';

const getProjects = () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/project`, {
      validateStatus: (status) => status === 200,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
      },
    })
    .then((result) => result.data.result);
};

export default getProjects;
