import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import useResponseExceptionHandler from './useResponseExceptionHandler';
import { useAuth } from '../context/auth';
import getPublicDistribution from '../queries/getPublicDistribution';
import getAllDistributionValues from '../utilities/distribution/getAllDistributionValues';
import { useTranslation } from 'react-i18next';

function usePublicDistributionAnswers(projectId) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const handleResponseException = useResponseExceptionHandler();
  const { authToken } = useAuth();
  const {
    isLoading,
    isError,
    data,
    error: queryError,
  } = useQuery(
    ['projectPublicDistribution', { id: projectId, authToken }],
    getPublicDistribution,
    {
      refetchInterval: 60000,
    }
  );

  const handleError = useCallback(
    (e) => {
      const error = handleResponseException(e);
      setError(error);
    },
    [setError, handleResponseException]
  );

  useEffect(() => {
    if (isError && queryError) {
      handleError(queryError);
    } else if (error) {
      setError(null);
    }
  }, [error, isError, queryError, handleError]);

  return {
    isLoading,
    publicDistributionAnswers: data?.map((publicDistribution) => ({
      id: publicDistribution.id,
      ...getAllDistributionValues(publicDistribution),
      name: publicDistribution?.name || t('content.publicForm.anonymous'),
    })),
    error,
  };
}

export default usePublicDistributionAnswers;
