import React from 'react';

import styles from './distributionResultRow.module.scss';

function DistributionResultRow({ color, label, value }) {
  return (
    <div className={`row no-gutters ${styles.distributionResultRow}`}>
      <div className={`col ${styles.valueLabelContainer}`}>
        <h2 className={styles.valueLabel} style={{ color }}>
          {label}
        </h2>
      </div>
      <div className={`col-auto ${styles.valueBlock}`}>{value}</div>
      <div className="col">
        <div
          className={styles.valueBar}
          style={{ backgroundColor: color, width: `${value}%` }}
        />
      </div>
    </div>
  );
}

export default DistributionResultRow;
