import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import styles from './rightColumn.module.scss';

export default function RightColumn() {
  const { t } = useTranslation();

  return (
    <div className={`row ${styles.rightColumn}`}>
      <div className={`col-12 py-5 px-4`}>
        <h3>{t('home_rightColumn_title')}</h3>
        <p style={{ whiteSpace: 'pre-wrap' }}>
          <Trans i18nKey="home_rightColumn_text">
            Wil je meer verdieping over hoe je gedragskennis toepast in je eigen
            praktijk? Het CCV heeft brede en actuele kennis op het gebied van
            gedragsbeïnvloeding en nalevingsbevordering en de invloed van
            organisatiecultuur. Dat brengen we graag op je over via onze
            trainingen. Bekijk ons
            <a
              href="https://hetccv.nl/onderwerpen/gedrag-en-samenleving/trainingen/"
              target="_blank"
              rel="noopener noreferrer"
            >
              trainingsoverzicht
            </a>
            op de CCV-website.
          </Trans>
        </p>
      </div>
    </div>
  );
}
