import {
  COLOR_TARGET_GROUP_CALCULATING,
  COLOR_TARGET_GROUP_DETERRED,
  COLOR_TARGET_GROUP_KNOWN,
  COLOR_TARGET_GROUP_OPPORTUNITY,
  COLOR_TARGET_GROUP_PRINCIPLED,
  COLOR_TARGET_GROUP_SPONTANEOUS,
  COLOR_TARGET_GROUP_UNDEFINED,
  COLOR_TARGET_GROUP_UNINTENTIONAL_COMPLIER,
  COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER,
  COLOR_TARGET_GROUP_UNKNOWN,
} from '../../../../../constants/colors';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import usePublicDistributionAnswers from '../../../../../hooks/usePublicDistributionAnswers';
import { useTranslation } from 'react-i18next';
import styles from './distributionAnswers.module.scss';
import DistributionSlider from '../distributionSlider/distributionSlider';
import useDistributionChangeHandler from '../../../../../hooks/useDistributionChangeHandler';

function DistributionAnswerRow({
  value,
  max,
  leftColor,
  leftLabel,
  rightColor,
  rightLabel,
  name,
}) {
  const leftWidth = useMemo(
    () => (max > 0 ? (100 / max) * value : 0),
    [value, max]
  );
  return (
    <div className="row my-2 d-flex align-items-center">
      <div className="col-10">
        <div className={styles.distributionAnswerRow}>
          <div className={styles.sliderBackgroundContainer}>
            <div
              className={styles.left}
              style={{ width: `${leftWidth}%`, backgroundColor: leftColor }}
            />
          </div>
          <div
            className={styles.rightBackground}
            style={{ backgroundColor: rightColor }}
          />
          <div
            className={styles.leftLabelContainer}
            style={{ width: `${leftWidth}%` }}
          >
            <p className={styles.label}>
              {value}% {leftLabel}
            </p>
          </div>
          <div
            className={styles.rightLabelContainer}
            style={{ width: `${100 - leftWidth}%` }}
          >
            <p className={styles.label}>
              {max - value}% {rightLabel}
            </p>
          </div>
        </div>
      </div>
      <div className="col-2">
        <p className={styles.name}>{name}</p>
      </div>
    </div>
  );
}

function DistributionAnswerGroup({
  title,
  currentUserValue,
  currentUserMax,
  leftLabel,
  leftColor,
  rightLabel,
  rightColor,
  publicDistributions,
  disabled,
  onChange,
}) {
  return (
    <div className="container my-5">
      <h3 className={styles.groupLabel}>{title}</h3>

      <div className="row my-2 d-flex align-items-center">
        <div className="col-10">
          <DistributionSlider
            disabled={disabled}
            value={currentUserValue}
            max={currentUserMax}
            leftLabel={leftLabel}
            leftColor={leftColor}
            rightLabel={rightLabel}
            rightColor={rightColor}
            onChange={onChange}
            size="small"
          />
        </div>
      </div>
      {publicDistributions &&
        publicDistributions.map((publicDistribution) => (
          <DistributionAnswerRow
            key={publicDistribution.id}
            value={publicDistribution.value}
            max={publicDistribution.max}
            leftLabel={leftLabel}
            leftColor={leftColor}
            rightLabel={rightLabel}
            rightColor={rightColor}
            name={publicDistribution.name}
          />
        ))}
    </div>
  );
}

function DistributionAnswers({ currentUserDistribution, disabled, onChange }) {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { publicDistributionAnswers } = usePublicDistributionAnswers(projectId);
  const {
    unknown,
    setUnknown,
    unintentionalComplier,
    setUnintentionalComplier,
    spontaneous,
    setSpontaneous,
    deterred,
    setDeterred,
    principled,
    setPrincipled,
    opportunity,
    setOpportunity,
    known,
    nonSpontaneous,
    nonDeterred,
    nonPrincipled,
  } = useDistributionChangeHandler(currentUserDistribution, onChange);

  return (
    <>
      <DistributionAnswerGroup
        disabled={disabled}
        onChange={setUnknown}
        title={t('content.distribution.targetGroup')}
        currentUserValue={unknown}
        currentUserMax={100}
        leftLabel={t('content.distribution.unknown')}
        leftColor={COLOR_TARGET_GROUP_UNKNOWN}
        rightLabel={t('content.distribution.known')}
        rightColor={COLOR_TARGET_GROUP_KNOWN}
        publicDistributions={publicDistributionAnswers?.map(
          (publicDistribution) => ({
            id: publicDistribution.id,
            value:
              publicDistribution.unintentionalComplier +
              publicDistribution.unintentionalOffender,
            max: 100,
            name: publicDistribution.name,
          })
        )}
      />

      <DistributionAnswerGroup
        disabled={disabled}
        onChange={setUnintentionalComplier}
        title={t('content.distribution.unknown')}
        currentUserValue={unintentionalComplier}
        currentUserMax={unknown}
        leftLabel={t('content.distribution.unintentionalComplier')}
        leftColor={COLOR_TARGET_GROUP_UNINTENTIONAL_COMPLIER}
        rightLabel={t('content.distribution.unintentionalOffender')}
        rightColor={COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER}
        publicDistributions={publicDistributionAnswers?.map(
          (publicDistribution) => ({
            id: publicDistribution.id,
            value: publicDistribution.unintentionalComplier,
            max:
              publicDistribution.unintentionalComplier +
              publicDistribution.unintentionalOffender,
            name: publicDistribution.name,
          })
        )}
      />

      <DistributionAnswerGroup
        disabled={disabled}
        onChange={setSpontaneous}
        title={t('content.distribution.known')}
        currentUserValue={spontaneous}
        currentUserMax={known}
        leftLabel={t('content.distribution.spontaneous')}
        leftColor={COLOR_TARGET_GROUP_SPONTANEOUS}
        rightLabel={t('content.distribution.nonSpontaneous')}
        rightColor={COLOR_TARGET_GROUP_KNOWN}
        publicDistributions={publicDistributionAnswers?.map(
          (publicDistribution) => ({
            id: publicDistribution.id,
            value: publicDistribution.spontaneous,
            max: 100 - publicDistribution.unknown,
            name: publicDistribution.name,
          })
        )}
      />

      <DistributionAnswerGroup
        disabled={disabled}
        onChange={setDeterred}
        title={t('content.distribution.nonSpontaneous')}
        currentUserValue={deterred}
        currentUserMax={nonSpontaneous}
        leftLabel={t('content.distribution.deterred')}
        leftColor={COLOR_TARGET_GROUP_DETERRED}
        rightLabel={t('content.distribution.intentional')}
        rightColor={COLOR_TARGET_GROUP_KNOWN}
        publicDistributions={publicDistributionAnswers?.map(
          (publicDistribution) => ({
            id: publicDistribution.id,
            value: publicDistribution.deterred,
            max: publicDistribution.nonSpontaneous,
            name: publicDistribution.name,
          })
        )}
      />

      <DistributionAnswerGroup
        disabled={disabled}
        onChange={setPrincipled}
        title={t('content.distribution.intentional')}
        currentUserValue={principled}
        currentUserMax={nonDeterred}
        leftLabel={t('content.distribution.principled')}
        leftColor={COLOR_TARGET_GROUP_PRINCIPLED}
        rightLabel={t('content.distribution.rest')}
        rightColor={COLOR_TARGET_GROUP_UNDEFINED}
        publicDistributions={publicDistributionAnswers?.map(
          (publicDistribution) => ({
            id: publicDistribution.id,
            value: publicDistribution.principled,
            max: publicDistribution.nonDeterred,
            name: publicDistribution.name,
          })
        )}
      />

      <DistributionAnswerGroup
        disabled={disabled}
        onChange={setOpportunity}
        title={`${t('content.distribution.intentional')} (${t(
          'content.distribution.rest'
        )})`}
        currentUserValue={opportunity}
        currentUserMax={nonPrincipled}
        leftLabel={t('content.distribution.opportunity')}
        leftColor={COLOR_TARGET_GROUP_OPPORTUNITY}
        rightLabel={t('content.distribution.calculating')}
        rightColor={COLOR_TARGET_GROUP_CALCULATING}
        publicDistributions={publicDistributionAnswers?.map(
          (publicDistribution) => ({
            id: publicDistribution.id,
            value: publicDistribution.opportunity,
            max: publicDistribution.nonPrincipled,
            name: publicDistribution.name,
          })
        )}
      />
    </>
  );
}

export default DistributionAnswers;
