import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseBtn from '../closeBtn/closeBtn';
import SaveBtn from '../saveBtn/saveBtn';
import { useTranslation } from 'react-i18next';

import styles from './exampleModal.module.scss';
import { useMutation, useQueryCache } from 'react-query';
import toggleSaveExample from '../../queries/saveExample';
import { useAuth } from '../../context/auth';
import SaveLoader from '../loader/saveLoader';
import { useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

function ExampleModal({ active, example, onClose, savedExampleIds }) {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [alert, setAlert] = useState(null);
  const queryCache = useQueryCache();
  const { authToken } = useAuth();
  const PDFURL = example
    ? `${process.env.PUBLIC_URL}/assets/examples/pdfs/${example.PDF}`
    : '';
  const [mutate, { error: mutationError }] = useMutation(toggleSaveExample, {
    onSuccess: () => {
      queryCache.invalidateQueries('project');
    },
  });

  async function handleSaveExample() {
    setIsSaving(true);
    const status = await mutate({
      id: projectId,
      authToken,
      data: {
        example_id: example.id,
        intervention_id: example.intervention_id,
      },
    });
    if (status === 200) {
      setAlert({
        variant: 'success',
        message: t('content.example.deleted'),
      });
    } else if (status === 201) {
      setAlert({
        variant: 'success',
        message: t('content.example.saved'),
      });
    }
    setIsSaving(false);
  }

  useEffect(() => {
    if (mutationError) {
      setAlert({
        variant: 'danger',
        message: t('error.generic.failed'),
      });
    }
  }, [mutationError, t]);

  return (
    <>
      <Modal show={active} onHide={onClose} size="lg" centered>
        <Modal.Header closeButton={false} className={styles.header}>
          {alert && (
            <Alert
              variant={alert.variant}
              dismissible
              onClick={() => setAlert(null)}
              className={styles.alert}
            >
              {alert.message}
            </Alert>
          )}
          <div className={styles.buttonsContainer}>
            <SaveBtn
              isSaved={example && savedExampleIds.includes(example.id)}
              onClick={handleSaveExample}
              disabled={isSaving}
            />
            <CloseBtn onClick={onClose} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <object
            type="application/pdf"
            data={PDFURL}
            id="example-pdf"
            width="100%"
            height="1132"
          >
            <p>{t('content.error.noPDFViewer')}</p>
            <p>
              <a
                href={PDFURL}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                {t('content.label.clickHereToDownloadPDF')}
              </a>
            </p>
          </object>
        </Modal.Body>
      </Modal>
      {isSaving && <SaveLoader />}
    </>
  );
}

export default ExampleModal;
