import axios from 'axios';

export default function updateProject({ id, authToken, data }) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/project/${id}`, data, {
      validateStatus: (status) => status === 200,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((result) => result.data.result);
}
