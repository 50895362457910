import { useState } from 'react';
import { ChildQuestion, Question } from 'src/hooks/useAnalysisQuestions';
import InfoBtn from '../../../../../components/infoBtn/infoBtn';
import AnalysisQuestionAnswers from '../analysisQuestionAnswers/analysisQuestionAnswers';

import styles from './analysisQuestion.module.scss';

interface AnalysisQuestionProps {
  question: ChildQuestion;
  colors: Question['colors'];
  categoryId: Question['id'];
  onInfoModalClick: (info?: string) => void;
  onSave: (
    categoryId: Question['id'],
    questionId: ChildQuestion['id'],
    answer?: unknown,
    subQuestionAnswer?: unknown
  ) => void;
}

function AnalysisQuestion({
  question,
  colors,
  onInfoModalClick,
  onSave,
  categoryId,
}: AnalysisQuestionProps) {
  const [answer, setAnswer] = useState<string>();

  return (
    <>
      <div className="row mt-3 mb-5 no-gutters justify-content-center">
        <div className="col-8 d-flex align-items-center mb-2">
          <h3
            className={`${styles.question} mb-0`}
            dangerouslySetInnerHTML={{ __html: question.question }}
          />
          {question.info && (
            <div>
              <InfoBtn onClick={() => onInfoModalClick(question.info)} />
            </div>
          )}
        </div>
        <div className={`col-12 ${styles.answersContainer}`}>
          <AnalysisQuestionAnswers
            answers={question.answers}
            selectedAnswer={question.score}
            colors={colors}
            onSelectAnswer={(answer: string) =>
              onSave(
                categoryId,
                question.id,
                answer,
                // @ts-expect-error -- TODO does not exist
                question.subQuestionAnswer
              )
            }
          />
        </div>
        {question.subquestion && (
          <div className="col-8 mt-3">
            <label>{question.subquestion}</label>
            <textarea
              className="form-control"
              style={{ backgroundColor: colors[0] }}
              rows={3}
              // @ts-expect-error -- TODO does not exist
              defaultValue={question.subQuestionAnswer}
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              // TODO -- remove `onBlur`, read values from input on submit.
              onBlur={(e) =>
                onSave(categoryId, question.id, question.score, e.target.value)
              }
            />
          </div>
        )}
      </div>
    </>
  );
}

export default AnalysisQuestion;
