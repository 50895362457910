import React from 'react';
import cn from 'classnames';
import styles from './buttonUnderlined.module.scss';

function ButtonUnderlined({ children, onClick, active }) {
  const classNames = cn(styles.buttonUnderlined, {
    [styles.active]: active,
  });

  return (
    <button onClick={onClick} className={classNames}>
      {children}
    </button>
  );
}

export default ButtonUnderlined;
