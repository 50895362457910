import axios from 'axios';

export default function pingUser({ authToken }) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/ping`,
      {},
      {
        validateStatus: (status) => status === 200,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((result) => result.data.result.auth_token);
}
