import {
  COLOR_TARGET_GROUP_CALCULATING,
  COLOR_TARGET_GROUP_DETERRED,
  COLOR_TARGET_GROUP_OPPORTUNITY,
  COLOR_TARGET_GROUP_PRINCIPLED,
  COLOR_TARGET_GROUP_SPONTANEOUS,
  COLOR_TARGET_GROUP_UNINTENTIONAL_COMPLIER,
  COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER,
} from './colors';

export const TARGET_GROUP_UNINTENTIONAL_COMPLIER = 'unintentionalComplier';
export const TARGET_GROUP_SPONTANEOUS = 'spontaneous';
export const TARGET_GROUP_DETERRED = 'deterred';
export const TARGET_GROUPS_COMPLYING = [
  TARGET_GROUP_UNINTENTIONAL_COMPLIER,
  TARGET_GROUP_SPONTANEOUS,
  TARGET_GROUP_DETERRED,
];

export const TARGET_GROUP_UNINTENTIONAL_OFFENDER = 'unintentionalOffender';
export const TARGET_GROUP_OPPORTUNITY = 'opportunity';
export const TARGET_GROUP_PRINCIPLED = 'principled';
export const TARGET_GROUP_CALCULATING = 'calculating';
export const TARGET_GROUPS_OFFENDER = [
  TARGET_GROUP_UNINTENTIONAL_OFFENDER,
  TARGET_GROUP_OPPORTUNITY,
  TARGET_GROUP_PRINCIPLED,
  TARGET_GROUP_CALCULATING,
];

export const TARGET_GROUPS_WITH_COLORS = [
  {
    targetGroup: TARGET_GROUP_UNINTENTIONAL_COMPLIER,
    color: COLOR_TARGET_GROUP_UNINTENTIONAL_COMPLIER,
  },
  {
    targetGroup: TARGET_GROUP_SPONTANEOUS,
    color: COLOR_TARGET_GROUP_SPONTANEOUS,
  },
  { targetGroup: TARGET_GROUP_DETERRED, color: COLOR_TARGET_GROUP_DETERRED },
  {
    targetGroup: TARGET_GROUP_UNINTENTIONAL_OFFENDER,
    color: COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER,
  },
  {
    targetGroup: TARGET_GROUP_OPPORTUNITY,
    color: COLOR_TARGET_GROUP_OPPORTUNITY,
  },
  {
    targetGroup: TARGET_GROUP_PRINCIPLED,
    color: COLOR_TARGET_GROUP_PRINCIPLED,
  },
  {
    targetGroup: TARGET_GROUP_CALCULATING,
    color: COLOR_TARGET_GROUP_CALCULATING,
  },
];
