import i18n from 'i18next';
import axios from 'axios';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'dayjs/locale/nl';
import contentEN from './content/static/EN/content.js';
import sunburstDescriptionsEN from './content/download/EN/sunburst_descriptions.json';
import labelsEN from './content/static/EN/labels.json';

import contentNL from './content/static/NL/content.js';
import sunburstDescriptionsNL from './content/download/NL/sunburst_descriptions.json';
import labelsNL from './content/static/NL/labels.json';
import dayjs from 'dayjs';
import cookieLanguageDetector from './utilities/cookieLanguageDetector';

// the translations
const resources = {
  en: {
    translation: {
      ...contentEN,
      ...sunburstDescriptionsEN,
      ...labelsEN,
    },
  },
  nl: {
    translation: {
      ...contentNL,
      ...sunburstDescriptionsNL,
      ...labelsNL,
    },
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(cookieLanguageDetector);
i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      // order and from where user language should be detected
      order: [
        'querystring',
        'customCookie',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['customCookie'],
    },

    resources,

    supportedLngs: ['nl', 'en'],

    load: 'all',

    fallbackLng: 'nl',

    keySeparator: '_', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: [
        'br',
        'strong',
        'i',
        'p',
        'h3',
        'h4',
        'ul',
        'li',
      ],
    },
  });

dayjs.locale('nl');
axios.defaults.headers.common['Accept-Language'] = 'nl';
i18n.on('languageChanged', function (lng) {
  dayjs.locale(lng);
  axios.defaults.headers.common['Accept-Language'] = lng;
});

export default i18n;
