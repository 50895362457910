import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import PublicAuthenticator from '../../../components/publicAuthenticator/publicAuthenticator';
import PublicAnalysisQuestions from './components/publicAnalysisQuestions/publicAnalysisQuestions';
import useAnalysisQuestions from '../../../hooks/useAnalysisQuestions';
import savePublicAnalysis from '../../../queries/savePublicAnalysis';
import PublicForm from '../../../components/publicForm/publicForm';
import usePublicProjectData from '../../../hooks/usePublicProjectData';
import useStore, { selectAuthToken } from 'src/hooks/useStore';

function PublicAnalysisForm() {
  const { t } = useTranslation();
  const { publicProject } = usePublicProjectData();
  const { questionCount } = useAnalysisQuestions(publicProject?.type);
  const [error, setError] = useState(null);
  const [analysisAnswers, setAnalysisAnswers] = useState([]);
  const { authToken } = useStore(selectAuthToken);
  const [mutate, { isLoading }] = useMutation(savePublicAnalysis, {
    throwOnError: true,
  });

  function handleAnalysisAnswersChange(analysisAnswer) {
    if (error) setError(null);
    const answerIndex = analysisAnswers.findIndex(
      (answer) => answer.question_id === analysisAnswer.question_id
    );

    const newAnalysisAnswers = [...analysisAnswers];
    if (answerIndex >= 0) {
      newAnalysisAnswers.splice(answerIndex, 1, {
        ...newAnalysisAnswers[answerIndex],
        ...analysisAnswer,
      });
    } else {
      newAnalysisAnswers.push(analysisAnswer);
    }

    setAnalysisAnswers(newAnalysisAnswers);
  }

  function handleSavePublicAnalysis(data) {
    return mutate({
      authToken,
      data: {
        name: data.name,
        analysisAnswers,
      },
    });
  }

  const hasAnsweredAllQuestions =
    analysisAnswers.length >= questionCount &&
    analysisAnswers.every(
      (analysisAnswer) =>
        analysisAnswer.hasOwnProperty('score') && analysisAnswer.score !== null
    );

  const disabled = isLoading || !hasAnsweredAllQuestions;
  const submitWarning = !hasAnsweredAllQuestions
    ? t('content.publicAnalysis.answerAllQuestions')
    : null;

  return (
    <PublicForm
      mutateData={handleSavePublicAnalysis}
      disabled={disabled}
      isLoading={isLoading}
      submitWarning={submitWarning}
    >
      <PublicAnalysisQuestions onChange={handleAnalysisAnswersChange} />
    </PublicForm>
  );
}

function PublicAnalysis() {
  return (
    <PublicAuthenticator type="analysis">
      <PublicAnalysisForm />
    </PublicAuthenticator>
  );
}

export default PublicAnalysis;
