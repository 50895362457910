import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { ROUTE_PROJECT_BASE } from '../../constants/routes';
import styles from './content.module.scss';

function Content({ children }) {
  const location = useLocation();
  const matchedProjectRoute = matchPath(location.pathname, {
    path: ROUTE_PROJECT_BASE,
  });
  const projectId = matchedProjectRoute?.params?.projectId;

  const contentClassNames = cn(styles.content, {
    [styles.inProject]: !!projectId,
  });

  return <div className={contentClassNames}>{children}</div>;
}

export default Content;
