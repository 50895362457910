import { useTranslation } from 'react-i18next';

import ExamplesEN from '../content/download/EN/examples.json';
import ExamplesNL from '../content/download/NL/examples.json';

export default function useExamples() {
  const { i18n } = useTranslation();

  let examples = ExamplesNL;
  if (i18n.language === 'en') {
    examples = ExamplesEN;
  }

  return {
    examples,
  };
}
