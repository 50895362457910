import { useAuth } from '../context/auth';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useStore, { selectAuthToken } from './useStore';

export default function useResponseExceptionHandler(isPublic = false) {
  const { authToken, setAuthToken } = useAuth();
  const { authToken: publicAuthToken, setAuthToken: setPublicAuthToken } =
    useStore(selectAuthToken);
  const { t } = useTranslation();

  return useCallback(
    (exception, errorMap = {}) => {
      if (exception.response) {
        const response = exception.response;

        if (!isPublic && response.status === 401 && authToken) {
          // not authenticated anymore
          setAuthToken(null, 'INACTIVE_TOO_LONG');
        } else if (isPublic && response.status === 401 && publicAuthToken) {
          setPublicAuthToken(null, 'INACTIVE_TOO_LONG');
        }

        if (response.data?.errors) {
          return Object.values(response.data.errors);
        } else if (errorMap.hasOwnProperty(response.data?.result)) {
          return errorMap[response.data?.result];
        } else if (errorMap.hasOwnProperty('default')) {
          return errorMap.default;
        } else if (response.config.method === 'get') {
          return t('error.generic.retrieve');
        } else if (
          response.config.method === 'post' ||
          response.config.method === 'put'
        ) {
          return t('error.generic.failed');
        }
      }
      return t('error.generic.noInternet');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAuthToken, t]
  );
}
