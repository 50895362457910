import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './distributionFilterHelpTexts.module.scss';

function DistributionFilterHelpTexts() {
  const { t } = useTranslation();

  return (
    <div className={`${styles.helpText} ${styles.helpText0}`}>
      {t('content.result.distributionTip.0')}
    </div>
  );
}

export default DistributionFilterHelpTexts;
