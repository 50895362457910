import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ContentPage from '../../components/contentPage/contentPage';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import ColumnContent from '../../components/columnContent/columnContent';
import BackButton from '../../components/backButton/backButton';
import Footer from '../../components/footer/footer';
import React from 'react';

export default function Disclaimer() {
  const { t } = useTranslation();
  const history = useHistory();

  function handleGoBackClick() {
    history.goBack();
  }

  return (
    <ContentPage>
      <ContentImageBackground />
      <ColumnContent>
        <h1>{t('disclaimer_title')}</h1>
        <h4>{t('disclaimer_managedBy')}</h4>
        <p>
          Centrum voor Criminaliteitspreventie en Veiligheid
          <br />
          Churchilllaan 11
          <br />
          3527 GV Utrecht
        </p>
        <Trans i18nKey="disclaimer_text">
          <h4>Tekst</h4>
          <p>
            Het CCV streeft naar een zorgvuldige controle van de informatie die
            we via het InterventieKompas aanbieden, zodat deze actueel,
            nauwkeurig en betrouwbaar is. Desondanks kan het CCV niet garanderen
            dat de informatie in het InterventieKompas te allen tijde actueel en
            correct is.
          </p>
          <h4>Afbeeldingen</h4>
          <p>
            De foto’s die getoond worden bij de voorbeelden van het
            Interventiekompas zijn in eigen beheer gemaakt, aangekocht of
            geselecteerd op rechtenvrij gebruik. Op deze foto’s kan copyright
            rusten.
          </p>
          <h4>Links</h4>
          <p>
            Deze website bevat links naar websites en bestanden van derden. Deze
            links zijn uitsluitend ter informatie. Het CCV heeft geen
            zeggenschap over deze websites en is niet verantwoordelijk of
            aansprakelijk voor de daarop aangeboden informatie, producten of
            diensten. Evenmin is het CCV verantwoordelijk voor verouderde
            informatie of links die niet meer actief zijn.
          </p>
          <h4>Aansprakelijkheid</h4>
          <p>
            Het CCV aanvaardt geen enkele aansprakelijkheid voor schade ontstaan
            door het gebruik van het InterventieKompas, danwel uit verleende
            diensten, aangeboden of verwezen content in het InterventieKompas.
            De vlekkeloze werking van het InterventieKompas wordt nagestreefd
            maar niet gegarandeerd. Aan de verstrekte informatie kunnen geen
            rechten worden ontleend.
          </p>
          <p>
            Bij vragen, opmerkingen en/of klachten over het InterventieKompas
            kun je contact opnemen met het CCV via
            <a href="mailto:1@hetccv.nl">tickets@hetccv.nl</a>.
          </p>
          <a href="mailto:info@hetccv.nl">info@hetccv.nl</a>
          <a href="mailto:tickets@hetccv.nl">tickets@hetccv.nl</a>{' '}
          {/* FOR TRANSLATION PURPOSES */}
        </Trans>
        <div className="mt-6 float-right">
          <BackButton onClick={handleGoBackClick} />
        </div>
      </ColumnContent>
      <Footer />
    </ContentPage>
  );
}
