import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import DistributionFilter from './components/distributionFilter/distributionFilter';
import Sunburst from './components/sunburst/sunburst';
import useProjectData from '../../../hooks/useProjectData';
import { generatePath, Redirect, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import zoomedProject from '../../../queries/zoomedProject';
import { useAuth } from '../../../context/auth';
import SunburstModal from './components/sunburstModal/sunburstModal';
import CompassCenter, {
  COMPASS_CENTER_SIZE,
} from './components/compassCenter/compassCenter';
import ExampleStack from '../../../components/exampleStack/exampleStack';

import styles from './result.module.scss';
import { DistributionFilterContext } from './context/distributionFilterContext';
import AnalysisQuestionModal from './components/analysisQuestionModal/analysisQuestionModal';
import finishProject from '../../../queries/finishProject';
import { ROUTE_PROJECT_DETAIL } from '../../../constants/routes';
import SunburstHelpTexts from './components/sunburstHelpTexts/sunburstHelpTexts';
import SunburstTitleTexts from './components/sunburstTitleTexts/sunburstTitleTexts';
import NextButton from '../../../components/nextButton/nextButton';
import useStateWithLocalStorage from '../../../hooks/useStateWithLocalStorage';
import { Button } from 'react-bootstrap';
import DistributionFilterHelpTexts from './components/distributionFilterHelpTexts/distributionFilterHelpTexts';

function Result() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { project } = useProjectData(projectId);
  const { authToken } = useAuth();
  const [mutateZoom] = useMutation(zoomedProject);
  const [mutateFinish] = useMutation(finishProject);
  const [
    selectedDistributionTargetGroups,
    setSelectedDistributionTargetGroups,
  ] = useState([]);
  const [navigateToProjectDetails, setNavigateToProjectDetails] =
    useState(false);
  const [finishButtonDisabled, setFinishButtonDisabled] = useState(false);
  const [sunburstModal, setSunburstModal] = useState(null);
  const [analysisQuestionModal, setAnalysisQuestionModal] = useState(null);
  const [showExampleStack, setShowExampleStack] = useState(false);
  const [exampleStack, setExampleStack] = useState(null);
  const [compassCenterAnimationCompleted, setCompassCenterAnimationCompleted] =
    useState(false);
  const [zoomInfo, setZoomInfo] = useState({
    zoomLevel: 0,
  });
  const [showHelpTexts, setShowHelpTexts] = useStateWithLocalStorage(
    'showHelpTexts',
    true
  );

  function onZoomSunburstStart(newZoomLevel, isInitialZoom) {
    setZoomInfo(null);
    if (isInitialZoom && project && !project.zoomed) {
      mutateZoom({
        id: projectId,
        authToken,
      });
    }
  }

  function onZoomSunburstFinished(newZoomLevel, nodeData, parentId) {
    setZoomInfo({ zoomLevel: newZoomLevel, name: nodeData?.name, parentId });
  }

  async function handleFinishProject() {
    setFinishButtonDisabled(true);
    if (project && !project.finished) {
      await mutateFinish({
        id: projectId,
        authToken,
      });
    }
    setNavigateToProjectDetails(true);
  }

  function openExampleStack() {
    setExampleStack(sunburstModal.exampleIds);
    setShowExampleStack(true);
  }

  function compassCenterAnimationInComplete() {
    if (exampleStack) {
      setCompassCenterAnimationCompleted(true);
    }
  }

  function compassCenterAnimationOutComplete() {
    if (!exampleStack) {
      setCompassCenterAnimationCompleted(false);
      setSunburstModal(null);
      setShowExampleStack(false);
    }
  }

  function closeExampleStack() {
    setExampleStack(null);
  }

  function handleOpenModal(modal) {
    if (modal.type === 'question') {
      setAnalysisQuestionModal(modal);
    } else {
      setSunburstModal(modal);
    }
  }

  function toggleShowHelpTexts() {
    setShowHelpTexts(!showHelpTexts);
  }

  if (navigateToProjectDetails) {
    return <Redirect to={generatePath(ROUTE_PROJECT_DETAIL, { projectId })} />;
  }

  function handleZoomOut() {
    if (zoomInfo.zoomLevel >= 1) {
      const middleOfCircle = document.getElementById(zoomInfo.parentId);
      if (middleOfCircle) {
        middleOfCircle.dispatchEvent(new Event('click'));
      }
    }
  }

  return (
    <DistributionFilterContext.Provider
      value={{
        selectedDistributionTargetGroups,
        setSelectedDistributionTargetGroups,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-4 mt-4">
            {showHelpTexts && <DistributionFilterHelpTexts />}
            <DistributionFilter />
          </div>
          <div className="col-8">
            {zoomInfo?.zoomLevel > 0 && (
              <Button onClick={handleZoomOut} className={styles.zoomOutButton}>
                {t('content.label.zoomOut')}
              </Button>
            )}
            <div
              className={`form-check float-right mt-3 ${styles.showHelpTextCheckbox}`}
            >
              <input
                type="checkbox"
                className="form-check-input"
                value=""
                checked={showHelpTexts}
                onChange={toggleShowHelpTexts}
                id="showHelpTexts"
              />
              <label className="form-check-label" htmlFor="showHelpTexts">
                {t('content.label.tipText')}
              </label>
            </div>
            <div className={`mt-5 ${styles.sunburstTitleTexts}`}>
              <SunburstTitleTexts
                zoomLevel={zoomInfo?.zoomLevel}
                name={zoomInfo?.name}
              />
            </div>
            <div className={styles.sunburstContainer}>
              {showHelpTexts && (
                <SunburstHelpTexts zoomLevel={zoomInfo?.zoomLevel} />
              )}
              <Sunburst
                size={505}
                answerSize={305}
                handleZoomStart={onZoomSunburstStart}
                handleZoomFinished={onZoomSunburstFinished}
                handleOpenModal={handleOpenModal}
              />
              <div
                className={cn(styles.examplesContainer, {
                  [styles.show]: showExampleStack,
                })}
                style={{
                  width: COMPASS_CENTER_SIZE,
                  height: COMPASS_CENTER_SIZE,
                }}
              >
                <CompassCenter
                  show={!!exampleStack}
                  categoryColors={sunburstModal?.categoryColors}
                  onAnimationInComplete={compassCenterAnimationInComplete}
                  onAnimationOutComplete={compassCenterAnimationOutComplete}
                />
                {exampleStack && compassCenterAnimationCompleted && (
                  <ExampleStack
                    onClose={closeExampleStack}
                    exampleIds={exampleStack}
                    categoryColors={sunburstModal?.categoryColors}
                    savedExampleIds={project?.examples.map(
                      (example) => example.example_id
                    )}
                  />
                )}
              </div>
            </div>
            <div
              className={`d-flex justify-content-end ${styles.nextButtonContainer}`}
            >
              <NextButton
                disabled={finishButtonDisabled}
                onClick={handleFinishProject}
              />
            </div>
          </div>
        </div>
      </div>
      <SunburstModal
        active={!!sunburstModal && !showExampleStack}
        onClose={() => setSunburstModal(null)}
        title={sunburstModal?.title}
        type={sunburstModal?.type}
        color={sunburstModal?.color}
        content={sunburstModal?.content}
        showExamplesButton={
          sunburstModal?.exampleIds && sunburstModal.exampleIds.length > 0
        }
        onShowExamples={openExampleStack}
      />
      <AnalysisQuestionModal
        active={!!analysisQuestionModal}
        onClose={() => setAnalysisQuestionModal(null)}
        colors={analysisQuestionModal?.colors}
        question={analysisQuestionModal?.question}
      />
    </DistributionFilterContext.Provider>
  );
}

export default Result;
