import axios from 'axios';

export default function toggleSaveExample({ id, authToken, data }) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/project/${id}/example`, data, {
      validateStatus: (status) => status === 201 || status === 200,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((response) => response.status);
}
