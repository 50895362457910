import axios from 'axios';

const getAnalysis = (key, { id }) => {
  if (!id || !sessionStorage.getItem('authToken')) {
    return Promise.resolve([]);
  }
  return axios
    .get(`${process.env.REACT_APP_API_URL}/project/${id}/analysis`, {
      validateStatus: (status) => status === 200,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
      },
    })
    .then((result) => result.data.result);
};

export default getAnalysis;
