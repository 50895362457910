import React from 'react';
import { Link } from 'react-router-dom';

import styles from './homeBtn.module.scss';

import { ROUTE_PROJECTS } from '../../constants/routes';

function HomeBtn() {
  return (
    <Link to={ROUTE_PROJECTS}>
      <div className={styles.homeButton} />
    </Link>
  );
}

export default HomeBtn;
