import React, { useState } from 'react';

import useAnalysisQuestionsWithData from '../../../hooks/useAnalysisQuestionsWithData';
import { generatePath, useParams } from 'react-router-dom';
import FixedLoader from '../../../components/loader/fixedLoader';
import Errors from '../../../components/errors/errors';
import { useTranslation } from 'react-i18next';
import {
  ROUTE_PROJECT_ANALYSIS_RESULT,
  ROUTE_PROJECT_INTAKE,
} from '../../../constants/routes';
import ConfirmNavigationModal from '../../../components/confirmNavigationModal/confirmNavigationModal';
import NextButton from '../../../components/nextButton/nextButton';
import AnalysisQuestions from './components/analysisQuestions/analysisQuestions';
import useProjectData from '../../../hooks/useProjectData';
import AnalysisAnswers from './components/analysisAnswers/analysisAnswers';
import EnablePublicProjectHeader, {
  VIEW_QUESTIONNAIRE,
  VIEW_ANSWERS,
} from '../../../components/enablePublicProjectHeader/enablePublicProjectHeader';
import SaveLoader from '../../../components/loader/saveLoader';
import { useMutation, useQueryCache } from 'react-query';
import saveAnalysis from '../../../queries/saveAnalysis';
import { useAuth } from '../../../context/auth';
import IKLink from '../../../components/button/IKLink';
import styles from './analysis.module.scss';

function Analysis() {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const [activeView, setActiveView] = useState(VIEW_QUESTIONNAIRE);
  const { project } = useProjectData(projectId);
  const {
    analysisQuestionsAnsweredCount,
    analysisQuestionCount,
    isLoading,
    error,
  } = useAnalysisQuestionsWithData(projectId);
  const { authToken } = useAuth();
  const queryCache = useQueryCache();
  const [mutate, { isLoading: analysisIsSaving }] = useMutation(saveAnalysis, {
    throwOnError: true,
    onSuccess: () => {
      queryCache.invalidateQueries('projectAnalysis');
    },
  });

  const publicFormEnabled = !!project?.public_analysis_id;

  async function handleSaveAnalysisQuestionAnswer(
    categoryId,
    questionId,
    answer,
    subQuestionAnswer
  ) {
    try {
      await mutate({
        id: projectId,
        authToken,
        data: {
          question_id: questionId,
          score: answer,
          category_id: categoryId,
          subquestion_answer: subQuestionAnswer,
        },
      });
    } catch (e) {
      console.log('e', e); // eslint-disable-line
    }
  }

  return (
    <>
      {!project?.type ? (
        <div className="container my-5">
          <div className="row">
            <div className="col-12 text-center">
              <p>{t('content.analysis.intakeRequired')}</p>
              <div className={styles.toIntakeButton}>
                <IKLink
                  link={generatePath(ROUTE_PROJECT_INTAKE, { projectId })}
                >
                  <div className={styles.label}>
                    {t('content.analysis.toIntake')}
                  </div>
                </IKLink>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <EnablePublicProjectHeader
            type="analysis"
            publicFormEnabled={publicFormEnabled}
            activeView={activeView}
            onChangeActiveView={setActiveView}
            answersCount={project?.public_analysis_count}
          />
          {isLoading ? (
            <FixedLoader />
          ) : (
            <>
              {analysisIsSaving && <SaveLoader />}
              {error ? (
                <Errors errors={[error]} />
              ) : (
                <>
                  {activeView === VIEW_QUESTIONNAIRE && (
                    <AnalysisQuestions
                      onSaveAnalysisQuestionAnswer={
                        handleSaveAnalysisQuestionAnswer
                      }
                    />
                  )}
                  {activeView === VIEW_ANSWERS && (
                    <AnalysisAnswers
                      onSaveAnalysisQuestionAnswer={
                        handleSaveAnalysisQuestionAnswer
                      }
                    />
                  )}

                  <div className="container mb-5">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-end">
                        <NextButton
                          to={generatePath(ROUTE_PROJECT_ANALYSIS_RESULT, {
                            projectId,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <ConfirmNavigationModal
            shouldTriggerConfirmation={
              analysisQuestionsAnsweredCount < analysisQuestionCount
            }
            title={t('content.questionnaire.analysis.confirm.title')}
            text={t('content.questionnaire.analysis.confirm.text')}
            confirmLabel={t('content.label.analysis.confirm.ok')}
            cancelLabel={t('content.label.analysis.confirm.cancel')}
          />
        </>
      )}
    </>
  );
}

export default Analysis;
