import React from 'react';
import { ReactComponent as Next } from './assets/next.svg';
import styles from './nextExampleButton.module.scss';

function NextExampleButton({ onClick, disabled }) {
  return (
    <button onClick={onClick} className={styles.nextBtn} disabled={disabled}>
      <Next width="28" height="28" />
    </button>
  );
}

export default NextExampleButton;
