import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  COLOR_TARGET_GROUP_CALCULATING,
  COLOR_TARGET_GROUP_COMPLYING,
  COLOR_TARGET_GROUP_DETERRED,
  COLOR_TARGET_GROUP_OFFENDER,
  COLOR_TARGET_GROUP_OPPORTUNITY,
  COLOR_TARGET_GROUP_PRINCIPLED,
  COLOR_TARGET_GROUP_SPONTANEOUS,
  COLOR_TARGET_GROUP_UNINTENTIONAL_COMPLIER,
  COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER,
} from '../../../../../constants/colors';
import useDistribution from '../../../../../hooks/useDistribution';
import DistributionFilterItem from '../distributionFilterItem/distributionFilterItem';
import {
  TARGET_GROUP_CALCULATING,
  TARGET_GROUP_DETERRED,
  TARGET_GROUP_OPPORTUNITY,
  TARGET_GROUP_PRINCIPLED,
  TARGET_GROUP_SPONTANEOUS,
  TARGET_GROUP_UNINTENTIONAL_COMPLIER,
  TARGET_GROUP_UNINTENTIONAL_OFFENDER,
  TARGET_GROUPS_COMPLYING,
  TARGET_GROUPS_OFFENDER,
} from '../../../../../constants/targetGroups';
import CheckboxWithLabel from '../../../../../components/checkboxWithLabel/checkboxWithLabel';
import Loader from '../../../../../components/loader/loader';
import Errors from '../../../../../components/errors/errors';
import { Button } from 'react-bootstrap';
import { DistributionFilterContext } from '../../context/distributionFilterContext';

function DistributionFilter() {
  const {
    selectedDistributionTargetGroups,
    setSelectedDistributionTargetGroups,
  } = useContext(DistributionFilterContext);
  const { projectId } = useParams();
  const { t } = useTranslation();
  const {
    distribution,
    isLoading: isLoadingDistribution,
    error,
    refetch,
  } = useDistribution(projectId);

  function handleValueSelect(value) {
    if (Array.isArray(value)) {
      setSelectedDistributionTargetGroups([
        ...new Set([...selectedDistributionTargetGroups, ...value]),
      ]);
    } else {
      setSelectedDistributionTargetGroups([
        ...new Set([...selectedDistributionTargetGroups, value]),
      ]);
    }
  }

  function handleValueDeselect(value) {
    if (Array.isArray(value)) {
      setSelectedDistributionTargetGroups([
        ...selectedDistributionTargetGroups.filter(
          (selectedTargetGroup) => !value.includes(selectedTargetGroup)
        ),
      ]);
    } else {
      setSelectedDistributionTargetGroups([
        ...selectedDistributionTargetGroups.filter(
          (selectedTargetGroup) => selectedTargetGroup !== value
        ),
      ]);
    }
  }

  function handleSelectComplier() {
    if (
      TARGET_GROUPS_COMPLYING.every((group) =>
        selectedDistributionTargetGroups.includes(group)
      )
    ) {
      handleValueDeselect(TARGET_GROUPS_COMPLYING);
    } else {
      handleValueSelect(TARGET_GROUPS_COMPLYING);
    }
  }

  function handleSelectOffender() {
    if (
      TARGET_GROUPS_OFFENDER.every((group) =>
        selectedDistributionTargetGroups.includes(group)
      )
    ) {
      handleValueDeselect(TARGET_GROUPS_OFFENDER);
    } else {
      handleValueSelect(TARGET_GROUPS_OFFENDER);
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <h3 className="font-weight-bold">{t('content.label.filter')}</h3>
          </div>
        </div>
        {isLoadingDistribution && <Loader />}
        {error && (
          <div className="row">
            <div className="col-12 mx-4 mt-2">
              <Errors errors={[error]} />
              <Button onClick={refetch}>{t('content.label.retry')}</Button>
            </div>
          </div>
        )}
      </div>
      {distribution && (
        <>
          <div className="container mt-2">
            <CheckboxWithLabel
              label={t('content.distribution.complying').toUpperCase()}
              checkboxColor={COLOR_TARGET_GROUP_COMPLYING}
              labelColor={COLOR_TARGET_GROUP_COMPLYING}
              onClick={handleSelectComplier}
              checked={TARGET_GROUPS_COMPLYING.every((group) =>
                selectedDistributionTargetGroups.includes(group)
              )}
            />
            <DistributionFilterItem
              label={t('content.distribution.unintentionalComplier')}
              value={TARGET_GROUP_UNINTENTIONAL_COMPLIER}
              color={COLOR_TARGET_GROUP_UNINTENTIONAL_COMPLIER}
              distributionValue={distribution.unintentionalComplier}
              checked={selectedDistributionTargetGroups.includes(
                TARGET_GROUP_UNINTENTIONAL_COMPLIER
              )}
              onClickSelect={handleValueSelect}
              onClickDeselect={handleValueDeselect}
            />
            <DistributionFilterItem
              label={t('content.distribution.spontaneous')}
              value={TARGET_GROUP_SPONTANEOUS}
              color={COLOR_TARGET_GROUP_SPONTANEOUS}
              distributionValue={distribution.spontaneous}
              checked={selectedDistributionTargetGroups.includes(
                TARGET_GROUP_SPONTANEOUS
              )}
              onClickSelect={handleValueSelect}
              onClickDeselect={handleValueDeselect}
            />
            <DistributionFilterItem
              label={t('content.distribution.deterred')}
              value={TARGET_GROUP_DETERRED}
              color={COLOR_TARGET_GROUP_DETERRED}
              checked={selectedDistributionTargetGroups.includes(
                TARGET_GROUP_DETERRED
              )}
              distributionValue={distribution.deterred}
              onClickSelect={handleValueSelect}
              onClickDeselect={handleValueDeselect}
            />
          </div>

          <div className="container mt-3">
            <CheckboxWithLabel
              label={t('content.distribution.offender').toUpperCase()}
              checkboxColor={COLOR_TARGET_GROUP_OFFENDER}
              labelColor={COLOR_TARGET_GROUP_OFFENDER}
              onClick={handleSelectOffender}
              checked={TARGET_GROUPS_OFFENDER.every((group) =>
                selectedDistributionTargetGroups.includes(group)
              )}
            />
            <DistributionFilterItem
              label={t('content.distribution.unintentionalOffender')}
              value={TARGET_GROUP_UNINTENTIONAL_OFFENDER}
              color={COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER}
              distributionValue={distribution.unintentionalOffender}
              checked={selectedDistributionTargetGroups.includes(
                TARGET_GROUP_UNINTENTIONAL_OFFENDER
              )}
              onClickSelect={handleValueSelect}
              onClickDeselect={handleValueDeselect}
            />
            <DistributionFilterItem
              label={t('content.distribution.opportunity')}
              value={TARGET_GROUP_OPPORTUNITY}
              color={COLOR_TARGET_GROUP_OPPORTUNITY}
              distributionValue={distribution.opportunity}
              checked={selectedDistributionTargetGroups.includes(
                TARGET_GROUP_OPPORTUNITY
              )}
              onClickSelect={handleValueSelect}
              onClickDeselect={handleValueDeselect}
            />
            <DistributionFilterItem
              label={t('content.distribution.principled')}
              value={TARGET_GROUP_PRINCIPLED}
              color={COLOR_TARGET_GROUP_PRINCIPLED}
              distributionValue={distribution.principled}
              checked={selectedDistributionTargetGroups.includes(
                TARGET_GROUP_PRINCIPLED
              )}
              onClickSelect={handleValueSelect}
              onClickDeselect={handleValueDeselect}
            />
            <DistributionFilterItem
              label={t('content.distribution.calculating')}
              value={TARGET_GROUP_CALCULATING}
              color={COLOR_TARGET_GROUP_CALCULATING}
              distributionValue={distribution.calculating}
              checked={selectedDistributionTargetGroups.includes(
                TARGET_GROUP_CALCULATING
              )}
              onClickSelect={handleValueSelect}
              onClickDeselect={handleValueDeselect}
            />
          </div>
        </>
      )}
    </>
  );
}

export default DistributionFilter;
