import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './project.module.scss';

import ProjectProgress from '../projectProgress/projectProgress';

function EmptyProject({ id }) {
  const { t } = useTranslation();

  return (
    <div className={styles.project}>
      <div className={styles.content}>
        <p>{t('content.project.noProjects')}</p>
      </div>
      <div className={styles.projectProgressContainer}>
        <ProjectProgress projectId={id} />
      </div>
    </div>
  );
}

export default EmptyProject;
