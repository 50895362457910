import styles from './analysisQuestionAnswers.module.scss';

function AnalysisQuestionAnswerCount({
  answers,
  selectedAnswer,
  publicAnalysisAnswers,
}) {
  function countAnswer(score) {
    const startCount = selectedAnswer === score ? 1 : 0;
    return (
      publicAnalysisAnswers?.reduce((acc, publicAnalysisAnswer) => {
        if (publicAnalysisAnswer.score === score) return acc + 1;
        return acc;
      }, startCount) || startCount
    );
  }

  return (
    <div className="row no-gutters h-100">
      <div className="col-1 h-100">
        <div className={styles.answerCount}>{countAnswer(-1)}</div>
      </div>
      <div className="col-1 h-100">
        <div className={styles.answerCount}>{countAnswer(0)}</div>
      </div>
      {answers.map((answer, i) => {
        return (
          <div className="col-2 h-100" key={i}>
            <div className={styles.answerCount}>{countAnswer(i + 1)}</div>
          </div>
        );
      })}
    </div>
  );
}

export default AnalysisQuestionAnswerCount;
