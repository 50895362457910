import { useState } from 'react';
import useUpdateEffect from './useUpdateEffect';

export default function useDistributionChangeHandler(
  defaultDistribution,
  onChange
) {
  const [unknown, setUnknown] = useState(defaultDistribution.unknown);
  const [unintentionalComplier, setUnintentionalComplier] = useState(
    defaultDistribution.unintentionalComplier
  );
  const [spontaneous, setSpontaneous] = useState(
    defaultDistribution.spontaneous
  );
  const [deterred, setDeterred] = useState(defaultDistribution.deterred);
  const [principled, setPrincipled] = useState(defaultDistribution.principled);
  const [opportunity, setOpportunity] = useState(
    defaultDistribution.opportunity
  );

  const known = 100 - unknown;
  const nonSpontaneous = known - spontaneous < 0 ? 0 : known - spontaneous;
  const nonDeterred =
    nonSpontaneous - deterred < 0 ? 0 : nonSpontaneous - deterred;
  const nonPrincipled =
    nonDeterred - principled < 0 ? 0 : nonDeterred - principled;

  useUpdateEffect(() => {
    onChange({
      unknown,
      unintentional_complier: unintentionalComplier,
      unintentional_offender: unknown - unintentionalComplier,
      spontaneous,
      deterred,
      principled,
      opportunity,
      nonPrincipled,
    });
  }, [
    unknown,
    unintentionalComplier,
    spontaneous,
    deterred,
    principled,
    opportunity,
    nonPrincipled,
  ]);

  return {
    unknown,
    setUnknown,
    unintentionalComplier,
    setUnintentionalComplier,
    spontaneous,
    setSpontaneous,
    deterred,
    setDeterred,
    principled,
    setPrincipled,
    opportunity,
    setOpportunity,
    known,
    nonSpontaneous,
    nonDeterred,
    nonPrincipled,
  };
}
