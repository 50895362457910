export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const currentYear = new Date().getFullYear();
const years = [];
let startYear = 2010;

while (startYear <= currentYear + 15) {
  years.push(startYear++);
}

export { years };
