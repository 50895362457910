import axios from 'axios';

const getPublicProjectPublicInfo = (key, { publicId, type }) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/public/project/${publicId}/${type}/public`,
      {
        validateStatus: (status) => status === 200,
      }
    )
    .then((result) => result.data.result);
};

export default getPublicProjectPublicInfo;
