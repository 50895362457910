import {
  COLOR_TARGET_GROUP_CALCULATING,
  COLOR_TARGET_GROUP_DETERRED,
  COLOR_TARGET_GROUP_KNOWN,
  COLOR_TARGET_GROUP_OPPORTUNITY,
  COLOR_TARGET_GROUP_PRINCIPLED,
  COLOR_TARGET_GROUP_SPONTANEOUS,
  COLOR_TARGET_GROUP_UNDEFINED,
  COLOR_TARGET_GROUP_UNINTENTIONAL_COMPLIER,
  COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER,
  COLOR_TARGET_GROUP_UNKNOWN,
} from '../../../../../constants/colors';
import React, { useState } from 'react';
import ModalComponent from '../../../../../components/modal/modal';
import nl2br from '../../../../../utilities/string/nl2br';
import { useTranslation } from 'react-i18next';
import DistributionQuestion from '../distributionQuestion/distributionQuestion';
import getAllDistributionValues from '../../../../../utilities/distribution/getAllDistributionValues';
import useDistributionChangeHandler from '../../../../../hooks/useDistributionChangeHandler';

function DistributionQuestions({
  defaultDistribution = getAllDistributionValues(),
  disabled,
  onChange,
}) {
  const { t } = useTranslation();
  const {
    unknown,
    setUnknown,
    unintentionalComplier,
    setUnintentionalComplier,
    spontaneous,
    setSpontaneous,
    deterred,
    setDeterred,
    principled,
    setPrincipled,
    opportunity,
    setOpportunity,
    known,
    nonSpontaneous,
    nonDeterred,
    nonPrincipled,
  } = useDistributionChangeHandler(defaultDistribution, onChange);

  const [infoModalContent, setInfoModalContent] = useState({
    show: false,
    title: null,
    body: null,
  });

  function showInfoModal(key) {
    setInfoModalContent({
      show: true,
      title: t(`${key}.title`),
      body: nl2br(t(`${key}.text`)),
    });
  }

  function hideInfoModal() {
    setInfoModalContent({
      show: false,
      title: null,
      body: null,
    });
  }

  return (
    <div className="container px-0">
      <DistributionQuestion
        value={unknown}
        max={100}
        onChange={setUnknown}
        title={t('content.distribution.label.unknown')}
        topLabel={t('content.distribution.targetGroup')}
        leftLabel={t('content.distribution.unknown')}
        leftColor={COLOR_TARGET_GROUP_UNKNOWN}
        rightLabel={t('content.distribution.known')}
        rightColor={COLOR_TARGET_GROUP_KNOWN}
        disabled={disabled}
        onInfoBtnClick={() =>
          showInfoModal('content.questionnaire.distribution.unknown')
        }
      />

      <DistributionQuestion
        disabled={disabled}
        value={unintentionalComplier}
        max={unknown}
        onChange={setUnintentionalComplier}
        title={t('content.distribution.label.unintentionalComplier', {
          unknown,
        })}
        topLabel={t('content.distribution.unknown')}
        topColor={COLOR_TARGET_GROUP_UNKNOWN}
        leftLabel={t('content.distribution.unintentionalComplier')}
        leftColor={COLOR_TARGET_GROUP_UNINTENTIONAL_COMPLIER}
        rightLabel={t('content.distribution.unintentionalOffender')}
        rightColor={COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER}
        onInfoBtnClick={() =>
          showInfoModal(
            'content.questionnaire.distribution.unintentionalComplier'
          )
        }
      />

      <DistributionQuestion
        disabled={disabled}
        value={spontaneous}
        max={known}
        onChange={setSpontaneous}
        topLabel={t('content.distribution.known')}
        topColor={COLOR_TARGET_GROUP_KNOWN}
        leftLabel={t('content.distribution.spontaneous')}
        leftColor={COLOR_TARGET_GROUP_SPONTANEOUS}
        rightLabel={t('content.distribution.nonSpontaneous')}
        rightColor={COLOR_TARGET_GROUP_KNOWN}
        title={t('content.distribution.label.spontaneous', { known })}
        onInfoBtnClick={() =>
          showInfoModal('content.questionnaire.distribution.spontaneous')
        }
      />

      <DistributionQuestion
        disabled={disabled}
        value={deterred}
        max={nonSpontaneous}
        onChange={setDeterred}
        topLabel={t('content.distribution.nonSpontaneous')}
        topColor={COLOR_TARGET_GROUP_KNOWN}
        leftLabel={t('content.distribution.deterred')}
        leftColor={COLOR_TARGET_GROUP_DETERRED}
        rightLabel={t('content.distribution.intentional')}
        rightColor={COLOR_TARGET_GROUP_KNOWN}
        title={t('content.distribution.label.deterred', { nonSpontaneous })}
        onInfoBtnClick={() =>
          showInfoModal('content.questionnaire.distribution.deterred')
        }
      />

      <DistributionQuestion
        disabled={disabled}
        value={principled}
        max={nonDeterred}
        onChange={setPrincipled}
        topLabel={t('content.distribution.intentional')}
        topColor={COLOR_TARGET_GROUP_KNOWN}
        leftLabel={t('content.distribution.principled')}
        leftColor={COLOR_TARGET_GROUP_PRINCIPLED}
        rightLabel={t('content.distribution.rest')}
        rightColor={COLOR_TARGET_GROUP_UNDEFINED}
        title={t('content.distribution.label.principled', { nonDeterred })}
        onInfoBtnClick={() =>
          showInfoModal('content.questionnaire.distribution.principled')
        }
      />

      <DistributionQuestion
        disabled={disabled}
        value={opportunity}
        max={nonPrincipled}
        onChange={setOpportunity}
        topLabel={`${t('content.distribution.intentional')} (${t(
          'content.distribution.rest'
        )})`}
        topColor={COLOR_TARGET_GROUP_KNOWN}
        leftLabel={t('content.distribution.opportunity')}
        leftColor={COLOR_TARGET_GROUP_OPPORTUNITY}
        rightLabel={t('content.distribution.calculating')}
        rightColor={COLOR_TARGET_GROUP_CALCULATING}
        title={t('content.distribution.label.opportunity', { nonPrincipled })}
        onInfoBtnClick={() =>
          showInfoModal('content.questionnaire.distribution.opportunity')
        }
      />

      <ModalComponent
        active={infoModalContent.show}
        onClose={hideInfoModal}
        title={infoModalContent.title}
      >
        <p
          dangerouslySetInnerHTML={{ __html: infoModalContent.body }}
          className="mb-0"
        />
      </ModalComponent>
    </div>
  );
}

export default DistributionQuestions;
