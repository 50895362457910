import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useParams, generatePath } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';

import styles from './projectIntake.module.scss';

import useResponseExceptionHandler from '../../../hooks/useResponseExceptionHandler';
import { months, years } from '../../../utilities/date/projectDates';
import { useAuth } from '../../../context/auth';
import FixedLoader from '../../../components/loader/fixedLoader';
import Errors from '../../../components/errors/errors';
import IntakeModal from './components/intakeModal/intakeModal';
import Project from '../../../components/project/project';
import { ROUTE_PROJECT_ANALYSIS_INTRO } from '../../../constants/routes';
import useProjectData from '../../../hooks/useProjectData';
import { useMutation, useQueryCache } from 'react-query';
import updateProject from '../../../queries/updateProject';
import SaveLoader from '../../../components/loader/saveLoader';
import NextButton from '../../../components/nextButton/nextButton';
import useIntakeQuestionsWithData from '../../../hooks/useIntakeQuestionsWithData';
import IntakeQuestionField from './components/intakeQuestionField/intakeQuestionField';
import ProjectTypeField from './components/projectTypeField/projectTypeField';
import ConfirmNavigationModal from '../../../components/confirmModal/confirmNavigationModal';

function ProjectIntake() {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const {
    register,
    control,
    watch,
    handleSubmit,
    errors,
    reset,
    getValues,
    setValue,
  } = useForm();
  const { fields: intakeQuestionPreFields } = useFieldArray({
    control,
    name: 'intake_questions_pre',
    keyName: 'key',
  });
  const { fields: intakeQuestionPostFields } = useFieldArray({
    control,
    name: 'intake_questions_post',
    keyName: 'key',
  });
  const [hasSavedProjectData, setHasSaveProjectData] = useState(false);
  const [showConfirmChangeType, setShowConfirmChangeType] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [globalErrors, setGlobalErrors] = useState(null);
  const handleResponseException = useResponseExceptionHandler();
  const { authToken } = useAuth();
  const [mutate] = useMutation(updateProject, {
    throwOnError: true,
    onSuccess: () => {
      queryCache.invalidateQueries('project');
      queryCache.invalidateQueries('projectIntake');
    },
  });
  const {
    project,
    isLoading: isLoadingProject,
    error: projectError,
  } = useProjectData(projectId);
  const {
    intakeQuestions,
    intakeQuestionsPre,
    intakeQuestionsPost,
    isLoading: isLoadingIntake,
    error: intakeError,
  } = useIntakeQuestionsWithData(projectId, project?.type);
  const projectTypeRef = useRef(project?.type);

  useEffect(() => {
    if (project && intakeQuestionsPre) {
      reset({
        ...project,
        intake_questions_pre: intakeQuestionsPre,
        intake_questions_post: intakeQuestionsPost,
      });
    }
    if (project?.type && !projectTypeRef.current) {
      projectTypeRef.current = project?.type;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, project, intakeQuestions]);

  function saveOnBlur() {
    handleProjectSubmit(getValues(), false);
  }

  function handleError(e) {
    setIsSaving(false);
    const error = handleResponseException(e, {
      default: t('error.generic.failed'),
    });
    setGlobalErrors([error]);
  }

  async function handleProjectSubmit(data, isManualSubmit = true) {
    projectTypeRef.current = data.type;
    setIsSaving(true);
    setGlobalErrors(null);
    const month = parseInt(data.month) + 1;
    const startDate = `${data.year}-${month >= 10 ? month : `0${month}`}-01`;

    try {
      await mutate({
        id: projectId,
        authToken,
        data: {
          ...data,
          startdate: startDate,
        },
      });
      if (isManualSubmit) {
        setHasSaveProjectData(true);
      } else {
        setIsSaving(false);
      }
    } catch (e) {
      if (isManualSubmit) {
        handleError(e);
      } else {
        setIsSaving(false);
      }
    }
  }

  function handleProjectTypeChange() {
    if (
      project.analysis_questions_count > 0 ||
      intakeQuestionsPost.some((intakeQuestion) => !!intakeQuestion.answer)
    ) {
      setShowConfirmChangeType(true);
    } else {
      saveOnBlur();
    }
  }

  function handleCloseConfirmChangeType() {
    setValue('type', projectTypeRef.current);
    setShowConfirmChangeType(false);
  }

  function handleConfirmChangeType() {
    setShowConfirmChangeType(false);
    saveOnBlur();
  }

  if (hasSavedProjectData) {
    return (
      <Redirect
        to={generatePath(ROUTE_PROJECT_ANALYSIS_INTRO, { projectId })}
      />
    );
  }

  return (
    <div className="container">
      <div className="row my-3">
        <div className="col-6">
          <h2>{t('content.project.info')}</h2>
          {(globalErrors || projectError || intakeError) && (
            <Errors errors={globalErrors || [projectError || intakeError]} />
          )}
          {(isLoadingProject || isLoadingIntake) && <FixedLoader />}
          {isSaving && <SaveLoader />}
          {project && (
            <form onSubmit={handleSubmit(handleProjectSubmit)}>
              <div className="form-group">
                <label>{t('content.project.title')}</label>
                <input
                  type="text"
                  name="title"
                  ref={register({
                    required: t('validation.required'),
                    maxLength: {
                      value: 75,
                      message: t('validation.maxLength', { maxLength: 75 }),
                    },
                  })}
                  className={cn('form-control', { 'is-invalid': errors.title })}
                  onBlur={saveOnBlur}
                />
                {errors.title && (
                  <div className="invalid-feedback">{errors.title.message}</div>
                )}
              </div>
              <div className="form-group">
                <label>
                  {t('content.project.meta')} {t('content.project.metaSuffix')}
                </label>
                <textarea
                  name="metadata"
                  rows="4"
                  ref={register}
                  className={cn('form-control', {
                    'is-invalid': errors.metadata,
                  })}
                  onBlur={saveOnBlur}
                />
                {errors.metadata && (
                  <div className="invalid-feedback">
                    {errors.metadata.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t('content.project.notes')}</label>
                <textarea
                  name="notes"
                  rows="4"
                  ref={register}
                  className={cn('form-control', { 'is-invalid': errors.notes })}
                  onBlur={saveOnBlur}
                />
                {errors.notes && (
                  <div className="invalid-feedback">{errors.notes.message}</div>
                )}
              </div>

              <div className="form-group">
                <label>{t('content.project.startdateEdit')}</label>
                <div className="form-row">
                  <div className="col">
                    <select
                      className="form-control"
                      ref={register}
                      name="month"
                      onBlur={saveOnBlur}
                    >
                      {months.map((month, i) => (
                        <option key={i} value={i}>
                          {t(`content.month.${month}`)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col">
                    <select
                      className="form-control"
                      ref={register}
                      name="year"
                      onBlur={saveOnBlur}
                    >
                      {years.map((year, i) => (
                        <option key={i} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="mt-5 mb-3">
                <IntakeModal />
                <h2>{t('project.new.intake.title')}</h2>
              </div>
              {intakeQuestionPreFields.map((intakeQuestion, i) => (
                <IntakeQuestionField
                  key={`${intakeQuestion.id}_${intakeQuestion.key}`}
                  name={`intake_questions_pre[${i}].answer`}
                  questionIdName={`intake_questions_pre[${i}].question_id`}
                  intakeQuestion={intakeQuestion}
                  error={errors?.intake_questions_pre?.[i]?.answer}
                  onBlur={saveOnBlur}
                  register={register}
                />
              ))}

              {/* SWITCH PROJECT TYPE FIELD */}
              <ProjectTypeField
                onChange={handleProjectTypeChange}
                register={register}
                error={errors.type}
              />

              {intakeQuestionPostFields.map((intakeQuestion, i) => (
                <IntakeQuestionField
                  key={intakeQuestion.id}
                  name={`intake_questions_post[${i}].answer`}
                  questionIdName={`intake_questions_post[${i}].question_id`}
                  intakeQuestion={intakeQuestion}
                  error={errors?.intake_questions_post?.[i]?.answer}
                  onBlur={saveOnBlur}
                  register={register}
                />
              ))}

              <div className="d-flex justify-content-end">
                <NextButton
                  type="submit"
                  disabled={isSaving || isLoadingProject}
                />
              </div>
            </form>
          )}
        </div>
        <div className="col-6">
          {project && (
            <div className={styles.projectSummaryContainer}>
              <Project
                showProgress={false}
                title={watch('title')}
                metadata={watch('metadata')}
                notes={watch('notes')}
                startdate={`${watch('year')}-${parseInt(watch('month')) + 1}`}
              />
            </div>
          )}
        </div>
      </div>
      <ConfirmNavigationModal
        active={showConfirmChangeType}
        onClose={handleCloseConfirmChangeType}
        onConfirm={handleConfirmChangeType}
        title={t('content.project.changeProjectTypeConfirm.title')}
        text={t('content.project.changeProjectTypeConfirm.text')}
        confirmLabel={t('content.project.changeProjectTypeConfirm.confirm')}
        cancelLabel={t('content.project.changeProjectTypeConfirm.cancel')}
      />
    </div>
  );
}

export default ProjectIntake;
