import React from 'react';

import { ReactComponent as Save } from './assets/save.svg';
import { ReactComponent as Saved } from './assets/saved.svg';

import styles from './saveBtn.module.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';

function SaveBtn({ onClick, isSaved, disabled }) {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="tooltip-disabled">
          {t(`content.label.${isSaved ? 'delete' : 'save'}`)}
        </Tooltip>
      }
    >
      <button onClick={onClick} className={styles.saveBtn} disabled={disabled}>
        {isSaved ? (
          <Saved width="30" height="30" />
        ) : (
          <Save width="30" height="30" />
        )}
      </button>
    </OverlayTrigger>
  );
}

export default SaveBtn;
