import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './nextButton.module.scss';
import IKButton from '../button/IKButton';
import IKLink from '../button/IKLink';

function NextButton({ onClick, to, disabled, type = 'button' }) {
  const { t } = useTranslation();

  return (
    <div className={styles.nextButton}>
      {to ? (
        <IKLink link={to} disabled={disabled} priority>
          <div className={styles.label}>{t('content.label.continue')}</div>
        </IKLink>
      ) : (
        (onClick || type) && (
          <IKButton onClick={onClick} disabled={disabled} type={type} priority>
            <div className={styles.label}>{t('content.label.continue')}</div>
          </IKButton>
        )
      )}
    </div>
  );
}

export default NextButton;
