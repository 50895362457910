import axios from 'axios';

const getPublicProject = (key, { authToken }) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/public/project`, {
      validateStatus: (status) => status === 200,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((result) => result.data.result);
};

export default getPublicProject;
