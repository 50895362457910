import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import useResponseExceptionHandler from './useResponseExceptionHandler';
import { useQuery } from 'react-query';
import getProject from '../queries/getProject';
import { useTranslation } from 'react-i18next';

export function useProjectDataExtractor(isError, queryError, data) {
  const { i18n } = useTranslation();
  const [project, setProject] = useState(null);
  const [error, setError] = useState(null);
  const handleResponseException = useResponseExceptionHandler();

  const handleError = useCallback(
    (e) => {
      const error = handleResponseException(e);
      setError(error);
    },
    [setError, handleResponseException]
  );

  useEffect(() => {
    if (isError && queryError) {
      handleError(queryError);
    } else if (error) {
      setError(null);
    }
  }, [error, isError, queryError, handleError]);

  useEffect(() => {
    if (data) {
      const startDate = dayjs(data.startdate);
      setProject({
        ...data,
        month: parseInt(startDate.format('M')) - 1,
        year: parseInt(startDate.format('YYYY')),
      });
    } else if (!data && project) {
      setProject(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, i18n.language]);

  return { project, error };
}

export default function useProjectData(projectId) {
  const {
    isLoading,
    isError,
    data,
    error: queryError,
  } = useQuery(['project', { id: projectId }], getProject, {
    refetchInterval: 60000,
  });

  const { project, error } = useProjectDataExtractor(isError, queryError, data);

  return {
    project,
    isLoading,
    error,
  };
}
