import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import {
  ROUTE_PROJECT_ANALYSIS,
  ROUTE_PROJECT_DISTRIBUTION_INTRO,
} from '../../../constants/routes';
import { useTranslation } from 'react-i18next';
import Sunburst from '../result/components/sunburst/sunburst';

import './analysisResult.scss';
import NextButton from '../../../components/nextButton/nextButton';
import BackButton from '../../../components/backButton/backButton';

function AnalysisResult() {
  const { projectId } = useParams();
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="mt-4 row justify-content-center">
        <div className="col-5 text-center">
          <h3>{t('content.analysisResult.helpText')}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-3 d-flex justify-content-center align-items-end">
          <BackButton
            to={generatePath(ROUTE_PROJECT_ANALYSIS, { projectId })}
          />
        </div>
        <div className="col-6">
          <div className="sunburst">
            <Sunburst size={505} answerSize={305} interactive={false} />
          </div>
        </div>
        <div className="col-3 d-flex justify-content-center align-items-end">
          <NextButton
            to={generatePath(ROUTE_PROJECT_DISTRIBUTION_INTRO, { projectId })}
          />
        </div>
      </div>
    </div>
  );
}

export default AnalysisResult;
