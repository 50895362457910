import InfoBtn from '../../../../../components/infoBtn/infoBtn';
import DistributionSlider from '../distributionSlider/distributionSlider';
import React from 'react';

function DistributionQuestion({
  value,
  max = 100,
  title,
  topLabel,
  topColor,
  leftLabel,
  leftColor,
  rightLabel,
  rightColor,
  onInfoBtnClick,
  onChange,
  disabled,
}) {
  return (
    <div className="row no-gutters my-5">
      <div className="col-12 mb-2 w-100 d-flex align-items-center">
        <h3 className="px-5 flex-grow-1 mb-0">{title}</h3>
        <div className="float-right ml-1">
          <InfoBtn onClick={onInfoBtnClick} />
        </div>
      </div>
      <div className="col-12">
        <DistributionSlider
          disabled={disabled}
          value={value}
          max={max}
          topLabel={topLabel}
          topColor={topColor}
          leftLabel={leftLabel}
          leftColor={leftColor}
          rightLabel={rightLabel}
          rightColor={rightColor}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default DistributionQuestion;
