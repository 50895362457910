import React from 'react';
import cn from 'classnames';
import styles from './header.module.scss';
import useAnalysisQuestionsWithData from '../../hooks/useAnalysisQuestionsWithData';
import {
  PROJECT_STEP_ANALYSIS,
  PROJECT_STEP_DETAIL,
  PROJECT_STEP_DISTRIBUTION,
  PROJECT_STEP_NEW,
  PROJECT_STEP_RESULT,
} from '../../constants/projectSteps';

function AnalysisProgressBar({ projectId }) {
  const { analysisQuestionsAnsweredCount, analysisQuestionCount } =
    useAnalysisQuestionsWithData(projectId);

  return (
    <div
      className={styles.progressBar}
      style={{
        width: `${
          (100 / analysisQuestionCount) * analysisQuestionsAnsweredCount
        }%`,
      }}
    />
  );
}

function HeaderBorder({ projectStep, projectId }) {
  const headerBorderClasses = cn(styles.headerBorder, {
    [styles.new]: projectStep === PROJECT_STEP_NEW,
    [styles.analysis]: projectStep === PROJECT_STEP_ANALYSIS,
    [styles.distribution]: projectStep === PROJECT_STEP_DISTRIBUTION,
    [styles.result]: projectStep === PROJECT_STEP_RESULT,
    [styles.detail]: projectStep === PROJECT_STEP_DETAIL,
  });
  return (
    <div className={headerBorderClasses}>
      {projectStep === 'analysis' && (
        <AnalysisProgressBar projectId={projectId} />
      )}
    </div>
  );
}

export default HeaderBorder;
