import { Alert } from 'react-bootstrap';

import styles from './saveSuccess.module.scss';
import { useTranslation } from 'react-i18next';

function SaveSuccess() {
  const { t } = useTranslation();
  return (
    <div className="container h-100">
      <div className="row no-gutters h-100">
        <div className="col-6 offset-3 h-100 d-flex align-items-center">
          <Alert variant="success" className={styles.message}>
            <h3>{t('content.saveSuccess.message')}</h3>
          </Alert>
        </div>
      </div>
    </div>
  );
}

export default SaveSuccess;
