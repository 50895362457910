import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './analysisQuestionAnswers.module.scss';

function AnalysisQuestionAnswers({
  answers,
  selectedAnswer = null,
  colors,
  onSelectAnswer,
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(selectedAnswer);

  function handleSelectAnswer(answer) {
    if (onSelectAnswer && answer !== selected) {
      setSelected(answer);
      onSelectAnswer(answer);
    }
  }

  useEffect(() => {
    if (selectedAnswer !== selected) {
      setSelected(selectedAnswer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswer]);

  return (
    <div
      className={cn('row no-gutters h-100', {
        [styles.selectionMade]: selected !== null,
      })}
    >
      <div className="col-1 h-100">
        <div
          className={cn(styles.answer, styles.unknownAnswer, {
            [styles.selected]: -1 === selected,
            [styles.selectable]: !!onSelectAnswer,
          })}
          style={{ backgroundColor: colors[0] }}
          onClick={() => handleSelectAnswer(-1)}
        >
          <p>{t('content.analysis.answer.unknown')}</p>
        </div>
      </div>
      <div className="col-1 h-100">
        <div
          className={cn(styles.answer, {
            [styles.selected]: 0 === selected,
            [styles.selectable]: !!onSelectAnswer,
          })}
          style={{ backgroundColor: colors[0] }}
          onClick={() => handleSelectAnswer(0)}
        >
          <p>{t('content.analysis.answer.notApplicable')}</p>
        </div>
      </div>
      {answers.map((answer, i) => {
        const answerScore = i + 1;
        return (
          <div className="col-2 h-100" key={i}>
            <div
              className={cn(styles.answer, {
                [styles.selected]: answerScore === selected,
                [styles.selectable]: !!onSelectAnswer,
              })}
              style={{ backgroundColor: colors[answerScore] }}
              onClick={() => handleSelectAnswer(answerScore)}
            >
              <p>{answer}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AnalysisQuestionAnswers;
