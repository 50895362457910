import React, { useCallback, useEffect, useRef, useState } from 'react';

import styles from './categoryHeader.module.scss';

function CategoryHeader({ category, index }) {
  const headerRef = useRef(null);
  const [fixedHeader, setFixedHeader] = useState(false);

  const handleScroll = useCallback(() => {
    if (!fixedHeader && headerRef.current.getBoundingClientRect().top <= 121) {
      setFixedHeader(true);
    } else if (
      fixedHeader &&
      headerRef.current.getBoundingClientRect().top > 121
    ) {
      setFixedHeader(false);
    }
  }, [fixedHeader]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <div
        ref={headerRef}
        className={styles.categoryHeader}
        style={{
          backgroundColor: category.colors[2],
          zIndex: index + 1000,
        }}
      >
        <h1>{category.name}</h1>
      </div>
      {fixedHeader && (
        <div
          className={styles.fixedcategoryHeader}
          style={{
            backgroundColor: category.colors[2],
            zIndex: index + 1000,
          }}
        >
          <h1>{category.name}</h1>
        </div>
      )}
    </>
  );
}

export default CategoryHeader;
