import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTE_HOME } from '../../constants/routes';
import { useForm } from 'react-hook-form';
import emailValidator from '../../validators/emailValidator';
import cn from 'classnames';
import axios from 'axios';

import Errors from '../../components/errors/errors';
import FixedLoader from '../../components/loader/fixedLoader';
import useResponseExceptionHandler from '../../hooks/useResponseExceptionHandler';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import BackButton from '../../components/backButton/backButton';
import Footer from '../../components/footer/footer';
import ContentPage from '../../components/contentPage/contentPage';
import ColumnContent from '../../components/columnContent/columnContent';

function ForgotPassword() {
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const handleResponseException = useResponseExceptionHandler();

  function handleResponseError(exception) {
    setIsLoading(false);
    const error = handleResponseException(exception, {
      default: t('forgotPassword_error_invalidEmail'),
      'passwords.throttled': t('forgotPassword_error_tooManyTimes'),
    });
    setGlobalError([error]);
  }

  function handleForgotPasswordSubmit(data) {
    setEmailSent(false);
    setIsLoading(true);
    setGlobalError(null);
    axios
      .post(`${process.env.REACT_APP_API_URL}/forgot_password`, data, {
        validateStatus: (status) => status === 200,
      })
      .then((response) => {
        setIsLoading(false);
        setEmailSent(true);
      })
      .catch(handleResponseError);
  }

  function navigateToHome() {
    history.push(generatePath(ROUTE_HOME));
  }

  return (
    <ContentPage>
      <ContentImageBackground />
      <ColumnContent>
        <div className="col-8 mx-auto">
          <h1>{t('forgotPassword_title')}</h1>
          <p>{t('forgotPassword_text')}</p>

          {emailSent && (
            <div className="alert alert-success">
              <h4 className="alert-heading">
                {t('forgotPassword_success_title')}
              </h4>
              <p className="mb-0">{t('forgotPassword_success_body')}</p>
            </div>
          )}

          {globalError && <Errors errors={[globalError]} />}
          {isLoading && <FixedLoader />}
          <form onSubmit={handleSubmit(handleForgotPasswordSubmit)}>
            <div className="form-group">
              <label>{t('content.login.email')}</label>
              <input
                type="email"
                name="email"
                ref={register({
                  required: t('validation.required'),
                  validate: (value) =>
                    emailValidator(value) || t('error.register.invalidEmail'),
                })}
                className={cn('form-control', { 'is-invalid': errors.email })}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email.message}</div>
              )}
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                {t('content.label.send')}
              </button>
            </div>
          </form>
          <div className="mt-6 float-right">
            <BackButton onClick={navigateToHome} />
          </div>
        </div>
      </ColumnContent>
      <Footer />
    </ContentPage>
  );
}

export default ForgotPassword;
