import { useState } from 'react';
import CategoryHeader from '../../../../steps/analysis/components/categoryHeader/categoryHeader';
import AnalysisQuestion from '../../../../steps/analysis/components/analysisQuestion/analysisQuestion';
import IntakeInfoModal from '../../../../../components/intakeInfoModal/intakeInfoModal';
import ModalComponent from '../../../../../components/modal/modal';
import nl2br from '../../../../../utilities/string/nl2br';
import usePublicProjectData from '../../../../../hooks/usePublicProjectData';
import useAnalysisQuestions from '../../../../../hooks/useAnalysisQuestions';

type QuestionAnswer = Record<string, number>;

interface PublicAnalysisQuestionsProps {
  onChange: (questionAnswer: QuestionAnswer) => void;
  answers: unknown;
}

function PublicAnalysisQuestions({
  onChange,
  answers,
}: PublicAnalysisQuestionsProps) {
  const { publicProject } = usePublicProjectData();
  const { questions: analysisQuestions } = useAnalysisQuestions(
    publicProject?.type
  );
  const [infoModalContent, setInfoModalContent] = useState<string>();

  const handleSaveQuestionAnswer = (
    // @ts-expect-error -- TODO
    categoryId,
    // @ts-expect-error -- TODO
    questionId,
    // @ts-expect-error -- TODO
    answer,
    // @ts-expect-error -- TODO
    subQuestionAnswer
  ) => {
    const questionAnswer: QuestionAnswer = {
      question_id: questionId,
      category_id: categoryId,
    };

    if (answer !== undefined && answer !== null) questionAnswer.score = answer;
    if (subQuestionAnswer !== undefined && subQuestionAnswer !== null)
      questionAnswer.subquestion_answer = subQuestionAnswer;
    onChange(questionAnswer);
  };

  return (
    <>
      {analysisQuestions && (
        <>
          {analysisQuestions.map((analysisQuestionsCategory, index) => (
            <div
              key={analysisQuestionsCategory.id}
              id={analysisQuestionsCategory.category}
            >
              <div className="row no-gutters">
                <div className="col-12">
                  <CategoryHeader
                    index={index}
                    category={analysisQuestionsCategory}
                  />
                </div>
              </div>
              <div className="container px-0 pt-3">
                {analysisQuestionsCategory.children.map((question) => (
                  <AnalysisQuestion
                    key={question.id}
                    question={question}
                    categoryId={analysisQuestionsCategory.id}
                    colors={analysisQuestionsCategory.colors}
                    onInfoModalClick={setInfoModalContent}
                    onSave={handleSaveQuestionAnswer}
                  />
                ))}
              </div>
            </div>
          ))}
          <IntakeInfoModal intakeQuestions={publicProject?.intake_questions} />
        </>
      )}
      {/* @ts-expect-error -- TODO */}
      <ModalComponent
        active={!!infoModalContent}
        onClose={() => setInfoModalContent(undefined)}
      >
        {infoModalContent && (
          <p
            dangerouslySetInnerHTML={{ __html: nl2br(infoModalContent) }}
            className="mb-0"
          />
        )}
      </ModalComponent>
    </>
  );
}

export default PublicAnalysisQuestions;
