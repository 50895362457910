import React, { useState } from 'react';
import ModalComponent from '../modal/modal';
import { Prompt, Redirect } from 'react-router-dom';

// based on https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
function ConfirmNavigationModal({
  shouldTriggerConfirmation,
  title,
  text,
  confirmLabel,
  cancelLabel,
  extraActions = [],
}) {
  const [showModal, setShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  function handleConfirmNavigationPrompt(nextLocation) {
    if (shouldTriggerConfirmation) {
      setShowModal(true);
      setNextLocation(nextLocation);
      return false;
    }
    return true;
  }

  function closeModal() {
    setShowModal(false);
  }

  if (confirmedNavigation && nextLocation) {
    return <Redirect to={nextLocation.pathname} />;
  }

  return (
    <>
      <Prompt message={handleConfirmNavigationPrompt} />
      <ModalComponent
        title={title}
        active={showModal}
        onClose={closeModal}
        actions={[
          {
            label: cancelLabel,
            onClick: closeModal,
            variant: 'warning',
          },
          {
            label: confirmLabel,
            onClick: () => setConfirmedNavigation(true),
          },
          ...extraActions.map((extraAction) => ({
            ...extraAction,
            onClick: () => extraAction.onClick(nextLocation.pathname),
          })),
        ]}
      >
        {text}
      </ModalComponent>
    </>
  );
}

export default ConfirmNavigationModal;
