import React, { useContext, useEffect, useRef } from 'react';
import useSunburstData from '../../../../../hooks/useSunburstData';
import useAnalysisQuestionsWithData from '../../../../../hooks/useAnalysisQuestionsWithData';
import { generatePath, Link, useParams } from 'react-router-dom';

import './sunburst.scss';

import { DistributionFilterContext } from '../../context/distributionFilterContext';
import { ROUTE_PROJECT_ANALYSIS } from '../../../../../constants/routes';
import { useTranslation } from 'react-i18next';
import useSunburst from '../../../../../hooks/useSunburst';
import useUpdateEffect from '../../../../../hooks/useUpdateEffect';
import useProjectData from '../../../../../hooks/useProjectData';

function Sunburst({
  handleZoomStart,
  handleZoomFinished,
  handleOpenModal,
  size,
  answerSize,
  interactive = true,
  labels = true,
}) {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const sunburstData = useSunburstData();
  const { project } = useProjectData(projectId);
  const {
    analysisQuestions,
    analysisQuestionsDataSet,
    analysisQuestionsAnsweredCount,
  } = useAnalysisQuestionsWithData(projectId);
  const sunburst = useSunburst({
    labels,
    interactive,
    size,
    answerCircleSize: answerSize,
    onOpenModal: handleOpenModal,
    onZoomStart: handleZoomStart,
    onZoomFinished: handleZoomFinished,
  });
  const { selectedDistributionTargetGroups } = useContext(
    DistributionFilterContext
  );
  const sunBurstRef = useRef(null);

  useEffect(() => {
    if (
      sunBurstRef.current &&
      sunburstData &&
      analysisQuestionsDataSet &&
      project &&
      !sunburst.sunburstInitialised
    ) {
      sunburst.initSunburst(
        sunBurstRef.current,
        sunburstData,
        analysisQuestions,
        project.type
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sunburstData, analysisQuestionsDataSet, project]);

  useUpdateEffect(() => {
    sunburst.setActiveDistributionTargetGroups(
      selectedDistributionTargetGroups
    );
  }, [selectedDistributionTargetGroups]);

  return (
    <>
      <span
        ref={sunBurstRef}
        id="sunburst"
        className={interactive ? 'interactive' : 'not_interactive'}
      />
      {analysisQuestionsAnsweredCount === 0 && (
        <div className="analysis-empty-notice">
          <p className="notice">
            !!!
            <br />
            {t('content.label.analysisEmpty')}
          </p>
          <Link
            to={generatePath(ROUTE_PROJECT_ANALYSIS, { projectId })}
            className="btn btn-primary"
          >
            {t('content.label.to')} {t('content.questionnaire.analysis.title')}
          </Link>
        </div>
      )}
    </>
  );
}

export default Sunburst;
