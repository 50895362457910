import axios from 'axios';

export default function enablePublicProject({ id, authToken, type, password }) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/project/${id}/enable_public`,
      { type, password },
      {
        validateStatus: (status) => status === 200,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((result) => result.data.result);
}
