import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import styles from './introSteps.module.scss';
import {
  PROJECT_STEP_ANALYSIS,
  PROJECT_STEP_DETAIL,
  PROJECT_STEP_DISTRIBUTION,
  PROJECT_STEP_NEW,
  PROJECT_STEP_RESULT,
} from '../../../../constants/projectSteps';

function ContextAwareToggle({ number, title, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div className={styles.head} onClick={decoratedOnClick}>
      <span className={styles.icon}>{number}</span>
      <div className={styles.headContent}>
        <h4 className={styles.title}>{title}</h4>
        <i
          className={cn('fas', {
            'fa-caret-up': isCurrentEventKey,
            'fa-caret-down': !isCurrentEventKey,
          })}
        />
      </div>
    </div>
  );
}

function IntroSteps() {
  const { t } = useTranslation();

  return (
    <Accordion className={styles.introSteps}>
      {[
        PROJECT_STEP_NEW,
        PROJECT_STEP_ANALYSIS,
        PROJECT_STEP_DISTRIBUTION,
        PROJECT_STEP_RESULT,
        PROJECT_STEP_DETAIL,
      ].map((step, i) => {
        const x = i + 1;
        return (
          <Card key={i} className={cn(styles.introStep, styles[step])}>
            <ContextAwareToggle
              eventKey={i.toString()}
              number={x}
              title={t(`introSteps_${x}_title`)}
            />
            <Accordion.Collapse eventKey={i.toString()}>
              <div className={styles.content}>{t(`introSteps_${x}_text`)}</div>
            </Accordion.Collapse>
          </Card>
        );
      })}
    </Accordion>
  );
}

export default IntroSteps;
