import React, { useEffect, useRef, useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../context/auth';
import downloadProjectReport from '../../../../../queries/downloadProjectReport';
import { useParams } from 'react-router-dom';
import useResponseExceptionHandler from '../../../../../hooks/useResponseExceptionHandler';
import ModalComponent from '../../../../../components/modal/modal';
import useSunburstData from '../../../../../hooks/useSunburstData';
import useAnalysisQuestionsWithData from '../../../../../hooks/useAnalysisQuestionsWithData';
import useSunburst from '../../../../../hooks/useSunburst';
import Loader from '../../../../../components/loader/loader';
import useProjectData from '../../../../../hooks/useProjectData';

function DownloadReportModal({ onClose }) {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { authToken } = useAuth();
  const handleResponseException = useResponseExceptionHandler();
  const sunburstData = useSunburstData();
  const { project } = useProjectData(projectId);
  const { analysisQuestions, analysisQuestionsDataSet } =
    useAnalysisQuestionsWithData(projectId);
  const sunburst = useSunburst({
    labels: true,
    interactive: false,
    size: 505,
    answerCircleSize: 305,
    meantForDownload: true,
  });
  const [alert, setAlert] = useState({
    active: false,
  });
  const sunburstContainerRef = useRef();

  function closeAlert() {
    setAlert({
      active: false,
    });
  }

  useEffect(() => {
    if (
      sunburstData &&
      analysisQuestionsDataSet &&
      project &&
      !sunburst.sunburstInitialised
    ) {
      sunburst.initSunburst(
        sunburstContainerRef.current,
        sunburstData,
        analysisQuestions,
        project.type
      );
      sunburst.getPngImage((sunburstImage) => {
        const formData = new FormData();
        formData.append('sunburstImage', sunburstImage);
        downloadProjectReport({ authToken, id: projectId, formData })
          .then(handleDownloadResponse)
          .catch((e) => {
            const error = handleResponseException(e);
            setAlert({
              message: error,
              error: true,
              active: true,
            });
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sunburstData, analysisQuestionsDataSet, project]);

  function handleDownloadResponse(response) {
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'report.pdf';
    const filenameMatches = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    );
    if (filenameMatches != null && filenameMatches[1]) {
      filename = filenameMatches[1].replace(/['"]/g, '');
    }

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    if (navigator.msSaveBlob) {
      // For IE
      navigator.msSaveBlob(response.data, filename);
    } else {
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    }
    setAlert({
      message: t('content.label.printSuccess'),
      error: false,
      active: true,
    });
  }

  return (
    <ModalComponent
      active
      title="downloading"
      onClose={alert.active ? onClose : null}
    >
      <p>
        Your report is being created and will soon start to download, please
        wait.
      </p>
      {!alert.active && <Loader />}
      {alert.active && (
        <Alert
          variant={alert.error ? 'danger' : 'success'}
          onClose={closeAlert}
          dismissible
          className="mb-1"
        >
          <p className="mb-0">{alert.message}</p>
        </Alert>
      )}
      <div ref={sunburstContainerRef} style={{ display: 'none' }} />
    </ModalComponent>
  );
}

function DownloadReportButton() {
  const { t } = useTranslation();
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  function downloadReport() {
    setIsDownloadingReport(true);
  }

  function handleCloseDownloadModal() {
    setIsDownloadingReport(false);
  }

  return (
    <>
      {isDownloadingReport && (
        <DownloadReportModal onClose={handleCloseDownloadModal} />
      )}
      <Button
        variant="primary"
        onClick={downloadReport}
        disabled={isDownloadingReport}
      >
        {isDownloadingReport
          ? t('content.label.downloading')
          : t('content.label.print')}
      </Button>
    </>
  );
}

export default DownloadReportButton;
