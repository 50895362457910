import { useCallback, useEffect, useRef, useState } from 'react';

import useIntakeQuestions, { IntakeQuestionJSON } from './useIntakeQuestions';
import useResponseExceptionHandler from './useResponseExceptionHandler';
import { useQuery } from 'react-query';
import getPublicProject from '../queries/getPublicProject';
import { useTranslation } from 'react-i18next';
import useStore, { selectAuthToken } from 'src/hooks/useStore';

type IntakeQuestion = {
  id: number;
  project_id: number;
  question_id: number;
  answer: string;
  subquestion_answer: null;
};

type Project<T = IntakeQuestion[]> = {
  title: string;
  type: ProjectType;
  intake_questions: T;
};

type ProjectJSON = Project<IntakeQuestionJSON[]>;

export default function usePublicProjectData() {
  const { i18n } = useTranslation();
  const currentLanguageRef = useRef(i18n.language);
  const [project, setProject] = useState<ProjectJSON | null>(null);
  const [error, setError] = useState(null);
  const { authToken } = useStore(selectAuthToken);
  const {
    isLoading,
    isError,
    data,
    error: queryError,
  } = useQuery<Project>(['publicProject', { authToken }], getPublicProject);
  const { questions: intakeQuestions } = useIntakeQuestions(data?.type);
  const handleResponseException = useResponseExceptionHandler(true);

  const handleError = useCallback(
    (e: unknown) => {
      const error = handleResponseException(e);
      setError(error);
    },
    [setError, handleResponseException]
  );

  useEffect(() => {
    if (isError && queryError) {
      handleError(queryError);
    } else if (error) {
      setError(null);
    }
  }, [error, isError, queryError, handleError]);

  useEffect(() => {
    if (data && intakeQuestions) {
      setProject({
        ...data,
        intake_questions: intakeQuestions.map((intakeQuestion) => {
          const backendQuestion = data.intake_questions.find(
            (backendQuestion: IntakeQuestion) =>
              backendQuestion.question_id === intakeQuestion.id
          );
          return {
            ...intakeQuestion,
            answer: backendQuestion ? backendQuestion.answer : '',
          };
        }),
      });
      currentLanguageRef.current = i18n.language;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, i18n.language]);

  return {
    publicProject: project,
    isLoading,
    error,
  };
}
