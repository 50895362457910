import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import axios from 'axios';

import logo from '../../assets/images/logo/logo.svg';
import logoGreen from '../../assets/images/logo/logo-green.svg';
import logoLightGreen from '../../assets/images/logo/logo-light-green.svg';
import logoPurple from '../../assets/images/logo/logo-purple.svg';
import logoRed from '../../assets/images/logo/logo-red.svg';

import styles from './header.module.scss';
import { Link, useLocation, matchPath, NavLink } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import {
  ROUTE_ADVICE_AND_TRAINING,
  ROUTE_CONTACT,
  ROUTE_FAQ,
  ROUTE_HOME,
  ROUTE_PROJECT_ANALYSIS,
  ROUTE_PROJECT_BASE,
  ROUTE_PROJECT_DETAIL,
  ROUTE_PROJECT_DISTRIBUTION,
  ROUTE_PROJECT_INTAKE,
  ROUTE_PROJECT_RESULT,
  ROUTE_PROJECTS,
} from '../../constants/routes';
import ProjectProgress from '../projectProgress/projectProgress';
import HomeBtn from '../homeBtn/homeBtn';
import HeaderBorder from './headerBorder';
import {
  PROJECT_STEP_ANALYSIS,
  PROJECT_STEP_DETAIL,
  PROJECT_STEP_DISTRIBUTION,
  PROJECT_STEP_NEW,
  PROJECT_STEP_RESULT,
} from '../../constants/projectSteps';

function getProjectLogo(step) {
  switch (step) {
    case PROJECT_STEP_ANALYSIS:
      return logoPurple;
    case PROJECT_STEP_DISTRIBUTION:
      return logoRed;
    case PROJECT_STEP_RESULT:
      return logoGreen;
    case PROJECT_STEP_DETAIL:
      return logoLightGreen;
    default:
      return logo;
  }
}

function getProjectTitle(step, t) {
  switch (step) {
    case PROJECT_STEP_NEW:
      return t('content.project.edit');
    case PROJECT_STEP_ANALYSIS:
      return t('content.questionnaire.analysis.title');
    case PROJECT_STEP_DISTRIBUTION:
      return t('content.questionnaire.distribution.title');
    case PROJECT_STEP_RESULT:
      return t('content.result.title');
    case PROJECT_STEP_DETAIL:
      return t('content.project.detail');
    default:
      return t('content.label.title');
  }
}

function Header() {
  const { t, i18n } = useTranslation();
  const { authToken, setAuthToken } = useAuth();
  const [projectStep, setProjectStep] = useState(null);
  const location = useLocation();
  const matchedProjectRoute = matchPath(location.pathname, {
    path: ROUTE_PROJECT_BASE,
  });
  const projectId = matchedProjectRoute?.params?.projectId;

  useEffect(() => {
    let currentProjectStep = null;
    if (matchPath(location.pathname, { path: ROUTE_PROJECT_ANALYSIS })) {
      currentProjectStep = PROJECT_STEP_ANALYSIS;
    } else if (
      matchPath(location.pathname, { path: ROUTE_PROJECT_DISTRIBUTION })
    ) {
      currentProjectStep = PROJECT_STEP_DISTRIBUTION;
    } else if (matchPath(location.pathname, { path: ROUTE_PROJECT_RESULT })) {
      currentProjectStep = PROJECT_STEP_RESULT;
    } else if (matchPath(location.pathname, { path: ROUTE_PROJECT_DETAIL })) {
      currentProjectStep = PROJECT_STEP_DETAIL;
    } else if (matchPath(location.pathname, { path: ROUTE_PROJECT_INTAKE })) {
      currentProjectStep = PROJECT_STEP_NEW;
    }
    setProjectStep(currentProjectStep);
  }, [location.pathname]);

  function changeLanguage(language) {
    if (i18n.languages[0] !== language) {
      i18n.changeLanguage(language);
    }
  }

  function handleLogout() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        {
          validateStatus: (status) => status === 200,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then(() => setAuthToken(null));
  }

  const headerLogo = getProjectLogo(projectStep);
  const headerTitle = getProjectTitle(projectStep, t);

  const nlButtonClasses = cn('nav-link btn btn-link', {
    [styles.active]: i18n.languages[0] === 'nl',
  });
  const enButtonClasses = cn('nav-link btn btn-link', {
    [styles.active]: i18n.languages[0] === 'en',
  });
  const headerClassNames = cn('navbar fixed-top', styles.header, {
    [styles.inProject]: !!projectId,
  });

  return (
    <nav className={headerClassNames}>
      <div className={`container ${styles.content}`}>
        <div className="row no-gutters w-100">
          <div className="col-6 my-2 d-flex align-items-center">
            <div className={`${styles.logo} float-left`}>
              <Link to={authToken ? ROUTE_PROJECTS : ROUTE_HOME}>
                <img
                  src={headerLogo}
                  width={62}
                  height={62}
                  alt="InterventieKompas"
                />
              </Link>
            </div>
            <div>
              <h1>{headerTitle}</h1>
              <h3>{t('content.label.subtitle')}</h3>
            </div>
          </div>
          <div className="col-6">
            <div
              className={`${styles.headerButtons} justify-content-end d-flex align-items-center`}
            >
              {authToken && (
                <button
                  className="nav-link btn btn-link"
                  onClick={handleLogout}
                >
                  {t('content.menu.logout')}
                </button>
              )}
              <NavLink
                to={ROUTE_FAQ}
                className="nav-link btn btn-link"
                activeClassName={styles.active}
              >
                {t('content.faq.title')}
              </NavLink>
              <NavLink
                to={ROUTE_ADVICE_AND_TRAINING}
                className="nav-link btn btn-link"
                activeClassName={styles.active}
              >
                {t('adviceAndTraining_title')}
              </NavLink>
              <NavLink
                to={ROUTE_CONTACT}
                className="nav-link btn btn-link"
                activeClassName={styles.active}
              >
                {t('contact_title')}
              </NavLink>
              <div className={`d-flex ${styles.languageButtons}`}>
                <button
                  type="button"
                  className={nlButtonClasses}
                  onClick={() => changeLanguage('nl')}
                >
                  NL
                </button>
                <button
                  type="button"
                  className={enButtonClasses}
                  onClick={() => changeLanguage('en')}
                >
                  EN
                </button>
              </div>
            </div>
            {projectId && (
              <div className="row w-100 mt-n3">
                <div className="col-12 justify-content-end align-items-end d-flex">
                  <div className={styles.homeBtnContainer}>
                    <HomeBtn />
                  </div>
                  <ProjectProgress
                    projectId={projectId}
                    newActive={projectStep === PROJECT_STEP_NEW}
                    analysisActive={projectStep === PROJECT_STEP_ANALYSIS}
                    distributionActive={
                      projectStep === PROJECT_STEP_DISTRIBUTION
                    }
                    resultActive={projectStep === PROJECT_STEP_RESULT}
                    detailActive={projectStep === PROJECT_STEP_DETAIL}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {projectId && (
        <HeaderBorder projectStep={projectStep} projectId={projectId} />
      )}
    </nav>
  );
}

export default Header;
