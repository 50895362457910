import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import useResponseExceptionHandler from './useResponseExceptionHandler';
import getPublicAnalysis from '../queries/getPublicAnalysis';
import { useAuth } from '../context/auth';

function usePublicAnalysisAnswers(projectId) {
  const [error, setError] = useState(null);
  const handleResponseException = useResponseExceptionHandler();
  const { authToken } = useAuth();
  const {
    isLoading,
    isError,
    data,
    error: queryError,
  } = useQuery(
    ['projectPublicAnalysis', { id: projectId, authToken }],
    getPublicAnalysis,
    {
      refetchInterval: 60000,
    }
  );

  const handleError = useCallback(
    (e) => {
      const error = handleResponseException(e);
      setError(error);
    },
    [setError, handleResponseException]
  );

  useEffect(() => {
    if (isError && queryError) {
      handleError(queryError);
    } else if (error) {
      setError(null);
    }
  }, [error, isError, queryError, handleError]);

  return {
    isLoading,
    publicAnalysisAnswers: data,
    error,
  };
}

export default usePublicAnalysisAnswers;
