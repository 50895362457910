import { useCallback, useEffect, useState } from 'react';

import useResponseExceptionHandler from './useResponseExceptionHandler';
import { useQuery } from 'react-query';
import getPublicProjectPublicInfo from '../queries/getPublicProjectPublicInfo';
import { useTranslation } from 'react-i18next';

export default function usePublicProjectInfo(publicId, type) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const handleResponseException = useResponseExceptionHandler(true);
  const {
    isLoading,
    isError,
    data,
    error: queryError,
  } = useQuery(
    ['publicProjectInfo', { publicId, type }],
    getPublicProjectPublicInfo
  );

  const handleError = useCallback(
    (e) => {
      const error = handleResponseException(e, {
        default: t('error.publicProject.failedFetch'),
      });
      setError(error);
    },
    [t, setError, handleResponseException]
  );

  useEffect(() => {
    if (isError && queryError) {
      handleError(queryError);
    } else if (error) {
      setError(null);
    }
  }, [error, isError, queryError, handleError]);

  return {
    publicProjectInfo: data,
    isLoading,
    error,
  };
}
