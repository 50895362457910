import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ROUTE_CONDITIONS,
  ROUTE_CONTACT,
  ROUTE_COPYRIGHT,
  ROUTE_DISCLAIMER,
  ROUTE_FAQ,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_HOME,
  ROUTE_PRIVACY,
  ROUTE_PROJECT_ANALYSIS,
  ROUTE_PROJECT_ANALYSIS_INTRO,
  ROUTE_PROJECT_ANALYSIS_RESULT,
  ROUTE_PROJECT_DETAIL,
  ROUTE_PROJECT_DISTRIBUTION,
  ROUTE_PROJECT_DISTRIBUTION_INTRO,
  ROUTE_PROJECT_DISTRIBUTION_RESULT,
  ROUTE_PROJECT_INTAKE,
  ROUTE_PROJECT_RESULT,
  ROUTE_PROJECTS,
  ROUTE_REGISTER,
  ROUTE_RESET_PASSWORD,
  ROUTE_ADVICE_AND_TRAINING,
  ROUTE_ACCESSIBILITY,
} from './constants/routes';

import AuthContextProvider from './context/auth';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import Header from './components/header/header';
import Register from './pages/register/register';
import ForgotPassword from './pages/forgotPassword/forgotPassword';
import ResetPassword from './pages/resetPassword/resetPassword';
import Faqs from './pages/faqs/faqs';
import PrivateRoute from './components/privateRoute/privateRoute';
import Detail from './pages/steps/detail/detail';
import Result from './pages/steps/result/result';
import DistributionResult from './pages/steps/distribution/distributionResult';
import DistributionIntro from './pages/steps/distribution/distributionIntro';
import Distribution from './pages/steps/distribution/distribution';
import AnalysisResult from './pages/steps/analysis/analysisResult';
import AnalysisIntro from './pages/steps/analysis/analysisIntro';
import Analysis from './pages/steps/analysis/analysis';
import ProjectIntake from './pages/steps/projectIntake/projectIntake';
import Projects from './pages/projects/projects';
import Home from './pages/home/home';
import Content from './components/content/content';
import Conditions from './pages/conditions/conditions';
import Disclaimer from './pages/disclaimer/disclaimer';
import Copyright from './pages/copyright/copyright';
import Privacy from './pages/privacy/privacy';
import Contact from './pages/contact/contact';
import AdviceAndTraining from './pages/adviceAndTraining/adviceAndTraining';
import Accessibility from './pages/accessibility/accessibility';

function DefaultApp() {
  return (
    <AuthContextProvider>
      <ScrollToTop />
      <Header />
      <Content>
        <Switch>
          <Route path={ROUTE_REGISTER}>
            <Register />
          </Route>
          <Route path={ROUTE_FORGOT_PASSWORD}>
            <ForgotPassword />
          </Route>
          <Route path={ROUTE_RESET_PASSWORD}>
            <ResetPassword />
          </Route>
          <Route path={ROUTE_FAQ}>
            <Faqs />
          </Route>
          <Route path={ROUTE_CONDITIONS}>
            <Conditions />
          </Route>
          <Route path={ROUTE_DISCLAIMER}>
            <Disclaimer />
          </Route>
          <Route path={ROUTE_COPYRIGHT}>
            <Copyright />
          </Route>
          <Route path={ROUTE_PRIVACY}>
            <Privacy />
          </Route>
          <Route path={ROUTE_CONTACT}>
            <Contact />
          </Route>
          <Route path={ROUTE_ADVICE_AND_TRAINING}>
            <AdviceAndTraining />
          </Route>
          <Route path={ROUTE_ACCESSIBILITY}>
            <Accessibility />
          </Route>
          <PrivateRoute path={ROUTE_PROJECT_DETAIL}>
            <Detail />
          </PrivateRoute>
          <PrivateRoute path={ROUTE_PROJECT_RESULT}>
            <Result />
          </PrivateRoute>
          <PrivateRoute path={ROUTE_PROJECT_DISTRIBUTION_RESULT}>
            <DistributionResult />
          </PrivateRoute>
          <PrivateRoute path={ROUTE_PROJECT_DISTRIBUTION_INTRO}>
            <DistributionIntro />
          </PrivateRoute>
          <PrivateRoute path={ROUTE_PROJECT_DISTRIBUTION}>
            <Distribution />
          </PrivateRoute>
          <PrivateRoute path={ROUTE_PROJECT_ANALYSIS_RESULT}>
            <AnalysisResult />
          </PrivateRoute>
          <PrivateRoute path={ROUTE_PROJECT_ANALYSIS_INTRO}>
            <AnalysisIntro />
          </PrivateRoute>
          <PrivateRoute path={ROUTE_PROJECT_ANALYSIS}>
            <Analysis />
          </PrivateRoute>
          <PrivateRoute path={ROUTE_PROJECT_INTAKE}>
            <ProjectIntake />
          </PrivateRoute>
          <PrivateRoute path={ROUTE_PROJECTS}>
            <Projects />
          </PrivateRoute>
          <Route path={ROUTE_HOME}>
            <Home />
          </Route>
        </Switch>
      </Content>
    </AuthContextProvider>
  );
}

export default DefaultApp;
