import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import nl2br from '../../../../../utilities/string/nl2br';

import CloseBtn from '../../../../../components/closeBtn/closeBtn';

import styles from './sunburstModal.module.scss';
import { Button } from 'react-bootstrap';

function SunburstModal({
  active,
  color,
  title,
  type,
  onClose,
  content,
  showExamplesButton,
  onShowExamples,
}) {
  const { t } = useTranslation();
  return (
    <Modal
      show={active}
      onHide={onClose}
      size="md"
      className={styles.sunburstModal}
    >
      <Modal.Header
        className={styles.header}
        closeButton={false}
        style={{ backgroundColor: color }}
      >
        <div>
          {type && (
            <h5 className={styles.type}>{t(`content.label.${type}`)}:</h5>
          )}
          <Modal.Title className={styles.title}>{t(title)}</Modal.Title>
        </div>
        <CloseBtn onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <p dangerouslySetInnerHTML={{ __html: nl2br(t(content)) }} />
      </Modal.Body>
      {showExamplesButton && (
        <Modal.Footer className={styles.footer}>
          <Button variant="primary" onClick={onShowExamples}>
            {t('content.label.showExamples')}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default SunburstModal;
