import { useTranslation } from 'react-i18next';

import IntakeQuestionsEN from '../content/download/EN/intake.json';
import IntakeQuestionsNL from '../content/download/NL/intake.json';

export type IntakeQuestionJSON = {
  id: number;
  question: string;
  info?: string;
  mandatory: boolean;
  helpQuestions: never[];
  rows: number;
  type: ProjectType;
};

function useIntakeQuestions(type?: ProjectType) {
  const { i18n } = useTranslation();

  let questions: IntakeQuestionJSON[] = IntakeQuestionsNL;
  if (i18n.language === 'en') {
    questions = IntakeQuestionsEN;
  }

  const questionsByType = questions.filter((question) =>
    ['pre', 'post', type].includes(question.type)
  );
  return {
    questionCount: questionsByType.length,
    questions: questionsByType,
  };
}

export default useIntakeQuestions;
