import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './detail.module.scss';
import StepIndicator from '../../../components/stepIndicator/stepIndicator';
import ProjectProgress from '../../../components/projectProgress/projectProgress';
import useProjectData from '../../../hooks/useProjectData';
import { HashLink } from 'react-router-hash-link';
import { generatePath, Link, useParams } from 'react-router-dom';
import useAnalysisQuestionsWithData from '../../../hooks/useAnalysisQuestionsWithData';
import dayjs from 'dayjs';
import {
  ROUTE_PROJECT_ANALYSIS,
  ROUTE_PROJECT_DISTRIBUTION,
  ROUTE_PROJECT_INTAKE,
  ROUTE_PROJECT_RESULT,
  ROUTE_PROJECTS,
} from '../../../constants/routes';
import Sunburst from '../result/components/sunburst/sunburst';
import useDistribution from '../../../hooks/useDistribution';
import CheckboxWithLabel from '../../../components/checkboxWithLabel/checkboxWithLabel';
import {
  COLOR_TARGET_GROUP_CALCULATING,
  COLOR_TARGET_GROUP_OPPORTUNITY,
  COLOR_TARGET_GROUP_PRINCIPLED,
  COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER,
} from '../../../constants/colors';
import DistributionResultBar from './components/distributionResultBar/distributionResultBar';
import ExampleStack from '../../../components/exampleStack/exampleStack';
import useInterventions from '../../../hooks/useInterventions';
import nl2br from '../../../utilities/string/nl2br';
import DownloadReportButton from './components/downloadReportButton/downloadReportButton';
import {
  PROJECT_STEP_ANALYSIS,
  PROJECT_STEP_DISTRIBUTION,
  PROJECT_STEP_NEW,
  PROJECT_STEP_RESULT,
} from '../../../constants/projectSteps';
import FinishButton from '../../../components/finishButton/finishButton';

function Detail() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { project } = useProjectData(projectId);
  const {
    analysisQuestions,
    analysisQuestionsAnsweredCount,
    analysisQuestionCount,
  } = useAnalysisQuestionsWithData(projectId);
  const { distribution } = useDistribution(projectId);
  const { interventions } = useInterventions();

  function scrollWithOffset(el) {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -121; // header height
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  function getTop3Offenders(distribution) {
    if (!distribution) return [];

    return [
      {
        label: t('content.distribution.unintentionalOffender'),
        value: distribution.unintentionalOffender,
        color: COLOR_TARGET_GROUP_UNINTENTIONAL_OFFENDER,
      },
      {
        label: t('content.distribution.opportunity'),
        value: distribution.opportunity,
        color: COLOR_TARGET_GROUP_OPPORTUNITY,
      },
      {
        label: t('content.distribution.principled'),
        value: distribution.principled,
        color: COLOR_TARGET_GROUP_PRINCIPLED,
      },
      {
        label: t('content.distribution.calculating'),
        value: distribution.calculating,
        color: COLOR_TARGET_GROUP_CALCULATING,
      },
    ]
      .sort((a, b) => b.value - a.value)
      .slice(0, 3);
  }

  const top3Offenders = getTop3Offenders(distribution);

  function getSavedExamplesGroupedByIntervention(savedExamples, interventions) {
    if (!savedExamples || !interventions) {
      return [];
    }
    const savedExamplesInterventionIds = savedExamples.map(
      (example) => example.intervention_id
    );
    return [
      ...interventions.filter((intervention) =>
        savedExamplesInterventionIds.includes(intervention.id)
      ),
    ].map((intervention) => {
      return {
        ...intervention,
        savedExampleIds: savedExamples
          .filter((example) => example.intervention_id === intervention.id)
          .map((example) => example.example_id),
      };
    });
  }

  const savedExamplesGroupedByIntervention = useMemo(
    () =>
      getSavedExamplesGroupedByIntervention(project?.examples, interventions),
    [project?.examples, interventions]
  );

  return (
    <div className="container">
      <div className="row">
        <div className={`col-4 pt-3 ${styles.column}`}>
          <div className="mt-3">
            <h2 className="mb-3">{t('content.label.print_title')}</h2>
            <div>
              <DownloadReportButton />
            </div>
          </div>

          <div className={`mt-5 ${styles.title}`}>
            <StepIndicator active type={PROJECT_STEP_NEW} />
            <h2>{t('content.label.project')}</h2>
          </div>
          <p className="mb-0">{t('content.label.project')}:</p>
          <h4>{project?.title}</h4>
          <p className="mb-0">{t('content.project.meta')}:</p>
          <h4
            dangerouslySetInnerHTML={{ __html: nl2br(project?.metadata || '') }}
          />
          <p className="mb-0">{t('content.project.notes')}:</p>
          <h4
            dangerouslySetInnerHTML={{ __html: nl2br(project?.notes || '') }}
          />
          <p className="mb-0">{t('content.project.startdate')}:</p>
          <h4>
            {project?.startdate &&
              dayjs(project?.startdate).format('MMMM YYYY')}
          </h4>
          <div className="my-3">
            <ProjectProgress
              projectId={projectId}
              newActive
              analysisActive={
                analysisQuestionCount > 0 &&
                analysisQuestionsAnsweredCount >= analysisQuestionCount
              }
              distributionActive={project?.distribution_count > 0}
              resultActive={project?.zoomed}
              detailActive={project?.finished}
            />
          </div>

          <div className="mb-2">
            <Link
              to={generatePath(ROUTE_PROJECT_INTAKE, { projectId })}
              className="btn btn-primary"
            >
              {t('content.label.edit')}
            </Link>
          </div>
        </div>
        <div className={`col-4 pt-3 ${styles.column}`}>
          <div className={styles.title}>
            <StepIndicator active type={PROJECT_STEP_ANALYSIS} />
            <h2>{t('content.questionnaire.analysis.title')}</h2>
          </div>
          <div className="mb-3">
            {analysisQuestions?.map((category) => (
              <HashLink
                key={category.id}
                smooth
                scroll={(el) => scrollWithOffset(el)}
                className="btn text-left btn-block text-uppercase text-white font-weight-bold"
                to={`${generatePath(ROUTE_PROJECT_ANALYSIS, { projectId })}#${
                  category.category
                }`}
                style={{
                  backgroundColor: category.colors[2],
                }}
              >
                {category.name}
              </HashLink>
            ))}
          </div>
          <Link
            to={generatePath(ROUTE_PROJECT_ANALYSIS, { projectId })}
            className="btn btn-primary"
          >
            {t('content.label.to')} {t('content.questionnaire.analysis.title')}
          </Link>
          <div className={`mt-5 ${styles.title}`}>
            <StepIndicator active type={PROJECT_STEP_DISTRIBUTION} />
            <h2>{t('content.questionnaire.distribution.title')}</h2>
          </div>
          <p className="mb-0">{t('content.label.top3offenders')}:</p>
          {top3Offenders.map((offender, i) => (
            <CheckboxWithLabel
              key={i}
              label={`${offender.label} (${offender.value}%)`}
              checked
              checkboxColor={offender.color}
            />
          ))}
          <div className="my-3">
            <DistributionResultBar />
          </div>

          <Link
            to={generatePath(ROUTE_PROJECT_DISTRIBUTION, { projectId })}
            className="btn btn-primary"
          >
            {t('content.label.to')}{' '}
            {t('content.questionnaire.distribution.title')}
          </Link>
        </div>
        <div className={`col-4 pt-3 ${styles.column}`}>
          <div className={styles.title}>
            <StepIndicator active type={PROJECT_STEP_RESULT} />
            <h2>{t('content.result.title')}</h2>
          </div>
          <div className={styles.sunburstContainer}>
            <Sunburst
              size={350}
              answerSize={215}
              interactive={false}
              labels={false}
            />
          </div>

          <Link
            to={generatePath(ROUTE_PROJECT_RESULT, { projectId })}
            className="btn btn-primary"
          >
            {t('content.label.to')} {t('content.result.title')}
          </Link>

          <div className="mt-5 mb-1">
            <h2>{t('content.label.savedIdeas')}</h2>
          </div>
          <div className={styles.savedExamples}>
            {savedExamplesGroupedByIntervention.map((intervention) => (
              <div key={intervention.id}>
                <p className="mb-0">{t('content.label.intervention')}:</p>
                <h4>{intervention.name}</h4>
                <div className={`mt-3 mb-3 ${styles.exampleStackContainer}`}>
                  <ExampleStack
                    exampleIds={intervention.savedExampleIds}
                    categoryColors={intervention.colors}
                    savedExampleIds={intervention.savedExampleIds}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-end">
            <FinishButton to={generatePath(ROUTE_PROJECTS)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Detail;
