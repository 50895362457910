import React from 'react';

import styles from './loader.module.scss';
import { useTranslation } from 'react-i18next';

function SaveLoader() {
  const { t } = useTranslation();
  return (
    <div className={styles.saveLoader}>
      <div className={styles.loaderImage} />
      <p>{t('content.label.saving')}</p>
    </div>
  );
}

export default SaveLoader;
