import axios from 'axios';

export default function savePublicDistribution({ authToken, data }) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/public/project/distribution`,
      data,
      {
        validateStatus: (status) => status === 201,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((result) => result.data.result);
}
