import React, { useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { generatePath, Link, Redirect, useHistory } from 'react-router-dom';
import { ReCaptcha } from 'react-recaptcha-v3';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import cn from 'classnames';

import { ROUTE_CONDITIONS, ROUTE_HOME } from '../../constants/routes';

import emailValidator from '../../validators/emailValidator';
import passwordValidator from '../../validators/passwordValidator';
import FixedLoader from '../../components/loader/fixedLoader';
import Errors from '../../components/errors/errors';
import PersonalInformationModal from './components/personalInformationModal/personalInformationModal';
import useResponseExceptionHandler from '../../hooks/useResponseExceptionHandler';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import ContentPage from '../../components/contentPage/contentPage';
import ColumnContent from '../../components/columnContent/columnContent';
import BackButton from '../../components/backButton/backButton';
import Footer from '../../components/footer/footer';

function Register() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, watch, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [globalErrors, setGlobalErrors] = useState(null);
  const [showPersonalInformationModal, setShowPersonalInformationModal] =
    useState(false);
  const recaptchaToken = useRef(null);
  const recaptchaRef = useRef(null);
  const handleResponseException = useResponseExceptionHandler();

  function verifyCallback(token) {
    recaptchaToken.current = token;
  }

  function updateRecaptchaToken() {
    // you will get a new token in verifyCallback
    recaptchaRef.current.execute();
  }

  function handleResponseError(exception) {
    setIsLoading(false);

    const error = handleResponseException(exception, {
      'timeout-or-duplicate': t('error.register.recaptchaExpired'),
      default: t('error.generic.failed'),
    });
    setGlobalErrors([error]);
  }

  function handleRegisterSubmit(data) {
    updateRecaptchaToken();
    setIsLoading(true);
    setGlobalErrors(null);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user`,
        {
          ...data,
          password_confirmation: data.passwordRepeat,
          email_confirmation: data.emailRepeat,
          language: i18n.languages[0],
          'g-recaptcha-response': recaptchaToken.current,
        },
        {
          validateStatus: (status) => status === 201,
        }
      )
      .then((response) => {
        setIsLoading(false);
        setIsRegistered(true);
      })
      .catch((e) => {
        updateRecaptchaToken();
        handleResponseError(e);
      });
  }

  function navigateToHome() {
    history.push(generatePath(ROUTE_HOME));
  }

  if (isRegistered) {
    return (
      <Redirect
        to={{
          pathname: ROUTE_HOME,
          state: { isNewlyRegistered: true },
        }}
      />
    );
  }

  function handleShowPersonalInformationModal() {
    setShowPersonalInformationModal(true);
  }

  function handleHidePersonalInformationModal() {
    setShowPersonalInformationModal(false);
  }

  return (
    <>
      <ContentPage>
        <ContentImageBackground />
        <ColumnContent>
          <div className="col-8 mx-auto">
            <h1>{t('content.register.createAccount')}</h1>
            {globalErrors && <Errors errors={globalErrors} />}
            {isLoading && <FixedLoader />}
            <form onSubmit={handleSubmit(handleRegisterSubmit)}>
              <div className="form-group">
                <label>{t('content.login.email')}</label>
                <input
                  type="email"
                  name="email"
                  ref={register({
                    required: t('validation.required'),
                    validate: (value) =>
                      emailValidator(value) || t('error.register.invalidEmail'),
                  })}
                  className={cn('form-control', { 'is-invalid': errors.email })}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email.message}</div>
                )}
              </div>
              <div className="form-group">
                <label>{t('content.register.emailRepeat')}</label>
                <input
                  type="email"
                  name="emailRepeat"
                  ref={register({
                    required: t('validation.required'),
                    validate: (value) =>
                      watch('email') === value ||
                      t('error.register.invalidEmailRepeat'),
                  })}
                  className={cn('form-control', {
                    'is-invalid': errors.emailRepeat,
                  })}
                />
                {errors.emailRepeat && (
                  <div className="invalid-feedback">
                    {errors.emailRepeat.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t('content.register.firstname')}</label>
                <input
                  name="firstname"
                  ref={register({
                    required: t('validation.required'),
                  })}
                  className={cn('form-control', {
                    'is-invalid': errors.firstname,
                  })}
                />
                {errors.firstname && (
                  <div className="invalid-feedback">
                    {errors.firstname.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t('content.register.lastname')}</label>
                <input
                  name="lastname"
                  ref={register({
                    required: t('validation.required'),
                  })}
                  className={cn('form-control', {
                    'is-invalid': errors.lastname,
                  })}
                />
                {errors.lastname && (
                  <div className="invalid-feedback">
                    {errors.lastname.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t('content.register.organisation')}</label>
                <input
                  name="organisation"
                  ref={register}
                  className={cn('form-control', {
                    'is-invalid': errors.organisation,
                  })}
                />
              </div>
              <div className="form-group">
                <label>{t('content.register.position')}</label>
                <input
                  name="position"
                  ref={register}
                  className={cn('form-control', {
                    'is-invalid': errors.position,
                  })}
                />
              </div>
              <div className="form-group">
                <label>{t('content.login.password')}</label>
                <input
                  type="password"
                  name="password"
                  ref={register({
                    required: t('validation.required'),
                    validate: (value) =>
                      passwordValidator(value) ||
                      t('error.register.invalidPassword'),
                  })}
                  className={cn('form-control', {
                    'is-invalid': errors.password,
                  })}
                />
                {errors.password && (
                  <div className="invalid-feedback">
                    {errors.password.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t('content.register.passwordRepeat')}</label>
                <input
                  type="password"
                  name="passwordRepeat"
                  ref={register({
                    required: t('validation.required'),
                    validate: (value) =>
                      watch('password') === value ||
                      t('error.register.invalidPasswordRepeat'),
                  })}
                  className={cn('form-control', {
                    'is-invalid': errors.passwordRepeat,
                  })}
                />
                {errors.passwordRepeat && (
                  <div className="invalid-feedback">
                    {errors.passwordRepeat.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input
                    name="conditions"
                    type="checkbox"
                    ref={register({
                      required: t('error.register.invalidConditions'),
                      validate: (value) =>
                        value === true || t('error.register.invalidConditions'),
                    })}
                    className={cn('form-check-input', {
                      'is-invalid': errors.conditions,
                    })}
                    value=""
                    id="conditionsCheck"
                  />
                  <label className="form-check-label" htmlFor="conditionsCheck">
                    <Trans i18nKey="register_agreeToTermsAndConditions">
                      Ik ga akkoord met de
                      <Link target="_blank" to={ROUTE_CONDITIONS}>
                        algemene voorwaarden
                      </Link>
                    </Trans>
                  </label>
                  {errors.conditions && (
                    <div className="invalid-feedback">
                      {errors.conditions.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input
                    name="research_optin"
                    type="checkbox"
                    ref={register}
                    className="form-check-input"
                    value=""
                    id="researchOptinCheck"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="researchOptinCheck"
                  >
                    {t('register_researchOptin')}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input
                    name="newsletter_optin"
                    type="checkbox"
                    ref={register}
                    className="form-check-input"
                    value=""
                    id="newsletterOptinCheck"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="newsletterOptinCheck"
                  >
                    {t('register_newsletterOptin')}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-link float-right"
                  onClick={handleShowPersonalInformationModal}
                >
                  {t('register_personalInformation_title')}
                </button>
                <div className="clearfix" />
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  {t('content.label.create')}
                </button>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('content.register.problems'),
                }}
              />

              <p className="text-muted" style={{ fontSize: '14px' }}>
                This site is protected by reCAPTCHA and the Google{' '}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>{' '}
                apply.
              </p>

              <ReCaptcha
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                action="register"
                verifyCallback={verifyCallback}
              />
            </form>
          </div>
          <div className="mt-5 float-right">
            <BackButton onClick={navigateToHome} />
          </div>
        </ColumnContent>
        <Footer />
      </ContentPage>
      <PersonalInformationModal
        active={showPersonalInformationModal}
        onClose={handleHidePersonalInformationModal}
      />
    </>
  );
}

export default Register;
