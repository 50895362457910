import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../../context/auth';
import { ROUTE_HOME } from '../../constants/routes';

function PrivateRoute({ children, ...rest }) {
  const { authToken } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTE_HOME,
              state: { referer: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
