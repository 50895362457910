import React from 'react';
import styles from './IKButton.module.scss';
import { Link } from 'react-router-dom';
import cn from 'classnames';

function IKLink({ children, link, disabled, priority }) {
  const classes = cn(styles.IKButton, {
    [styles.priority]: priority,
  });
  return (
    <Link to={link}>
      <button type="button" className={classes} disabled={disabled}>
        {children}
      </button>
    </Link>
  );
}

export default IKLink;
