import React from 'react';

import styles from './distributionResultBar.module.scss';
import { TARGET_GROUPS_WITH_COLORS } from '../../../../../constants/targetGroups';
import { COLOR_TARGET_GROUP_UNDEFINED } from '../../../../../constants/colors';
import { useParams } from 'react-router-dom';
import useDistribution from '../../../../../hooks/useDistribution';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';

function DistributionResultBar() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { distribution } = useDistribution(projectId);

  return (
    <div className={styles.distributionResultBar}>
      {distribution ? (
        <>
          {TARGET_GROUPS_WITH_COLORS.map((targetGroupWithColor) => (
            <OverlayTrigger
              key={targetGroupWithColor.targetGroup}
              overlay={
                <Tooltip id="tooltip-disabled">
                  {t(
                    `content.distribution.${targetGroupWithColor.targetGroup}`
                  )}
                </Tooltip>
              }
            >
              <div
                className={styles.item}
                style={{
                  width: `${distribution[targetGroupWithColor.targetGroup]}%`,
                  backgroundColor: targetGroupWithColor.color,
                }}
              />
            </OverlayTrigger>
          ))}
        </>
      ) : (
        <div
          className={styles.undefined}
          style={{ backgroundColor: COLOR_TARGET_GROUP_UNDEFINED }}
        />
      )}
    </div>
  );
}

export default DistributionResultBar;
