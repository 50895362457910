import React from 'react';
import styles from './contentImageBackground.module.scss';

function ContentImageBackground({ children }) {
  return (
    <div className={`container-fluid ${styles.contentImageBackground}`}>
      {children}
    </div>
  );
}

export default ContentImageBackground;
