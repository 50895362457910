import React from 'react';
import cn from 'classnames';
import styles from './IKButton.module.scss';

function IKButton({ children, onClick, disabled, priority, type = 'button' }) {
  const classes = cn(styles.IKButton, {
    [styles.priority]: priority,
  });
  return (
    <button
      type={type}
      className={classes}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default IKButton;
