import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import CloseBtn from '../../../../../components/closeBtn/closeBtn';

import styles from './analysisQuestionModal.module.scss';
import IntakeInfoModal from '../../../../../components/intakeInfoModal/intakeInfoModal';
import InfoBtn from '../../../../../components/infoBtn/infoBtn';
import cn from 'classnames';
import ModalComponent from '../../../../../components/modal/modal';
import nl2br from '../../../../../utilities/string/nl2br';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import useIntakeQuestionsWithData from '../../../../../hooks/useIntakeQuestionsWithData';
import { useParams } from 'react-router-dom';

function AnalysisQuestionModal({ active, colors, question, onClose }) {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { intakeQuestions } = useIntakeQuestionsWithData(projectId);
  const [infoModalContent, setInfoModalContent] = useState(null);

  function showInfoModal(info) {
    setInfoModalContent(info);
  }

  function hideInfoModal() {
    setInfoModalContent(null);
  }

  return (
    <>
      <Modal
        show={active}
        onHide={onClose}
        size="md"
        className={styles.analysisQuestionModal}
      >
        <Modal.Header
          className={styles.header}
          closeButton={false}
          style={{ backgroundColor: colors && colors[colors.length - 1] }}
        >
          <div>
            <Modal.Title className={styles.title}>
              {t('content.label.question')}
            </Modal.Title>
          </div>
          <CloseBtn onClick={onClose} />
        </Modal.Header>
        <Modal.Body className={styles.body}>
          {question && (
            <>
              <div className="container mt-3">
                <div className="row">
                  <div className="col-12 d-flex">
                    <h3
                      className={styles.question}
                      dangerouslySetInnerHTML={{ __html: question.question }}
                    />
                    {question.info && (
                      <div>
                        <InfoBtn onClick={() => showInfoModal(question.info)} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row no-gutters justify-content-center">
                <div className={`${styles.answers} col-12`}>
                  <div className="row no-gutters">
                    <div className="col-1">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {t('content.analysis.answer.unknown')}
                          </Tooltip>
                        }
                      >
                        <div
                          className={cn(styles.answer, styles.unknownAnswer, {
                            [styles.selected]: -1 === question.score,
                          })}
                          style={{ backgroundColor: colors[0] }}
                        />
                      </OverlayTrigger>
                    </div>
                    <div className="col-1">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {t('content.analysis.answer.notApplicable')}
                          </Tooltip>
                        }
                      >
                        <div
                          className={cn(styles.answer, {
                            [styles.selected]: 0 === question.score,
                          })}
                          style={{ backgroundColor: colors[0] }}
                        />
                      </OverlayTrigger>
                    </div>
                    {question.answers.map((answer, i) => {
                      const answerScore = i + 1;
                      return (
                        <div className="col-2" key={i}>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">{answer}</Tooltip>
                            }
                          >
                            <div
                              className={cn(styles.answer, {
                                [styles.selected]:
                                  answerScore === question.score,
                              })}
                              style={{ backgroundColor: colors[answerScore] }}
                            />
                          </OverlayTrigger>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="container mb-3">
                <div className="row">
                  <div className="col-12 mt-3">
                    <label>{question.subquestion}</label>
                    {question.subQuestionAnswer ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: nl2br(question.subQuestionAnswer),
                        }}
                      />
                    ) : (
                      <p>{t('content.label.noAnswer')}</p>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      {question && <IntakeInfoModal intakeQuestions={intakeQuestions} />}
      <ModalComponent
        active={!!infoModalContent}
        onClose={hideInfoModal}
        className={styles.infoModal}
        backdropClassName={styles.infoModalBackdrop}
      >
        {infoModalContent && (
          <p
            dangerouslySetInnerHTML={{ __html: nl2br(infoModalContent) }}
            className="mb-0"
          />
        )}
      </ModalComponent>
    </>
  );
}

export default AnalysisQuestionModal;
