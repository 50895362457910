import React from 'react';
import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import cn from 'classnames';
import styles from './stepIndicator.module.scss';
import {
  PROJECT_STEP_ANALYSIS,
  PROJECT_STEP_DETAIL,
  PROJECT_STEP_DISTRIBUTION,
  PROJECT_STEP_NEW,
  PROJECT_STEP_RESULT,
} from '../../constants/projectSteps';

function StepIndicator({ type, active, path, tooltip }) {
  function getLabel() {
    switch (type) {
      case PROJECT_STEP_NEW:
        return 1;
      case PROJECT_STEP_ANALYSIS:
        return 2;
      case PROJECT_STEP_DISTRIBUTION:
        return 3;
      case PROJECT_STEP_RESULT:
        return 4;
      case PROJECT_STEP_DETAIL:
        return 5;
      default:
        return '';
    }
  }

  const StepComponent = (
    <div
      className={cn(styles.stepIndicator, styles[type], {
        [styles.active]: active,
        [styles.isHoverable]: !!tooltip,
      })}
    >
      {getLabel()}
    </div>
  );
  if (path && tooltip) {
    return (
      <Link to={path}>
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">{tooltip}</Tooltip>}
        >
          {StepComponent}
        </OverlayTrigger>
      </Link>
    );
  }
  if (path) {
    return <Link to={path}>{StepComponent}</Link>;
  }
  if (tooltip) {
    return (
      <OverlayTrigger
        overlay={<Tooltip id="tooltip-disabled">{tooltip}</Tooltip>}
      >
        {StepComponent}
      </OverlayTrigger>
    );
  }
  return StepComponent;
}

export default StepIndicator;
