import React from 'react';

import { ReactComponent as Close } from './assets/close.svg';

import styles from './closeBtn.module.scss';

function CloseBtn({ onClick, disabled }) {
  return (
    <button onClick={onClick} className={styles.closeBtn} disabled={disabled}>
      <Close width="20" height="20" />
    </button>
  );
}

export default CloseBtn;
