import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ProjectTypeField({ register, onChange, error }) {
  const { t } = useTranslation();
  return (
    <div className="form-group">
      <label>{t('content.intake.projectType.title')}</label>
      <div className="form-check">
        <input
          ref={register()}
          className={cn('form-check-input', { 'is-invalid': error })}
          type="radio"
          name="type"
          id="typeValueA"
          value="A"
          onChange={onChange}
        />
        <label className="form-check-label" htmlFor="typeValueA">
          {t('content.intake.projectType.A')}
        </label>
      </div>
      <div className="form-check">
        <input
          ref={register({
            required: t('validation.required'),
          })}
          className={cn('form-check-input', { 'is-invalid': error })}
          type="radio"
          name="type"
          id="typeValueB"
          value="B"
          onChange={onChange}
        />
        <label className="form-check-label" htmlFor="typeValueB">
          {t('content.intake.projectType.B')}
        </label>
      </div>
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
}

export default ProjectTypeField;
