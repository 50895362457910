import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';

import styles from './compassCenter.module.scss';

export const COMPASS_CENTER_SIZE = 320;

function CompassCenter({
  show,
  categoryColors,
  onAnimationInComplete,
  onAnimationOutComplete,
}) {
  const polygon1AnimationInRef = useRef(null);
  const polygon2AnimationInRef = useRef(null);
  const polygon3AnimationInRef = useRef(null);
  const polygon4AnimationInRef = useRef(null);
  const polygon5AnimationInRef = useRef(null);
  const polygon1AnimationOutRef = useRef(null);
  const polygon2AnimationOutRef = useRef(null);
  const polygon3AnimationOutRef = useRef(null);
  const polygon4AnimationOutRef = useRef(null);
  const polygon5AnimationOutRef = useRef(null);

  const animateInComplete = useCallback(() => {
    if (onAnimationInComplete) onAnimationInComplete();
  }, [onAnimationInComplete]);

  const animateOutComplete = useCallback(() => {
    if (onAnimationOutComplete) onAnimationOutComplete();
  }, [onAnimationOutComplete]);

  useLayoutEffect(() => {
    if (polygon1AnimationInRef.current) {
      if ('beginElement' in polygon1AnimationInRef.current) {
        if (show) {
          polygon1AnimationInRef.current.beginElement();
          polygon2AnimationInRef.current.beginElement();
          polygon3AnimationInRef.current.beginElement();
          polygon4AnimationInRef.current.beginElement();
          polygon5AnimationInRef.current.beginElement();
        } else {
          polygon1AnimationOutRef.current.beginElement();
          polygon2AnimationOutRef.current.beginElement();
          polygon3AnimationOutRef.current.beginElement();
          polygon4AnimationOutRef.current.beginElement();
          polygon5AnimationOutRef.current.beginElement();
        }
      } else if (show) {
        // no support for svg animation, probably IE
        animateInComplete();
      } else if (!show) {
        // no support for svg animation, probably IE
        animateOutComplete();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    const polygon1In = polygon1AnimationInRef.current;
    const polygon1Out = polygon1AnimationOutRef.current;
    polygon1In.addEventListener('endEvent', animateInComplete);
    polygon1Out.addEventListener('endEvent', animateOutComplete);

    return () => {
      polygon1In.removeEventListener('endEvent', animateInComplete);
      polygon1Out.removeEventListener('endEvent', animateOutComplete);
    };
  }, [animateInComplete, animateOutComplete]);

  return (
    <div
      className={cn(styles.compassCenter, {
        [styles.show]: show,
      })}
      style={{
        width: COMPASS_CENTER_SIZE,
        height: COMPASS_CENTER_SIZE,
      }}
    >
      <svg
        version="1.1"
        id="compass-center"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 322 322"
        xmlSpace="preserve"
        style={{
          enableBackground: 'new 0 0 322 322',
        }}
        width={COMPASS_CENTER_SIZE}
        height={COMPASS_CENTER_SIZE}
      >
        <g>
          <g>
            <polygon
              fill={categoryColors?.[4]}
              points="321,161 161,161 207.9,114.1 207.9,114.1"
            />
            <polygon
              fill={categoryColors?.[4]}
              points="114.1,207.9 114.1,207.9 1,161 161,161"
            />
            <polygon
              fill={categoryColors?.[4]}
              points="161,321 161,161 207.9,207.9 207.9,207.9"
            />
            <polygon
              fill={categoryColors?.[4]}
              points="114.1,114.1 114.1,114.1 161,1 161,161"
            />
          </g>
          <g>
            <polygon
              fill={categoryColors?.[2]}
              points="161,161 161,1 207.9,114.1 207.9,114.1"
            />
            <polygon
              fill={categoryColors?.[2]}
              points="114.1,207.9 114.1,207.9 161,161 161,321"
            />
            <polygon
              fill={categoryColors?.[2]}
              points="161,161 321,161 207.9,207.9 207.9,207.9"
            />
            <polygon
              fill={categoryColors?.[2]}
              points="114.1,114.1 114.1,114.1 161,161 1,161"
            />
          </g>
          <g>
            <polygon
              id="polygon1"
              fill={categoryColors?.[0]}
              points="207.9,114.1 161,1 274.1,47.9 321,161"
            >
              <animate
                ref={polygon1AnimationInRef}
                xlinkHref="#polygon1"
                attributeName="points"
                dur="1000ms"
                to="161,161 161,0 322,0 322,161"
                fill="freeze"
                begin="indefinite"
              />
              <animate
                ref={polygon1AnimationOutRef}
                xlinkHref="#polygon1"
                attributeName="points"
                dur="1000ms"
                to="207.9,114.1 161,1 274.1,47.9 321,161"
                fill="freeze"
                begin="indefinite"
              />
            </polygon>
            <polygon
              id="polygon2"
              fill={categoryColors?.[0]}
              points="47.9,274.1 1,161 114.1,207.9 161,321"
            >
              <animate
                ref={polygon2AnimationInRef}
                xlinkHref="#polygon2"
                attributeName="points"
                className="compass-animations"
                dur="1000ms"
                to="0,322 0,161 161,161 161,322"
                fill="freeze"
                begin="indefinite"
              />
              <animate
                ref={polygon2AnimationOutRef}
                xlinkHref="#polygon2"
                attributeName="points"
                className="compass-animations"
                dur="1000ms"
                to="47.9,274.1 1,161 114.1,207.9 161,321"
                fill="freeze"
                begin="indefinite"
              />
            </polygon>
            <polygon
              id="polygon3"
              fill={categoryColors?.[0]}
              points="207.9,207.9 321,161 274.1,274.1 161,321"
            >
              <animate
                ref={polygon3AnimationInRef}
                xlinkHref="#polygon3"
                attributeName="points"
                className="compass-animations"
                dur="1000ms"
                to="161,161 322,161 322,322 161,322"
                fill="freeze"
                begin="indefinite"
              />
              <animate
                ref={polygon3AnimationOutRef}
                xlinkHref="#polygon3"
                attributeName="points"
                className="compass-animations"
                dur="1000ms"
                to="207.9,207.9 321,161 274.1,274.1 161,321"
                fill="freeze"
                begin="indefinite"
              />
            </polygon>
            <polygon
              id="polygon4"
              fill={categoryColors?.[0]}
              points="47.9,47.9 161,1 114.1,114.1 1,161"
            >
              <animate
                ref={polygon4AnimationInRef}
                xlinkHref="#polygon4"
                attributeName="points"
                className="compass-animations"
                dur="1000ms"
                to="0,0 161,0 161,161 0,161"
                fill="freeze"
                begin="indefinite"
              />
              <animate
                ref={polygon4AnimationOutRef}
                xlinkHref="#polygon4"
                attributeName="points"
                className="compass-animations"
                dur="1000ms"
                to="47.9,47.9 161,1 114.1,114.1 1,161"
                fill="freeze"
                begin="indefinite"
              />
            </polygon>
          </g>
        </g>
        <g>
          <polygon
            id="polygon5"
            fill="none"
            stroke="#FFFFFF"
            strokeMiterlimit="10"
            points="161,321 47.9,274.1 1,161 47.9,47.9 161,1 274.1,47.9 321,161 274.1,274.1"
          >
            <animate
              ref={polygon5AnimationInRef}
              xlinkHref="#polygon5"
              attributeName="points"
              className="compass-animations"
              dur="1000ms"
              to="161,322 0,322 0,161 0,0 161,0 322,0 322,161 322,322"
              fill="freeze"
              begin="indefinite"
            />
            <animate
              ref={polygon5AnimationOutRef}
              xlinkHref="#polygon5"
              attributeName="points"
              className="compass-animations"
              dur="1000ms"
              to="161,321 47.9,274.1 1,161 47.9,47.9 161,1 274.1,47.9 321,161 274.1,274.1"
              fill="freeze"
              begin="indefinite"
            />
          </polygon>
        </g>
      </svg>
    </div>
  );
}

export default CompassCenter;
