import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../modal/modal';
import { generatePath, useParams } from 'react-router-dom';
import useProjectData from '../../hooks/useProjectData';
import { Alert, Button } from 'react-bootstrap';
import { useMutation, useQueryCache } from 'react-query';
import enablePublicProject from '../../queries/enablePublicProject';
import { useAuth } from '../../context/auth';
import SaveLoader from '../loader/saveLoader';
import useResponseExceptionHandler from '../../hooks/useResponseExceptionHandler';
import {
  ROUTE_PUBLIC_PROJECT_ANALYSIS,
  ROUTE_PUBLIC_PROJECT_DISTRIBUTION,
} from '../../constants/routes';
import Errors from '../errors/errors';
import cn from 'classnames';
import { useForm } from 'react-hook-form';

function getPublicProjectRoute(type, publicId) {
  let route = null;
  switch (type) {
    case 'analysis':
      route = ROUTE_PUBLIC_PROJECT_ANALYSIS;
      break;
    case 'distribution':
      route = ROUTE_PUBLIC_PROJECT_DISTRIBUTION;
      break;
    default:
      throw new Error(`Could not find public project route for type '${type}'`);
  }
  return window.location.origin + generatePath(route, { publicId });
}

function EnablePublicProjectModal({ active, type, onClose }) {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const { register, handleSubmit, errors, watch, reset } = useForm();
  const watchPassword = watch('password');
  const { projectId } = useParams();
  const { authToken } = useAuth();
  const [error, setError] = useState(null);
  const closeCopyLinkResultTimeout = useRef(null);
  const [copyLinkResult, setCopyLinkResult] = useState({
    type: null,
    message: null,
  });
  const handleResponseException = useResponseExceptionHandler();
  const { project, isLoading: projectIsLoading } = useProjectData(projectId);
  const [
    mutateEnablePublicProject,
    { isLoading: enablePublicProjectIsLoading },
  ] = useMutation(enablePublicProject, {
    throwOnError: true,
    onSuccess: () => {
      queryCache.invalidateQueries('project');
    },
  });

  function resetError() {
    if (error) setError(null);
  }

  async function handleEnablePublicProject(data) {
    try {
      resetError();
      await mutateEnablePublicProject({
        ...data,
        id: projectId,
        authToken,
        type,
      });
      reset();
    } catch (e) {
      const error = handleResponseException(e, {
        default: t('error.generic.failed'),
      });
      setError(error);
    }
  }

  useEffect(() => {
    return () => {
      if (closeCopyLinkResultTimeout.current)
        clearTimeout(closeCopyLinkResultTimeout.current);
    };
  }, []);

  function copyLink() {
    if (closeCopyLinkResultTimeout.current)
      clearTimeout(closeCopyLinkResultTimeout.current);
    const linkInput = document.getElementById('link');
    linkInput.focus();
    linkInput.select();

    try {
      const successful = document.execCommand('copy');
      if (!successful) throw new Error('Could not copy');

      setCopyLinkResult({
        type: 'success',
        message: t('content.enablePublicForm.copyLinkSuccess'),
      });
    } catch (err) {
      setCopyLinkResult({
        type: 'danger',
        message: t('content.enablePublicForm.copyLinkError'),
      });
    }

    closeCopyLinkResultTimeout.current = setTimeout(closeCopyLinkResult, 3000);
  }

  function closeCopyLinkResult() {
    setCopyLinkResult({
      type: null,
      message: null,
    });
  }

  let publicId = null;
  switch (type) {
    case 'analysis':
      publicId = project?.public_analysis_id;
      break;
    case 'distribution':
      publicId = project?.public_distribution_id;
      break;
    default:
      throw new Error('unknown public project type');
  }

  const publicEnabled = !!publicId;
  const isLoading = projectIsLoading || enablePublicProjectIsLoading;

  return (
    <>
      <ModalComponent
        title={t('content.enablePublicForm.title')}
        active={active}
        onClose={onClose}
      >
        <p>{t('content.enablePublicForm.description')}</p>

        {error && <Errors errors={[error]} />}

        <form onSubmit={handleSubmit(handleEnablePublicProject)}>
          <div className="form-group">
            <label>{t('content.login.password')}</label>
            <input
              type="password"
              name="password"
              ref={register({
                required: t('validation.required'),
                maxLength: {
                  value: 30,
                  message: t('validation.maxLength', { maxLength: 30 }),
                },
              })}
              className={cn('form-control', { 'is-invalid': errors.password })}
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password.message}</div>
            )}
          </div>
          {publicEnabled && (
            <div className="form-group">
              <label>{t('content.enablePublicForm.link')}</label>
              <input
                id="link"
                type="text"
                name="link"
                readOnly
                value={getPublicProjectRoute(type, publicId)}
                className="form-control"
              />
              {copyLinkResult.type && (
                <Alert className="mt-2" variant={copyLinkResult.type}>
                  {copyLinkResult.message}
                </Alert>
              )}
            </div>
          )}
          <div className="d-flex justify-content-end my-4">
            {publicEnabled && (
              <Button
                className="mx-2"
                variant="outline-primary"
                onClick={copyLink}
                disabled={projectIsLoading || enablePublicProjectIsLoading}
              >
                {t('content.enablePublicForm.copyLink')}
              </Button>
            )}
            <Button
              className="ml-2"
              type="submit"
              variant="primary"
              disabled={
                projectIsLoading ||
                enablePublicProjectIsLoading ||
                !watchPassword
              }
            >
              {t(
                !publicEnabled
                  ? 'content.enablePublicForm.createLink'
                  : 'content.enablePublicForm.updateLink'
              )}
            </Button>
          </div>
        </form>
      </ModalComponent>
      {isLoading && <SaveLoader />}
    </>
  );
}

export default EnablePublicProjectModal;
