import React from 'react';
import styles from './footer.module.scss';
import { Link } from 'react-router-dom';
import {
  ROUTE_ACCESSIBILITY,
  ROUTE_CONDITIONS,
  ROUTE_COPYRIGHT,
  ROUTE_DISCLAIMER,
  ROUTE_PRIVACY,
} from '../../constants/routes';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <div className={`container py-3 mb-5 ${styles.footer}`}>
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <a href="https://hetccv.nl/">
            © Centrum voor Criminaliteitspreventie en Veiligheid
          </a>
          <Link to={ROUTE_CONDITIONS}>{t('conditions_title')}</Link>
          <Link to={ROUTE_DISCLAIMER}>{t('disclaimer_title')}</Link>
          <Link to={ROUTE_COPYRIGHT}>{t('copyright_title')}</Link>
          <Link to={ROUTE_PRIVACY}>{t('privacy_menuTitle')}</Link>
          <Link to={ROUTE_ACCESSIBILITY}>{t('accessibility_menuTitle')}</Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
