import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { ROUTE_PROJECTS } from '../../constants/routes';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import axios from 'axios';
import Errors from '../../components/errors/errors';
import FixedLoader from '../../components/loader/fixedLoader';
import passwordValidator from '../../validators/passwordValidator';
import emailValidator from '../../validators/emailValidator';
import { useAuth } from '../../context/auth';
import useResponseExceptionHandler from '../../hooks/useResponseExceptionHandler';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import Footer from '../../components/footer/footer';
import ContentPage from '../../components/contentPage/contentPage';
import ColumnContent from '../../components/columnContent/columnContent';

function ResetPassword() {
  const { t } = useTranslation();
  const { token } = useParams();
  const { register, handleSubmit, watch, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [globalErrors, setGlobalErrors] = useState(null);
  const [passwordReset, setPasswordReset] = useState(false);
  const { authToken, setAuthToken } = useAuth();
  const handleResponseException = useResponseExceptionHandler();

  function handleResponseError(exception) {
    setIsLoading(false);

    const error = handleResponseException(exception, {
      default: t('resetPassword_error_invalidToken'),
    });
    setGlobalErrors([error]);
  }

  function handleResetPasswordSubmit(data) {
    setPasswordReset(false);
    setIsLoading(true);
    setGlobalErrors(null);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/update_password`,
        {
          ...data,
          password_confirmation: data.passwordRepeat,
          token,
        },
        {
          validateStatus: (status) => status === 200,
        }
      )
      .then((response) => {
        setIsLoading(false);
        setPasswordReset(true);
        setTimeout(() => {
          setAuthToken(response.data.result.auth_token);
        }, 5000);
      })
      .catch(handleResponseError);
  }

  if (authToken) {
    return <Redirect to={ROUTE_PROJECTS} />;
  }

  return (
    <ContentPage>
      <ContentImageBackground />
      <ColumnContent>
        <div className="col-8 mx-auto">
          <h1>{t('resetPassword_title')}</h1>
          <p>{t('resetPassword_text')}</p>

          {passwordReset && (
            <div className="alert alert-success">
              <h4 className="alert-heading">
                {t('resetPassword_success_title')}
              </h4>
              <p className="mb-0">{t('resetPassword_success_body')}</p>
            </div>
          )}

          {globalErrors && <Errors errors={globalErrors} />}
          {isLoading && <FixedLoader />}
          <form onSubmit={handleSubmit(handleResetPasswordSubmit)}>
            <div className="form-group">
              <label>{t('content.login.email')}</label>
              <input
                type="email"
                name="email"
                ref={register({
                  required: t('validation.required'),
                  validate: (value) =>
                    emailValidator(value) || t('error.register.invalidEmail'),
                })}
                className={cn('form-control', { 'is-invalid': errors.email })}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email.message}</div>
              )}
            </div>
            <div className="form-group">
              <label>{t('content.login.password')}</label>
              <input
                type="password"
                name="password"
                ref={register({
                  required: t('validation.required'),
                  validate: (value) =>
                    passwordValidator(value) ||
                    t('error.register.invalidPassword'),
                })}
                className={cn('form-control', {
                  'is-invalid': errors.password,
                })}
              />
              {errors.password && (
                <div className="invalid-feedback">
                  {errors.password.message}
                </div>
              )}
            </div>
            <div className="form-group">
              <label>{t('content.register.passwordRepeat')}</label>
              <input
                type="password"
                name="passwordRepeat"
                ref={register({
                  required: t('validation.required'),
                  validate: (value) =>
                    watch('password') === value ||
                    t('error.register.invalidPasswordRepeat'),
                })}
                className={cn('form-control', {
                  'is-invalid': errors.passwordRepeat,
                })}
              />
              {errors.passwordRepeat && (
                <div className="invalid-feedback">
                  {errors.passwordRepeat.message}
                </div>
              )}
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                {t('content.label.send')}
              </button>
            </div>
          </form>
        </div>
      </ColumnContent>
      <Footer />
    </ContentPage>
  );
}

export default ResetPassword;
