import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../analysisQuestion/analysisQuestion.module.scss';
import AnalysisQuestionAnswers from '../analysisQuestionAnswers/analysisQuestionAnswers';
import IntakeInfoModal from '../../../../../components/intakeInfoModal/intakeInfoModal';
import { useParams } from 'react-router-dom';
import usePublicAnalysisAnswers from '../../../../../hooks/usePublicAnalysisAnswers';
import PublicAnalysisQuestionAnswers from '../analysisQuestionAnswers/publicAnalysisQuestionAnswers';
import useAnalysisQuestionsWithData from '../../../../../hooks/useAnalysisQuestionsWithData';
import useIntakeQuestionsWithData from '../../../../../hooks/useIntakeQuestionsWithData';
import cn from 'classnames';
import AnalysisQuestionAnswerCount from '../analysisQuestionAnswers/analysisQuestionAnswerCount';

function AnalysisAnswer({
  question,
  analysisQuestionsCategory,
  onSaveAnalysisQuestionAnswer,
  publicAnalysisAnswers,
}) {
  const { t } = useTranslation();
  const [showPublicAnswers, setShowPublicAnswers] = useState(false);

  function toggleShowPublicAnswers() {
    setShowPublicAnswers(!showPublicAnswers);
  }

  return (
    <div className="mb-4 row no-gutters" key={question.id}>
      <div className="col-12">
        <div className="row my-3">
          <div className="col-10 mb-2">
            <h3
              className={`${styles.question} mb-0`}
              dangerouslySetInnerHTML={{ __html: question.question }}
            />
          </div>
          <div className="col-10 mb-1">
            <AnalysisQuestionAnswerCount
              answers={question.answers}
              selectedAnswer={question.score}
              publicAnalysisAnswers={publicAnalysisAnswers?.[question.id]}
            />
          </div>
          <div className="col-10">
            <AnalysisQuestionAnswers
              answers={question.answers}
              selectedAnswer={question.score}
              colors={analysisQuestionsCategory.colors}
              onSelectAnswer={(answer) =>
                onSaveAnalysisQuestionAnswer(
                  analysisQuestionsCategory.id,
                  question.id,
                  answer,
                  question.subQuestionAnswer
                )
              }
            />
          </div>
          <div className="col-2" />
        </div>

        <div className="row">
          <div className="col-12">
            <button
              className={`${styles.showPublicAnswersButton} btn btn-link px-0`}
              onClick={toggleShowPublicAnswers}
            >
              <i
                className={cn('fas', styles.showPublicAnswersButtonArrow, {
                  'fa-caret-right': !showPublicAnswers,
                  'fa-caret-down': showPublicAnswers,
                })}
              />
              {t('content.publicForm.showAllAnswers')}
            </button>
          </div>
          {showPublicAnswers && (
            <div className="col-12">
              {publicAnalysisAnswers?.[question.id]?.map(
                (publicAnalysisAnswer) => (
                  <div
                    className="row my-2 d-flex align-items-center"
                    key={publicAnalysisAnswer.id}
                  >
                    <div className="col-10">
                      <PublicAnalysisQuestionAnswers
                        answers={question.answers}
                        selectedAnswer={publicAnalysisAnswer.score}
                        colors={analysisQuestionsCategory.colors}
                      />
                    </div>
                    <div className="col-2">
                      <p className={styles.name}>
                        {publicAnalysisAnswer?.name ||
                          t('content.publicForm.anonymous')}
                      </p>
                    </div>
                  </div>
                )
              )}

              <div className="row mb-3 mt-4">
                <div className="col-10">
                  <h4 className="mb-0">{t('content.analysis.explanation')}</h4>
                </div>
              </div>
              <div className="row my-2 d-flex align-items-center">
                <div className="col-10">
                  <div className={styles.explanation}>
                    <p>{question.subQuestionAnswer}</p>
                  </div>
                </div>
                <div className="col-2">
                  <p className={styles.name}>
                    {t('content.analysis.yourAnswer')}
                  </p>
                </div>
              </div>
              {publicAnalysisAnswers?.[question.id]?.map(
                (publicAnalysisAnswer) => (
                  <div
                    className="row my-2 d-flex align-items-center"
                    key={publicAnalysisAnswer.id}
                  >
                    <div className="col-10">
                      <div className={styles.explanation}>
                        <p>{publicAnalysisAnswer.subquestion_answer}</p>
                      </div>
                    </div>
                    <div className="col-2">
                      <p className={styles.name}>
                        {publicAnalysisAnswer?.name ||
                          t('content.publicForm.anonymous')}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function AnalysisAnswers({ onSaveAnalysisQuestionAnswer }) {
  const { projectId } = useParams();
  const { intakeQuestions } = useIntakeQuestionsWithData(projectId);
  const { publicAnalysisAnswers } = usePublicAnalysisAnswers(projectId);
  const { analysisQuestions } = useAnalysisQuestionsWithData(projectId);

  return (
    <>
      {analysisQuestions && (
        <>
          {analysisQuestions.map((analysisQuestionsCategory) => (
            <div
              className="container pt-3"
              key={analysisQuestionsCategory.id}
              id={analysisQuestionsCategory.category}
            >
              {analysisQuestionsCategory.children.map((question) => (
                <AnalysisAnswer
                  question={question}
                  onSaveAnalysisQuestionAnswer={onSaveAnalysisQuestionAnswer}
                  analysisQuestionsCategory={analysisQuestionsCategory}
                  publicAnalysisAnswers={publicAnalysisAnswers}
                />
              ))}
            </div>
          ))}

          <IntakeInfoModal intakeQuestions={intakeQuestions} />
        </>
      )}
    </>
  );
}

export default AnalysisAnswers;
