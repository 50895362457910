import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './backButton.module.scss';
import IKButton from '../button/IKButton';
import IKLink from '../button/IKLink';

function BackButton({ onClick, to, disabled }) {
  const { t } = useTranslation();

  return (
    <div className={styles.backButton}>
      {onClick && (
        <IKButton onClick={onClick} disabled={disabled}>
          <div className={styles.label}>{t('content.label.return')}</div>
        </IKButton>
      )}
      {to && (
        <IKLink link={to} disabled={disabled}>
          <div className={styles.label}>{t('content.label.return')}</div>
        </IKLink>
      )}
    </div>
  );
}

export default BackButton;
