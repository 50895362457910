import React, { useState } from 'react';
import Modal from '../../../../../components/modal/modal';
import InfoBtn from '../../../../../components/infoBtn/infoBtn';

import styles from './intakeHelpQuestionsModal.module.scss';

function IntakeHelpQuestionsModal({ info }) {
  const [active, setActive] = useState(false);

  function showModal() {
    setActive(true);
  }

  function hideModal() {
    setActive(false);
  }

  return (
    <>
      <div>
        <InfoBtn onClick={showModal} />
      </div>
      <Modal active={active} onClose={hideModal}>
        <div className={styles.intakeHelpQuestions}>
          <p>{info}</p>
        </div>
      </Modal>
    </>
  );
}

export default IntakeHelpQuestionsModal;
