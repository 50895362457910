import React from 'react';

import styles from './infoBtn.module.scss';

function InfoBtn({ onClick }) {
  return (
    <button type="button" className={styles.infoButton} onClick={onClick}>
      ?
    </button>
  );
}

export default InfoBtn;
