import axios from 'axios';

export default function saveDistribution({ id, authToken, data }) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/project/${id}/distribution`, data, {
      validateStatus: (status) => status === 201,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((result) => result.data.result);
}
