import { useTranslation } from 'react-i18next';

import InterventionsEN from '../content/download/EN/interventions.json';
import InterventionsNL from '../content/download/NL/interventions.json';

export default function useInterventions() {
  const { i18n } = useTranslation();

  let interventions = InterventionsNL;
  if (i18n.language === 'en') {
    interventions = InterventionsEN;
  }

  return {
    interventions,
  };
}
