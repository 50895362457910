import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ContentPage from '../../components/contentPage/contentPage';
import ContentImageBackground from '../../components/contentImageBackground/contentImageBackground';
import ColumnContent from '../../components/columnContent/columnContent';
import BackButton from '../../components/backButton/backButton';
import Footer from '../../components/footer/footer';
import React from 'react';

export default function Copyright() {
  const { t } = useTranslation();
  const history = useHistory();

  function handleGoBackClick() {
    history.goBack();
  }

  return (
    <ContentPage>
      <ContentImageBackground />
      <ColumnContent>
        <h1>{t('copyright_title')}</h1>
        <Trans i18nKey="copyright_text">
          <h4>Intellectuele eigendom</h4>
          <p>
            Het InterventieKompas is - in opdracht van het ministerie van
            Justitie en Veiligheid - ontwikkeld door en eigendom van het Centrum
            voor Criminaliteitspreventie en Veiligheid (het CCV).
          </p>
          <h4>Eigendomsvoorbehoud</h4>
          <p>
            Alle (aanspraken op) intellectuele eigendomsrechten (IE-rechten) met
            betrekking tot het InterventieKompas berusten bij het CCV.
            <br />
            Op alle content die via het InterventieKompas te raadplegen is,
            waaronder begrepen maar niet beperkt tot de teksten, look and feel,
            geluids- en beeldmateriaal, afbeeldingen, vormgeving, merken, logo’s
            alsmede enige andere informatie, rusten intellectuele
            eigendomsrechten die aan het CCV toebehoren.
            <br />
            Het CCV behoudt zich het recht voor alle informatie in het
            InterventieKompas, op ieder moment zonder verdere aankondiging, te
            wijzigen of te verwijderen.
          </p>
          <p>
            De gebruiker mag:
            <ul>
              <li>
                het Interventiekompas kopiëren, verspreiden, tonen en op- en
                uitvoeren;
              </li>
              <li>afgeleide werken maken;</li>
            </ul>
          </p>
          <p>
            onder de volgende voorwaarden:
            <ul>
              <li>
                naamsvermelding: de gebruiker dient de naam van het CCV te
                vermelden;
              </li>
              <li>
                niet-commercieel: de gebruiker mag het werk niet zonder
                toestemming voor commerciële doeleinden gebruiken;
              </li>
              <li>
                bij hergebruik of verspreiding dient de gebruiker de
                gebruiksvoorwaarden van het Interventiekompas kenbaar te maken
                aan derden;
              </li>
              <li>
                de gebruiker mag uitsluitend afstand doen van één of meerdere
                van deze voorwaarden met voorafgaande toestemming van de
                rechthebbende.
              </li>
            </ul>
          </p>
          <p>
            Het voorgaande laat de wettelijke beperkingen op de intellectuele
            eigendomsrechten onverlet.
          </p>
        </Trans>
        <a href="https://hetccv.nl/" target="_blank" rel="noopener noreferrer">
          © Centrum voor Criminaliteitspreventie en Veiligheid
        </a>
        <div className="mt-6 float-right">
          <BackButton onClick={handleGoBackClick} />
        </div>
      </ColumnContent>
      <Footer />
    </ContentPage>
  );
}
