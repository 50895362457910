import React from 'react';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './projectProgress.module.scss';
import {
  ROUTE_PROJECT_INTAKE,
  ROUTE_PROJECT_ANALYSIS,
  ROUTE_PROJECT_DETAIL,
  ROUTE_PROJECT_DISTRIBUTION,
  ROUTE_PROJECT_RESULT,
} from '../../constants/routes';
import StepIndicator from '../stepIndicator/stepIndicator';
import {
  PROJECT_STEP_ANALYSIS,
  PROJECT_STEP_DETAIL,
  PROJECT_STEP_DISTRIBUTION,
  PROJECT_STEP_NEW,
  PROJECT_STEP_RESULT,
} from '../../constants/projectSteps';

function ProjectProgress({
  projectId,
  newActive,
  analysisActive,
  distributionActive,
  resultActive,
  detailActive,
  showTooltips = true,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.projectProgression}>
      <StepIndicator
        type={PROJECT_STEP_NEW}
        path={projectId && generatePath(ROUTE_PROJECT_INTAKE, { projectId })}
        tooltip={showTooltips && t('content.project.info')}
        active={newActive}
      />
      <StepIndicator
        type={PROJECT_STEP_ANALYSIS}
        path={projectId && generatePath(ROUTE_PROJECT_ANALYSIS, { projectId })}
        tooltip={showTooltips && t('content.questionnaire.analysis.title')}
        active={analysisActive}
      />
      <StepIndicator
        type={PROJECT_STEP_DISTRIBUTION}
        path={
          projectId && generatePath(ROUTE_PROJECT_DISTRIBUTION, { projectId })
        }
        tooltip={showTooltips && t('content.questionnaire.distribution.title')}
        active={distributionActive}
      />
      <StepIndicator
        type={PROJECT_STEP_RESULT}
        path={projectId && generatePath(ROUTE_PROJECT_RESULT, { projectId })}
        tooltip={showTooltips && t('content.result.title')}
        active={resultActive}
      />
      <StepIndicator
        type={PROJECT_STEP_DETAIL}
        path={projectId && generatePath(ROUTE_PROJECT_DETAIL, { projectId })}
        tooltip={showTooltips && t('content.project.detail')}
        active={detailActive}
      />
    </div>
  );
}

export default ProjectProgress;
