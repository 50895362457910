import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import CloseBtn from '../closeBtn/closeBtn';

import styles from './modal.module.scss';

function ModalComponent({
  active,
  title,
  children,
  onClose,
  actions,
  size = 'lg',
  closeBtn = true,
  ...rest
}) {
  return (
    <Modal show={active} onHide={onClose} size={size} centered {...rest}>
      <Modal.Header closeButton={false} className={styles.header}>
        <Modal.Title className={styles.title} as="h1">
          {title}
        </Modal.Title>
        {onClose && closeBtn && (
          <div className={styles.closeBtnContainer}>
            <CloseBtn onClick={onClose} />
          </div>
        )}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {actions && (
        <Modal.Footer>
          {actions.map((action, i) => (
            <Button
              key={i}
              variant={action?.variant || 'primary'}
              onClick={action.onClick}
            >
              {action.label}
            </Button>
          ))}
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ModalComponent;
