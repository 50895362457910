import { useCallback, useEffect, useState } from 'react';

import useResponseExceptionHandler from './useResponseExceptionHandler';
import { useQuery } from 'react-query';
import getDistribution from '../queries/getDistribution';
import getAllDistributionValues from '../utilities/distribution/getAllDistributionValues';

export default function useDistribution(projectId) {
  const [error, setError] = useState(null);
  const handleResponseException = useResponseExceptionHandler();
  const {
    isLoading,
    isError,
    data,
    error: queryError,
    refetch,
    isSuccess,
  } = useQuery(['projectDistribution', { id: projectId }], getDistribution);

  const handleError = useCallback(
    (e) => {
      const error = handleResponseException(e);
      setError(error);
    },
    [setError, handleResponseException]
  );

  useEffect(() => {
    if (isError && queryError) {
      handleError(queryError);
    } else if (error) {
      setError(null);
    }
  }, [error, isError, queryError, handleError]);

  return {
    distribution: {
      id: data?.id,
      ...getAllDistributionValues(data),
    },
    isLoading,
    error,
    refetch,
    isSuccess,
  };
}
