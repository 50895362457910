import axios from 'axios';

export default function zoomedProject({ id, authToken }) {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/project/${id}/zoomed`,
      {},
      {
        validateStatus: (status) => status === 200,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((result) => result.data.result);
}
