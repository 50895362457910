import React from 'react';
import ProjectProgress from '../../components/projectProgress/projectProgress';
import { useTranslation } from 'react-i18next';
import nl2br from '../../utilities/string/nl2br';

import styles from './stepIntro.module.scss';
import IKLink from '../../components/button/IKLink';

function StepIntro({ title, description, activeStep, continueLink, skipLink }) {
  const { t } = useTranslation();

  return (
    <div className={styles.stepIntroContainer}>
      <div className={styles.stepIntro}>
        <div className="d-flex align-items-center  py-2">
          <div
            className={`${styles.content} text-center mx-auto d-flex flex-column align-items-center`}
          >
            <h3>{t('content.label.toNextStep')}</h3>
            <div className="mt-4 mb-2">
              <ProjectProgress
                showTooltips={false}
                analysisActive={activeStep === 'analysis'}
                distributionActive={activeStep === 'distribution'}
              />
            </div>
            <h1>{title}</h1>
            <p
              dangerouslySetInnerHTML={{ __html: nl2br(description) }}
              className="mt-2 mb-4"
            />
            <div className={styles.buttonContainer}>
              <div className={styles.continueButton}>
                <IKLink link={continueLink} priority>
                  <div className={styles.label}>
                    {t('content.label.continue')}
                  </div>
                </IKLink>
              </div>
              <div className={styles.skipButton}>
                <IKLink link={skipLink} priority>
                  <div className={styles.label}>
                    {t('content.label.skipStep')}
                  </div>
                </IKLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepIntro;
