import React from 'react';
import { useTranslation } from 'react-i18next';

import { generatePath, useParams } from 'react-router-dom';
import {
  ROUTE_PROJECT_DISTRIBUTION,
  ROUTE_PROJECT_RESULT,
} from '../../../constants/routes';
import StepIntro from '../../stepIntro/stepIntro';

function DistributionIntro() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  return (
    <StepIntro
      title={t('content.questionnaire.distribution.title')}
      description={t('content.questionnaire.intro.distribution.text')}
      continueLink={generatePath(ROUTE_PROJECT_DISTRIBUTION, { projectId })}
      skipLink={generatePath(ROUTE_PROJECT_RESULT, { projectId })}
      activeStep="distribution"
    />
  );
}

export default DistributionIntro;
