import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal/modal';
import BackButton from '../../../../components/backButton/backButton';
import { Link } from 'react-router-dom';
import { ROUTE_PRIVACY } from '../../../../constants/routes';

function PersonalInformationModal({ active, onClose }) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('register_personalInformation_title')}
      active={active}
      onClose={onClose}
      closeBtn={false}
    >
      <Trans i18nKey="register_personalInformation_text">
        <p>
          Door het InterventieKompas te gebruiken laat je persoonsgegevens bij
          het CCV achter die nodig zijn om je toegang tot jouw account op de
          website te geven. Het CCV bewaart en gebruikt deze gegevens alleen om
          op geaggregeerd niveau inzicht te krijgen in het gebruik van het
          InterventieKompas. Deze gegevens worden vertrouwelijk behandeld en
          niet met derden gedeeld.
        </p>
        <p>Het betreft de volgende gegevens:</p>
        <ul>
          <li>e-mailadres;</li>
          <li>voornaam;</li>
          <li>achternaam;</li>
          <li>organisatie;</li>
          <li>functie;</li>
          <li>gebruikersnaam;</li>
          <li>wachtwoord.</li>
        </ul>
        <p>
          Als je je gegevens (geanonimiseerd) ter beschikking stelt voor het
          verbeteren van het InterventieKompas, heeft het CCV tevens inzicht in
          de aangemaakte projecten. Deze gegevens worden zorgvuldig door het CCV
          bewaard. Het CCV gebruikt deze gegevens alleen om het
          InterventieKompas te verbeteren. Deze gegevens worden vertrouwelijk
          behandeld en niet met derden gedeeld. Als je je gegevens niet ter
          beschikking stelt, dan heeft het CCV geen toegang tot jouw projecten.
        </p>
        <Link to={ROUTE_PRIVACY} target="_blank">
          Privacy- en cookieverklaring
        </Link>
      </Trans>
      <div className="mt-3 float-right">
        <BackButton onClick={onClose} />
      </div>
    </Modal>
  );
}

export default PersonalInformationModal;
